const data = [
  {
    slug: "start",
    messages: [
      "Hi, I'm the Everyman archetype. Friendly and down-to-earth. Eager to chat and connect over ordinary things in life.",
      // "We are all created equal!",
      // "Empathy is my talent...belonging is my goal.",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/everyman/characteristics",
      },
      {
        label: "Build a everyman character",
        link: "/everyman/build",
      },
      {
        label: "Back home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "I believe everybody is created equal. It is important to me to connect with others. I am often reliable and respectable towards others. I support and encourage teamwork, and I get excited when problems are solved together. ",
      "Also known as the realist, the working stiff, neighbor, the silent majority, anyone, everybody, people, every person, every person, generality, masses, populace, and the public.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/everyman/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a everyman character",
        link: "/everyman/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template.", link: "/mint" },
      {
        label: "Build a everyman character ",
        link: "/everyman/build",
      },
      {
        label: "Get Help",
        link: "/everyman/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/mint" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/everyman/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "companionship",
    messages: ["Everyman character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Everyman character food and beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Everyman character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Everyman character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
