import React from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  font-family: "Arial", sans-serif;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
    @media (max-width: 790px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    font-weight: bold;
    @media (max-width: 790px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 40px;
  right: 27px;
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #41ff46;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 8px;

  z-index: 1100; /* Ensures it stays above the modal content */
`;

const BottomImage = styled.img`
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
`;

const ArticleModal = ({ isOpen, onClose, children, bottomImage }) => {
  if (!isOpen) return null;

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
        {bottomImage && <BottomImage src={bottomImage} alt="Bottom Image" />}
      </ModalContent>
    </ModalBackground>
  );
};

export default ArticleModal;
