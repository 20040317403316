import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function NotFoundPage() {
  return (
    <>
      <Section to="/">Page Not Found!</Section>
    </>
  );
}

const Section = styled(Link)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50vh;
  font-size: 2rem;
  color: #ffffff;
  text-decoration: none;
`;
