import React from "react";
import styled from "styled-components";
import { TitleStyles } from "./ReusableStyles";
import { SiReact, SiFirebase } from "react-icons/si";
import DescSection from "./MainDescSection";
import scrollreveal from "scrollreveal";

export default function WebappsIntroSection() {
  return (
    <>
      <Section id="services">
        <div className="title">
          <h1>Now what?</h1>
          <p>
            Firebase makes it easy and convenient to host serverless web
            applications!
          </p>
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/iosNuIdQoy8"
              frameBorder="0"
            />
          </div>
        </div>
        <DescSection />
      </Section>
    </>
  );
}

const Section = styled.section`
  margin: 2rem 4rem;
  ${TitleStyles};
  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;
    margin-top: 4rem;
    color: #fff;
    .service {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5vw;
      padding: 0 3vw;
      margin-bottom: 4rem;
      img {
        height: 2.8rem;
      }
      p {
        text-align: center;
        line-height: 2rem;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
      }

      button {
        padding: 0.6rem 3rem;
        letter-spacing: 0.2rem;
        border-radius: 2rem;
        font-size: 1.1rem;
        border: none;
        color: black;
        /* background-color: #fc4958; */
        background-color: #44ff46;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
    .yellow {
      /* button {
        background-color: #f9c74f;
        &:hover {
          background-color: #fc4958;
        }
      } */
      button {
        color: black;
        background-color: #ffffff;
        &:hover {
          background-color: #44ff46;
        }
      }
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    margin: 2rem;
    .services {
      grid-template-columns: 1fr;
    }
  }
`;
