import test from "../../images/2flycharacter.svg";
import test1 from "../../images/2flycharacter2.svg";
import currentIMG from "../../images/updated-2fly-sketch.svg";

// export const ArtDataMission = {
//   id: "mission",
//   lightBg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: " Mission ",
//   topLine2: "Our Process",
//   one: "I. Discover",
//   // one: "I.  Discover Your Archetype",
//   two: "II. Strategize ",
//   // two: "II. Tailored Archetypal Strategy ",
//   three: "III. Create",
//   // three: "III. Archetype-Driven Creation",
//   four: "IV. Refine",
//   // four: "IV.  Iterative Feedback and Refinement",
//   five: " V. Deliver",
//   // five: "V. Archetypal Brand Delivery",
//   six: " VI. Evolve ",
//   // six: "VI. Ongoing Archetypal Evolution ",
//   description1:
//     // "Each brand and client is unique, and our work should reflect that. We start by getting to know each other and discussing your goals and expectations. That is our first step and the foundation of our newly formed partnership.",
//     // "We want to get to know you and your unique brand before we start working together. This is the foundation of our partnership. ",
//     "Before diving into any project, the goal is to uncover the core archetype that resonates with your brand's identity. This foundational step ensures the journey is as unique as your brand itself.",
//   description2:
//     // "Together, we determine what you are looking for and put together a solution that meets your needs. We will also determine a timeline for your project.",
//     "With a clear understanding of your brand's archetypal essence, a customized strategy is outlined that aligns with your objectives. This plan encompasses branding and marketing initiatives designed to connect with your audience on a deeper, more psychological level. A project timeline will also be established to respect your pace and preferences. ",
//   // "Let's determine what you're looking for and create a tailored solution that meets your needs. We'll also establish a timeline for your project. ",
//   description3:
//     // "Now that everything has been laid out, we can begin the creative process. Whether we build virtual interactions, illustrations, web applications, or NFTs, every step is handled with meticulous care.",
//     "Equipped with a strategic vision, solutions are crafted that breathe life into your brand's archetype. From AI-driven tools and web applications to illustrative content and storytelling, every creation is infused with intention and archetypal alignment.",
//   // "Now that everything is in place, we can begin the creative process. Whether we're building AI-powered chatbots, illustrations, progressive web applications, or NFTs, every step comes with meticulous care.",
//   description4:
//     // "Upon receiving drafts of our work, you will be able to check if it meets your vision and needs. If not, we'll make adjustments based on your feedback.",
//     "As your archetype is brought to life across various mediums, your insights and feedback are invaluable. You are encouraged to review the creations and share your thoughts, ensuring that each iteration brings the project closer to a fully realized archetypal expression of your brand.",
//   // "Once you receive drafts of our work, you can review it to see if it matches your vision and needs. If not, we'll make adjustments based on your feedback. ",
//   description5:
//     // "We ensure that the final product is up to Studio Voice2fly's quality standards and that you have everything you need.",
//     "Commitment to excellence ensures that the final deliverables not only meet high standards of quality but also encapsulate your brand's unique archetype, ready to make a lasting impact in the market. All necessary tools and guidance will be provided to deploy your new assets confidently.",
//   // "We'll ensure that the final product meets our high-quality standards and that you have everything you need. ",
//   description6:
//     // "The art of visual and verbal communication is constantly evolving. We would be delighted to stay in contact and work with you on future projects. Please do not hesitate to contact us.",
//     "In the ever-evolving landscape of branding and marketing, staying true to your archetypal narrative is key to enduring relevance. Ongoing partnership is encouraged, helping your brand navigate future challenges and opportunities with archetypal precision. Reach out anytime—collaboration is always welcome.",
//   // "The world of visual and verbal communication is constantly evolving. We'd love to stay in contact and work with you on future projects. Please don't hesitate to reach out.    ",
//   buttonLabel: "Explore",
//   imgStart: false,
//   alt: "mission",
//   dark: true,
//   primary: true,
//   darkText: false,
// };

export const ArtDataMission = {
  id: "mission",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Mission",
  topLine2: "Our Process",
  one: "I. Discover",
  two: "II. Strategize",
  three: "III. Create",
  four: "IV. Refine",
  five: "V. Deliver",
  six: "VI. Evolve",
  description1:
    "Our journey together begins with a deep dive into understanding your business, industry, and unique challenges. We conduct a comprehensive discovery session to identify your goals, pain points, and the opportunities where our expertise can make the most impact. This step ensures that we align our strategies with your specific needs, laying a solid foundation for our partnership.",
  description2:
    "Armed with insights from our discovery phase, we develop a customized strategy that aligns with your business objectives. Our strategy encompasses actionable steps designed to optimize your brand’s positioning, streamline operations, and enhance customer engagement.",
  description3:
    "With a clear strategy in place, we move into the creation phase, where ideas transform into tangible solutions. Whether it’s developing new customer service processes, designing branding elements, or building digital tools, each creation is tailored to your brand’s unique needs.",
  description4:
    "As we implement the solutions, your feedback becomes invaluable. We engage in a continuous cycle of review and refinement to ensure that the deliverables not only meet but exceed your expectations. This iterative process allows us to adapt to any new insights or changes in direction, ensuring that the project stays aligned with your evolving needs.",
  description5:
    "After rigorous testing and refinement, we deliver the final solutions with full confidence in their ability to meet your business needs. Our focus on quality assurance ensures that the solutions are not only effective but also scalable and sustainable for the long term.",
  description6:
    "Our relationship doesn’t end with delivery. As your business grows and the market evolves, we continue to offer our support, helping you adapt and thrive. We monitor the performance of the solutions, provide ongoing consultation, and are always ready to assist with new challenges or opportunities.",
  buttonLabel: "Explore",
  imgStart: false,
  alt: "mission",
  dark: true,
  primary: true,
  darkText: false,
};
