import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { lighten, darken } from "polished";
import LogoIMG from "../../assets/logo192.png";

// Styled components
const ChatWindowContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px; // Fixed height
  width: 440px; // Fixed width
  z-index: 999;
  border-radius: 8px 8px 0px 0px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #000;
  background-color: ${lighten(0.2, "#43fe47")};
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  touch-action: pan-y;

  /* Customize scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }

  @media (max-width: 480px) {
    width: 300px;
  }
`;

const PrimaryChatWindow = styled.div`
  padding: 10px 0px 0px 10px;
  min-height: 100%;
  width: 100%;
  background-color: transparent;
  @media (max-width: 480px) {
    width: 100%;
    padding-right: 10px;
  }
`;

const ChatMessageContainer = styled.div`
  max-height: 550px;
  width: calc(100% - 20px); // Subtracting 20px to account for the right margin
  padding: 0px 0px 0px 0px; // Removed right padding
  margin-right: 20px; // Added right margin
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    width: calc(100% - 5px); // Adjusted for mobile to maintain layout
    margin-right: 10px; // Slightly smaller margin for mobile
  }
`;

const ChatMessageText = styled.p`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #000;
  margin-bottom: 10px;
  background-color: ${({ isResponse }) => (isResponse ? "#43fe47" : "#ffffff")};
  touch-action: pan-y;
  user-select: none;
  font-size: ${(props) => props.fontSize || "16px"}; // Default size is 16px
  line-height: 1.4; // Adjust line height for better readability

  @media (max-width: 480px) {
    margin-right: 10px;
    font-size: ${(props) =>
      props.mobileFontSize || "14px"}; // Smaller font size for mobile
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0px);
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.2);
  }
  50% {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.3);
  }
  100% {
    transform: translateY(0px);
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.2);
  }
`;

const vibrationAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(0.5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-0.5deg); }
  100% { transform: rotate(0deg); }
`;

const LinkChatMessageText = styled(ChatMessageText)`
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    animation: ${floatAnimation} 2s ease-in-out infinite,
      ${vibrationAnimation} 0.1s linear infinite;
    background-color: ${lighten(0.1, "#43fe47")};
  }
`;

const MessageSender = styled.div`
  background-color: ${({ isResponse }) => (isResponse ? "#43fe47" : "#ffffff")};
  margin-right: ${({ isResponse }) => (isResponse ? "0" : "0px")};
  margin-left: ${({ isResponse }) => (isResponse ? "0px" : "0")};
`;

const LogoImage = styled.img`
  width: 30px;
  height: 30px;
  margin: 0px 10px 0px 0px;
  border-radius: 50%;
  padding: 4px;
  background-color: #000000;
`;

const UserIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserIconText = styled.p`
  margin: 0px 0px 0px 10px;
`;

const Form = styled.form`
  background-color: #43fe47;
  width: 100%;
  border-radius: 0px 0px 14px 14px;
  padding: 0px 8px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000;
  @media (max-width: 480px) {
    background-color: ${darken(0.2, "#43fe47")};
    padding: 0px 0px 0px 0px;
    border-top: 1px solid #000;
    border-bottom: 0px solid #000;
    border-right: 0px solid #000;
    border-left: 0px solid #000;
  }
`;

const UserInputField = styled.textarea`
  flex: 1;
  padding: 10px;
  height: 38px;
  border: none;
  border-radius: 0px 0px 0px 8px;
  background-color: ${lighten(0.2, "#43fe47")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  border: 1px solid #000;
  font-size: 16px;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  resize: none;
  overflow: hidden;
  &::placeholder {
    color: #888888;
  }
`;

const UserInputButton = styled.button`
  margin: 8px 0px 8px 0px;
  padding: 10px 20px;
  border: none;
  border-radius: 0px 0px 8px 0px;
  background-color: #43fe47;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #000;
  color: #000000;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${lighten(0.2, "#43fe47")};
  }
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  touch-action: manipulation;
`;

const typingAnimation = keyframes`
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
`;

const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TypingDot = styled.span`
  height: 7px;
  width: 7px;
  float: left;
  margin: 0 1px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
  animation: ${typingAnimation} 1s infinite 0.2s;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const greetings = [
  "Hey there! 👋 I'm James, the Chief Integration Specialist at Studio Voice2Fly.",
  "Hello! 👋 I'm James, and I'm the Chief Integration Specialist here at Studio Voice2Fly.",
  "Greetings! I'm James, your Chief Integration Specialist at Studio Voice2Fly. 👋",
  "Hi there! James here, Chief Integration Specialist at Studio Voice2Fly. Nice to meet you! 👋",
  "Welcome! 👋 My name is James, and I'm the Chief Integration Specialist for Studio Voice2Fly.",
];

const aiConstructionMessages = [
  "While our AI is under construction, I'm here to chat with you personally.",
  "Our AI is still in development, but I'm available to assist you directly.",
  "As we fine-tune our AI, I'm here to provide personalized support.",
  "Our AI is learning the ropes, so I'm stepping in to chat with you myself.",
  "We're working on our AI, but don't worry – I'm here to help you personally.",
];

const callToActionMessages = [
  "Ready to discuss your business goals? Let's schedule a call! 📅",
  "Want to explore how we can boost your business? Let's set up a call! 📞",
  "Curious about what we can do for you? Let's chat on a call! 🗓️",
  "Interested in taking your business to the next level? Schedule a call with me! 📈",
  "Shall we discuss your business objectives? Book a call and let's talk! 🤝",
];

const getRandomMessage = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

const getRandomGreeting = () => {
  const randomIndex = Math.floor(Math.random() * greetings.length);
  return greetings[randomIndex];
};

const messages = [
  {
    text: getRandomMessage(greetings),
    isResponse: true,
    isLogo: true,
  },
  {
    text: getRandomMessage(aiConstructionMessages),
    isResponse: true,
    isLogo: true,
  },
  {
    text: getRandomMessage(callToActionMessages),
    isResponse: true,
    isLogo: true,
    isLink: true,
    link: "https://calendly.com/studio-voice2fly/30min",
  },
];

const UserIconWrapper = ({ text, icon: Icon }) => (
  <UserIconContainer>
    <Icon />
    <UserIconText>{text}</UserIconText>
  </UserIconContainer>
);

const ChatMessage = ({
  text,
  isResponse,
  isLogo,
  icon: Icon,
  isLink,
  link,
}) => {
  const MessageContent = isLink ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <LinkChatMessageText isResponse={isResponse}>{text}</LinkChatMessageText>
    </a>
  ) : (
    <ChatMessageText isResponse={isResponse}>{text}</ChatMessageText>
  );

  return (
    <ChatMessageContainer>
      {isResponse && isLogo ? (
        <>
          <MessageSender isResponse={isResponse} />
          <LogoImage src={LogoIMG} alt="Logo" />
        </>
      ) : (
        <UserIconWrapper icon={Icon} />
      )}
      {MessageContent}
    </ChatMessageContainer>
  );
};

const UserInput = () => {
  const [inputValue, setInputValue] = useState("");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    let timer;
    if (isSending) {
      timer = setTimeout(() => {
        setIsSending(false);
        setInputValue("");
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isSending]);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (inputValue.trim() && !isSending) {
      setIsSending(true);
      setInputValue("No connection!");
    }
  };

  const handleInputChange = (event) => {
    if (!isSending) {
      setInputValue(event.target.value);
    }
  };

  const preventZoom = (event) => {
    event.preventDefault();
  };

  return (
    <Form onSubmit={handleSendMessage}>
      <InputContainer>
        <UserInputField
          type="text"
          placeholder="Type your message..."
          onTouchStart={preventZoom}
          value={inputValue}
          onChange={handleInputChange}
          disabled={isSending}
          style={{ color: isSending ? "#888888" : "inherit" }}
        />
      </InputContainer>
      <UserInputButton type="submit" disabled={isSending}>
        Send
      </UserInputButton>
    </Form>
  );
};

const ChatWindow = () => {
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(true);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const showNextMessage = (index) => {
      if (index < messages.length) {
        setIsTyping(true);
        setTimeout(() => {
          setIsTyping(false);
          setVisibleMessages((prev) => [...prev, messages[index]]);
          setTimeout(() => showNextMessage(index + 1), 500);
        }, 1500 + Math.random() * 500);
      }
    };

    setTimeout(() => showNextMessage(0), 1500);
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [visibleMessages, isTyping]);

  return (
    <ChatWindowWrapper>
      <ChatWindowContainer ref={chatWindowRef}>
        <PrimaryChatWindow>
          {isTyping && visibleMessages.length === 0 && (
            <TypingIndicator>
              <LogoImage src={LogoIMG} alt="Logo" />
              <TypingDot />
              <TypingDot />
              <TypingDot />
            </TypingIndicator>
          )}
          {visibleMessages.map((message, index) => (
            <React.Fragment key={index}>
              <ChatMessage
                text={message.text}
                isResponse={message.isResponse}
                isLogo={message.isLogo}
                icon={message.icon}
                isLink={message.isLink}
                link={message.link}
              />
              {index === visibleMessages.length - 1 && isTyping && (
                <TypingIndicator>
                  <LogoImage src={LogoIMG} alt="Logo" />
                  <TypingDot />
                  <TypingDot />
                  <TypingDot />
                </TypingIndicator>
              )}
            </React.Fragment>
          ))}
        </PrimaryChatWindow>
      </ChatWindowContainer>
      {/* <UserInputWrapper> */}
      <UserInput />
      {/* </UserInputWrapper> */}
    </ChatWindowWrapper>
  );
};

const ChatWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  background-color: pink;
  padding: 10px;
  border-radius: 10px;
  background-color: ${darken(0.2, "#43fe47")};
  border: 1px solid #000000;
`;

// const UserInputWrapper = styled.div`
//   margin-top: auto;
// `;

export default ChatWindow;
