import React from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import {
  Container,
  Blocks,
  Blocks3,
  Blocks2,
  Blocks4,
  BlocksContainer,
  CarouselBackground,
} from "./CarouselElements";
import {
  FaInstagram,
  FaTwitter,
  FaSketch,
  FaAws,
  FaReact,
} from "react-icons/fa";
import {
  SiAmazonalexa,
  SiFirebase,
  SiBootstrap,
  SiEthereum,
  SiGooglechrome,
  SiOpenai,
  SiBlender,
  SiUnity,
  SiWeb3Dotjs,
} from "react-icons/si";
import { MdApps } from "react-icons/md";
import { DiOpensource } from "react-icons/di";
import { RiQrCodeLine } from "react-icons/ri";
import { GiArtificialIntelligence } from "react-icons/gi";
import { TiCss3 } from "react-icons/ti";

const RevealCarousel = () => {
  const CarouselUI = ({ children }) => <Container>{children}</Container>;
  const Carousel = makeCarousel(CarouselUI);

  return (
    <>
      <CarouselBackground>
        <Carousel
          defaultWait={3000}
          maxTurns={100} /*wait for 1000 milliseconds*/
        >
          {/* <Slide right>
            <BlocksContainer>
              <Blocks4>Our Specialties</Blocks4>
            </BlocksContainer>
          </Slide> */}
          <Slide right>
            <BlocksContainer>
              <Blocks>
                <SiOpenai color="#010606" size={42} />

                {/* <FaReact color="#3ddf3e" size={42} /> */}
              </Blocks>
              <Blocks2>
                <SiEthereum color="#010606" size={42} />
              </Blocks2>
              <Blocks3>
                <GiArtificialIntelligence color="#010606" size={42} />
              </Blocks3>
            </BlocksContainer>
          </Slide>
          <Slide right>
            <BlocksContainer>
              <Blocks2>
                <SiWeb3Dotjs color="#010606" size={42} />
              </Blocks2>
              <Blocks3>
                <FaReact color="#010606" size={42} />
              </Blocks3>
              <Blocks>
                <SiFirebase color="#010606" size={42} />
              </Blocks>
            </BlocksContainer>
          </Slide>
          <Slide right>
            <BlocksContainer>
              <Blocks2>
                <MdApps color="#010606" size={42} />
              </Blocks2>
              <Blocks3>
                <SiBlender color="#010606" size={42} />
              </Blocks3>
              <Blocks>
                <SiUnity color="#010606" size={42} />
              </Blocks>
            </BlocksContainer>
          </Slide>
        </Carousel>
      </CarouselBackground>
    </>
  );
};

export default RevealCarousel;
