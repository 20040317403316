import React, { useState } from "react";
import "./accordianElements.css";
import FAQ from "./FAQ";

function Accordion() {
  const [faqs, setfaqs] = useState([
    {
      question: "Overview",
      title:
        "Do not rely on a website builder to control the future of your business.      ",

      answer:
        "There are quite a few technologies in the market today, each designed to meet a different need in web development. In an age when the demand for software far outpaces the supply of coders, increasing numbers of business owners are turning to website builders to fill in the gap - but at what cost?",
      answer2:
        "Imagine you have to choose a bike before you go on a trip. You are driving along a well-paved road in the woods. If you ride a light, narrow-wheeled racing bike, you'll travel much faster than if you ride a knobbly-wheeled mountain bike with suspension, as long as you stay on the road. As soon as you need to cut a new path off-road, you'll wish you had a mountain bike, as the racing bike will severely slow you down if not make you stop altogether.",
      answer3:
        " So does the 'speed bike'/advanced framework make you go faster? Yes, as long as you stay on the road (i.e. match your business needs to the website builder's capabilities). The moment you need to 'go off-road' (i.e. do something the website builder doesn't do), such tools make progress difficult or impossible.",
      answer4:
        "  Additionally, you as the business owner are no longer in control of your business, the company behind the website builder is.",
      answer5:
        " It is important to remember that 'no-code' is simply someone else's code that you have no control over.",
      answer6:
        "Q: What would you do if a larger company decided to purchase the website builder you used and implemented new paywalls or required you to rebuild your app?!",
      answer7:
        " Businesses shouldn't have to rely on tools that may negatively impact them down the road. Regardless of the size of your business, complete control is essential.",
      answer8:
        "Even though using a template or building from scratch can be a slower process requiring some coding knowledge, you won't lose momentum when you need to veer off the beaten path.",
      answer9:
        " Whether you use one of our free pre-built business templates or need us to develop a custom app for you, we are always happy to help.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="App">
      <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}

export default Accordion;
