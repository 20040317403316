import styled from "styled-components";
import Image from "./Data";
import { Link } from "react-router-dom";

export const InfoContainer = styled.div`
  color: #fff;
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  background: transparent;
  /* background: #010606; */
  /* margin-top: 100px; */

  @media screen and (max-width: 768px) {
    /* padding: 100px 0; */
    /* margin-top: 100px; */
    /* margin-top: -110px; */
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: 560px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col2' 'col1'` : `'col2 col2' 'col1 col1'`};
  }
`;

export const Column1 = styled.div`
  /* margin-bottom: 15px; */
  padding: 10px 10px 10px 10px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    /* padding: 10px 10px 10px 10px; */
  }
`;

export const Column2 = styled.div`
  /* margin-bottom: 15px; */
  /* padding: 0 15px; */
  grid-area: col2;

  /* margin-top: 6rem; */
  @media screen and (max-width: 768px) {
    /* margin-top: 90px; */
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  /* color: #44ff46; */
  font-size: 16px;
  line-height: 32px;
  /* margin-left: 0px; */
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 18px;
  letter-spacing: 0.01em;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  @media screen and (max-width: 480px) {
    font-size: 16px;
    /* margin: 30px 30px; */
    /* width: 400px; */
  }
`;

export const SubtitleMission = styled.p`
  font-size: 18px;
  /* font-weight: bold; */
  margin-bottom: 24px;
  /* text-transform: uppercase; */
  @media screen and (max-width: 480px) {
    /* align-items: center; */
  }
`;

export const SubtitleMain = styled.p`
  height: auto;
  /* font-family: "Orbitron", sans-serif; */
  /* letter-spacing: 4px; */
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  line-height: 1.1;
  /* text-transform: uppercase; */
  /*  */
  /* max-width: 440px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px; */
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  @media screen and (max-width: 480px) {
    margin-top: 32px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const BtnWrapLink = styled(Link)`
  text-decoration: none;
`;

export const ImgWrap = styled.div`
  /* max-width: 555px; */
  height: auto;
  @media screen and (max-width: 480px) {
    /* height: 420px; */
  }
`;

export const Img = styled.img`
  margin: 0 0 10px 0;
  padding-right: 0;
  height: 600px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    /* margin-top: -100px; */
  }
`;

export const OrbitronFont = styled.div`
  font-family: "Orbitron", sans-serif;
  /* font-size: 40px; */
`;
