import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import AlexaSection from "../components/AlexaSection";
import ReactSection from "../components/ReactSection";
import Services from "../components/Services";
import Footer from "../components/Footer";
import AboutMeSection from "../components/AboutMe";
import RevealCarousel from "../components/RevealCarousel";
import { aboutMe } from "../components/AboutMe/Data";
import { homeObjThree } from "../components/AlexaSection/Data";
import { homeObjTwo } from "../components/ReactSection/Data";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TwelveJungianCarousel from "../components/TwelveJungianCarouselLeft";
import AppointmentServices from "../components/AppointmentServices/Appointment";
import TheSensesCard from "../components/ImmersiveStoreComponents/TheSensesCard";
import Products4saleSection from "../components/Products4saleSection";
import { ProductsForFunding } from "../components/Products4saleSection/Data";
import RecapAI4saleSection from "../components/RecapAI4saleSection";
import { RecapAIProductsForFunding } from "../components/RecapAI4saleSection/Data";
import NewCarousel from "../components/NewCarousel";
import Subscriptions from "../components/Subscriptions";
import { ButtonLink } from "../components/API-button";

import TweetsCarousel from "../components/TweetCarousel";
import XArticlesComponent from "../components/Articles";
import ArchetypeUtilizationChecklist from "../components/AIUtilizationChecklist";
import ArchetypeExplorer from "../components/ArchetypeExplorer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* <ContainScroll> */}
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AppointmentServices />
      {/* <AboutMeSection {...aboutMe} /> */}
      {/* <NewCarousel /> */}
      {/* <RecapAI4saleSection {...RecapAIProductsForFunding} /> */}
      {/* <ReactSection {...homeObjTwo} /> */}
      {/* <TwelveJungianCarousel /> */}
      <ReactSection {...homeObjTwo} />
      <RevealCarousel />
      {/* <Products4saleSection {...ProductsForFunding} /> */}
      {/* <TwelveJungianCarousel /> */}
      <Container>
        <ArchetypeUtilizationChecklist />
      </Container>
      <AlexaSection {...homeObjThree} />
      <Subscriptions />
      <XArticlesComponent />
      {/* <TweetsCarousel /> */}
      {/* <TheSensesCard /> */}
      {/* <AppointmentServices /> */}
      {/* </ContainScroll> */}
      {/* <Footer /> */}
    </>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4em;
  margin-bottom: 2em;
  @media (max-width: 600px) {
    margin-top: 0.5em;
    margin-bottom: 0em;
  }
  // optional: flex-direction: column; if you want vertical alignment
`;
