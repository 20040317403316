import React from "react";
import voice2 from "../../images/voice2.svg";
import Development from "../../images/development.svg";
import Interaction_design from "../../images/interaction_design.svg";
import LostOnline from "../../images/lostOnline.svg";
import Progressive_app from "../../images/progressive_app.svg";
import builder from "../../images/builder.svg";
import landing_page from "../../images/landing_page.svg";
import undraw_new_ideas from "../../images/undraw_new_ideas.svg";
import undraw_programming from "../../images/undraw_programming.svg";
import { MdFingerprint } from "react-icons/md";
import styled from "styled-components";
import { Link } from "react-router-dom";
import VoicePageBG from "../../images/VoicePageBG.png";
import VoicePageBG2 from "../../images/VoicePageBG2.png";
import XArticlesComponent from "../Articles";

const Subscriptions = () => {
  return (
    <>
      {/* <BackgroundWrapper /> */}
      <SubscriptionsContainer id="subscriptions">
        <SubscriptionsWrapper>
          <SubscriptionsCard>
            <H1>Basic Agent</H1>
            <Features>
              <Feature>
                <Icon>&#10003;</Icon> 1 core agent for internal use
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Answers basic employee questions
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Handles 1000 monthly conversations
              </Feature>
            </Features>
            <SubscriptionsH2> Monthly Plan: $99/mo</SubscriptionsH2>
            <SubscriptionsH3>
              {" "}
              Annual Plan: $899/yr{" "}
              <p
                style={{
                  color: "#cc0000",
                  textTransform: "uppercase",
                  paddingTop: "5px",
                  fontSize: "12px",
                }}
              >
                (Save 25%!)
              </p>
            </SubscriptionsH3>
            <SubscriptionsP>
              Our starter plan provides an internal virtual agent to handle
              basic employee questions and simple conversations. Best for small
              teams getting started with the power of AI.
            </SubscriptionsP>
            <ButtonContainer>
              <Button>Get Started</Button>
            </ButtonContainer>
            <H3>Internal Basic Agent for SMBs</H3>
          </SubscriptionsCard>
          <SubscriptionsCard>
            <H1>Professional Agent</H1>
            <Features>
              <Feature>
                <Icon>&#10003;</Icon> 1 customized agent
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Dynamic dialogs with employees
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Integrates with 1 key internal system
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> 5,000 monthly conversation limit
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Sentiment analysis: $25/month
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Translation module: $20/month
              </Feature>
            </Features>
            <SubscriptionsH2> Monthly Plan: $249/mo</SubscriptionsH2>
            <SubscriptionsH3>
              {" "}
              Annual Plan: $2241/yr{" "}
              <p
                style={{
                  color: "#cc0000",
                  textTransform: "uppercase",
                  paddingTop: "5px",
                  fontSize: "12px",
                }}
              >
                (Save 25%!)
              </p>
            </SubscriptionsH3>
            <SubscriptionsP>
              This mid-level agent delivers more advanced natural language
              interactions customized for your business. Integrations and
              expanded message capacity empower employees with an AI expert.
            </SubscriptionsP>
            <ButtonContainer>
              <Button>Get Started</Button>
            </ButtonContainer>
            <H3>Internal Professional Agent for SMBs</H3>
          </SubscriptionsCard>
          <SubscriptionsCard>
            <H1>Enterprise Agent</H1>
            <Features>
              <Feature>
                <Icon>&#10003;</Icon> 1 highly customized agent
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Conversations tailored to company terms
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Integrates across multiple internal
                databases
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> 50,000 monthly conversation limit
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Usage analytics dashboard
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Custom modules available
              </Feature>
              <Feature>
                <Icon>&#10003;</Icon> Priority live support
              </Feature>
            </Features>
            <SubscriptionsH2> Monthly Plan: $499/mo </SubscriptionsH2>
            <SubscriptionsH3>
              {" "}
              Annual Plan: $4491/yr{" "}
              <p
                style={{
                  color: "#cc0000",
                  textTransform: "uppercase",
                  paddingTop: "5px",
                  fontSize: "12px",
                }}
              >
                (Save 25%!)
              </p>
            </SubscriptionsH3>
            <SubscriptionsP>
              Our premier agent plan offers full customization to match
              specialized industry vocabulary and existing infrastructure,
              scaling to serve your entire organization with detailed analytics.
              Unlocks the full capability of AI-powered conversations.
            </SubscriptionsP>

            <ButtonContainer>
              <Button>Get Started</Button>
            </ButtonContainer>
            <H3>Internal Enterprise Agent for SMBs</H3>
          </SubscriptionsCard>
        </SubscriptionsWrapper>
      </SubscriptionsContainer>
    </>
  );
};

export default Subscriptions;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${VoicePageBG});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const Features = styled.ul`
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Icon = styled.span`
  margin-right: 8px;
`;

const Button = styled.div`
  background-color: #45fe47;
  border: none;
  color: #000000;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 30px;
  width: 200px;
  margin: 2px 2px; /* Slight margin for spacing between buttons */
  border: 0.3px solid #000;
  transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
  &:hover {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around; /* This will space the buttons evenly */
  padding-top: 0px;
  border: 1px solid #41ff46;
  border-radius: 30px;
`;

export const SubscriptionsContainer = styled.div`
  padding: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url(${VoicePageBG2}); */
  background-position: center;
  background-size: cover;
  border: none; /* Add this line to remove the border */
  height: auto;
  z-index: 1;
  @media screen and (max-width: 1025px) {
    /* height: 900px; */
  }

  @media screen and (max-width: 768px) {
    /* height: 1100px; */
  }
`;

const SubscriptionsWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Initial setup with 3 columns
  grid-gap: 30px;
  padding: 20px;

  @media screen and (max-width: 1200px) {
    // Adjust this breakpoint as needed
    grid-template-columns: repeat(2, 1fr); // Change to 2 columns
  }

  @media screen and (max-width: 768px) {
    // Adjust this breakpoint as needed
    grid-template-columns: 1fr; // Change to 1 column
  }
`;

const SubscriptionsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: auto;
  padding: 10px 20px;
  width: 360px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #41ff46;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  color: #ffffff;
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

const SubscriptionsCardLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const SubscriptionsIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

const SubscriptionsH1 = styled.h1`
  font-family: "Orbitron", sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  color: #85ff87;
  margin-bottom: 64px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 700px) {
  }
`;

const SubscriptionsH2 = styled.h1`
  font-size: 0.8rem;
  padding: 20px 0px 0px 0px;
  color: inherit;
  text-align: center;
`;

const SubscriptionsH3 = styled.h2`
  font-size: 0.8rem;
  padding: 10px 0px 0px 0px;
  color: inherit;
  text-align: center;
`;

const SubscriptionsP = styled.p`
  font-size: 0.8rem;
  text-align: center;
  color: inherit;
  letter-spacing: 0.2px;
  padding: 20px 0px 20px 0px;
`;

const P = styled.p`
  padding-top: 20px;
  font-size: 1rem;
  color: inherit;
  letter-spacing: 0.2px;
`;

const H1 = styled.h1`
  margin-top: 40px;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 0.2px;
  text-align: center;
`;

const H3 = styled.h3`
  padding-top: 10px;
  margin-bottom: 40px;
  font-size: 0.6em;
  font-weight: 100;
  color: inherit;
  letter-spacing: 0.2px;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: inherit;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const TitleContainer = styled.div`
  padding: 50px 0;

  ${Title} {
    margin: 0 20px;
  }
`;
