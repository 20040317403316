import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const ArchetypeButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ArchetypeDetail = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
`;

const Prompt = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #333;
`;

const LoadingMessage = styled.p`
  color: #007bff;
  font-size: 18px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 18px;
  margin-top: 20px;
`;

// ArchetypeExplorer component
const ArchetypeExplorer = () => {
  const [selectedArchetype, setSelectedArchetype] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const archetypes = [
    "Rebel",
    "Magician",
    "Hero",
    "Creator",
    "Ruler",
    "Caregiver",
    "Innocent",
    "Sage",
    "Explorer",
    "Lover",
    "Joker",
    "Everyman",
  ];

  const fetchArchetypeData = async (archetypeName) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes/${archetypeName.toLowerCase()}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch archetype data");
      }
      const data = await response.json();
      setSelectedArchetype(data);
      generatePrompts(data);
    } catch (err) {
      setError("Error fetching archetype data. Please try again.");
      console.error("Error fetching archetype data:", err);
    } finally {
      setLoading(false);
    }
  };

  const generatePrompts = (data) => {
    const newPrompts = [];

    if (data.name) {
      newPrompts.push(
        `Explain the deeper significance behind the name and motto of ${data.name}.`
      );
    }
    if (data.mission) {
      newPrompts.push(
        `Describe how ${data.name}'s mission shapes their worldview.`
      );
    }
    if (data.traits) {
      newPrompts.push(
        `Expand on each of the traits associated with ${data.name}.`
      );
    }
    if (data.motivations) {
      newPrompts.push(`What drives ${data.name}'s actions and decisions?`);
    }
    if (data.behaviors) {
      newPrompts.push(`Explore the typical behaviors of ${data.name}.`);
    }
    if (data.interests) {
      newPrompts.push(
        `Describe how ${data.name}'s interests align with their mission and traits.`
      );
    }
    if (data.historicalExamples) {
      newPrompts.push(
        `How do the historical figures associated with ${data.name} embody its traits and mission?`
      );
    }
    if (data.mythologicalReferences) {
      newPrompts.push(
        `Explore the mythological references linked to ${data.name}.`
      );
    }
    if (data.practicalApplications) {
      newPrompts.push(
        `How can the traits and behaviors of ${data.name} be applied in real-world scenarios?`
      );
    }
    if (data.characteristics) {
      newPrompts.push(
        `Describe how ${data.name}'s characteristics influence their approach to life.`
      );
    }
    if (data.ratings) {
      newPrompts.push(
        `Discuss how ${data.name} would utilize their physical attributes in challenges.`
      );
    }

    setPrompts(newPrompts);
  };

  return (
    <Container>
      <h1>Archetype Explorer</h1>
      <div>
        {archetypes.map((archetype) => (
          <ArchetypeButton
            key={archetype}
            onClick={() => fetchArchetypeData(archetype)}
          >
            {archetype}
          </ArchetypeButton>
        ))}
      </div>
      {loading && <LoadingMessage>Loading archetype data...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {selectedArchetype && !loading && !error && (
        <ArchetypeDetail>
          <h2>{selectedArchetype.name}</h2>
          <h3>{selectedArchetype.motto}</h3>
          {prompts.map((prompt, index) => (
            <Prompt key={index}>{prompt}</Prompt>
          ))}
        </ArchetypeDetail>
      )}
    </Container>
  );
};

export default ArchetypeExplorer;
