import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TimerContainer = styled.div`
  font-size: 1em;
  text-align: center;
`;

const Timer = ({ date }) => {
  const [daysPassed, setDaysPassed] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const specifiedDate = new Date(date);
      const timeDiff = currentDate - specifiedDate;
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      setDaysPassed(days);
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return <TimerContainer>Last updated: {daysPassed} Days ago</TimerContainer>;
};

export default Timer;
