import React, { useState, useEffect } from "react";
import { FaBars, FaGift, FaBook, FaNewspaper } from "react-icons/fa";

import { PiEggCrackFill, PiEggFill } from "react-icons/pi";

import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavLogoStudio,
  NavLogoVoice2fly99,
  NavLogoContainer,
  NavLinkRouter,
  BtnDomLink,
  SocialIconLink,
} from "./NavbarElements";

import { ReactComponent as Logo } from "../../images/Logo/logo.svg";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <Logo className="logo" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {/* <NavItem>
              <SocialIconLink to="/about">About</SocialIconLink>
            </NavItem> */}
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Services
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="react"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Templates
                {/* Web + */}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="dapps"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                AI
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="subscriptions"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Subscriptions
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="Xarticles"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                <FaBook />
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <BtnDomLink
              to="//calendly.com/studio-voice2fly/30min"
              target="_blank"
            >
              Let's Talk!
            </BtnDomLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
