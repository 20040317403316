import React from "react";
import styled from "styled-components";

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center; // Horizontally center
  align-items: center; // Vertically center
`;

// Adjusted StyledButtonLink component
export const StyledButtonLink = styled.a`
  display: flex; // Use flex to center text inside
  justify-content: center; // Center content horizontally in the button
  align-items: center; // Center content vertically in the button
  padding: 10px 10px;
  background-color: #45fe47; // Example background color
  color: #010606; // Button text color
  text-decoration: none;
  /* font-weight: bold; */
  transition: background-color 0.3s ease;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  width: auto;
  &:hover {
    background-color: #ffffff; // Color on hover
  }
`;
// The ButtonLink component
export const ButtonLink = ({ url, text }) => (
  <StyledButtonLink href={url} target="_blank" rel="noopener noreferrer">
    {text}
  </StyledButtonLink>
);
