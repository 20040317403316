import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./Chat.scss";
import { useParams, useHistory } from "react-router-dom";
import data from "./data";
import Message from "./Message";
import Answer from "./Answer";
import Typing from "./Typing";
import { motion } from "framer-motion";
import Magician2 from "./images/magician2.png";
import BubbleMagician from "./images/Magician-noB2.png";
import SimpleForm from "./SimpleForm";
import ScrollToTop from "../../ScrollToTop";

export default function Chat() {
  const history = useHistory();
  const { slug, status } = useParams();
  const currentDialog = data.find((dialog) => dialog.slug === slug) || data[0];
  const [messages, setMessages] = useState([]);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setMessages([]);
    setCompleted(false);
    if (status !== "completed") {
      currentDialog.messages &&
        currentDialog.messages.forEach((message, idx) => {
          setTimeout(function() {
            setMessages((prevMessages) => [...prevMessages, message]);
            if (idx + 1 === currentDialog.messages.length) {
              setTimeout(function() {
                if (slug === "portfolio") {
                  history.push("/portfolio");
                }
                setCompleted(true);
              }, 1500);
            }
          }, idx * 2500);
        });
    } else {
      setMessages(currentDialog.messages);
      setCompleted(true);
    }
  }, [slug, status]);

  return (
    <>
      <ScrollToTop />
      <div className="magician__container">
        <section className="Chat">
          <div className="Window">
            <img className="Head" src={BubbleMagician} alt="Rebel" />
            <div className="MessageContainer">
              {messages.map((message, idx) => (
                <div key={`message-${idx}`}>
                  <Message status={status} message={message} />
                </div>
              ))}
            </div>
            {/* {!completed && <Typing />} */}
            <div className="AnswerContainer">
              {completed &&
                currentDialog.answers.map((message, idx) => (
                  <div key={`answer-${idx}`}>
                    <Answer data={message} />
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
