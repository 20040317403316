import React from "react";
import styled from "styled-components";

function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">
        <TitleMain>{faq.title}</TitleMain>
        {/* <ReadTime> 1.5 minute(s) read 340 words 200 - 250 avg read speed</ReadTime> */}
        <P1>{faq.answer}</P1>
        <P2>{faq.answer2}</P2>
        <P3>{faq.answer3}</P3>
        <P4>{faq.answer4}</P4>
        <P5>{faq.answer5}</P5>
        <P6>{faq.answer6}</P6>
        <P7>{faq.answer7}</P7>
        <P8>{faq.answer8}</P8>
      </div>
    </div>
  );
}

export default FAQ;
const Title = styled.div`
  padding: 20px 20px 0px 0px;
  color: #000;
  letter-spacing: 0.5px;
  /* background-color: #44ff46; */
  /* font-weight: bold; */
  font-size: 1rem;
  background-color: #fff;
  text-transform: uppercase;
`;

const TitleMain = styled.div`
  padding: 40px 20px 0px 20px;
  color: #000;
  /* letter-spacing: 0.5px; */
  /* background-color: #44ff46; */
  font-weight: bold;
  font-size: 1rem;
  background-color: #fff;
  text-transform: uppercase;
`;

const P1 = styled.div`
  padding: 20px 20px 0px 20px;
  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P2 = styled.div`
  padding: 20px 20px 0px 20px;

  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P3 = styled.div`
  padding: 20px 20px 0px 20px;
  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P4 = styled.div`
  padding: 20px 20px 0px 20px;
  color: #000;
  /* background-color: #44ff46; */
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #fff;
`;
const P5 = styled.div`
  padding: 20px 20px 0px 20px;
  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P6 = styled.div`
  padding: 20px 20px 0px 20px;
  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P7 = styled.div`
  padding: 20px 20px 0px 20px;
  /* background-color: #44ff46; */
  background-color: #fff;
  color: #000000;
`;
const P8 = styled.div`
  padding: 20px 20px 40px 20px;
  color: #000;
  background-color: #fff;
`;
