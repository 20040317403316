const data = [
  {
    slug: "start",
    messages: [
      "Hi there! I'm excited to meet you!",
      // "Hello, Innocent is my name, and I aim to build happiness, and optimism.",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/innocent/characteristics",
      },
      {
        label: "Build a innocent character",
        link: "/innocent/build",
      },
      {
        label: "Back home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "My mission is to inspire people to do good in every way and to live a pure life. I am a strong believer in trust, and I am committed to helping others in any way I can.",
      "Also known as the rug rat, greenhorn, ingenue, the virgin, youngster, juvenile, minor, the baby, and the angel.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/innocent/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a innocent character",
        link: "/innocent/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a innocent character",
        link: "/innocent/build",
      },
      {
        label: "Contact",
        link: "/innocent/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/innocent/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "companionship",
    messages: ["Innocent character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Innocent character hospitality under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Innocent character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Innocent character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
