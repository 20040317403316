import React from "react";
import styled from "styled-components";

const FundingBarSection = ({ amountRaised, goal }) => {
  const percentage = (amountRaised / goal) * 100;

  return (
    <>
      <Description>
        <Wrapper>
          <ProgressBar>
            <Bar percentage={percentage} />
          </ProgressBar>
          <Stat>
            We have currently raised ${amountRaised} toward our ${goal} goal.
            Please consider supporting our project on{" "}
            <a href="//www.kickstarter.com/" target="_blank">
              Kickstarter
            </a>
            !
          </Stat>
        </Wrapper>
      </Description>
    </>
  );
};

export default FundingBarSection;

const Wrapper = styled.div`
  text-align: center;
`;

const ProgressBar = styled.div`
  height: 30px;
  border-radius: 50px;
  border: 1px solid #333;
`;

const Bar = styled.div`
  height: 100%;
  border-radius: ${(p) =>
    p.percentage > 0
      ? p.percentage >= 90
        ? "50px 50px 50px 50px"
        : "50px 0px 0px 50px"
      : "0px"};
  width: ${(p) => p.percentage}%;
  background: ${(p) => (p.percentage < 70 ? "#44FD47" : "#44FD47")};
  border-right: 1px solid #333;
  display: ${(p) => (p.percentage === 0 ? "none" : "block")};
`;

const Stat = styled.p`
  font-size: 18px;
  margin-top: 10px;
  color: #333333;
`;

const Description = styled.p`
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  padding: 10px 20px;
  a {
    /* background-color: #000; */
    color: #333;
    /* color: #43fe47; */
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50px;
  }
  a:hover {
    color: #43fe47;
  }
`;
