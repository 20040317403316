import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { HiCursorClick } from "react-icons/hi";
import { GiClick } from "react-icons/gi";
import { TiArrowBack } from "react-icons/ti";
import { TiChevronLeft } from "react-icons/ti";
import {
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaUndo,
  FaExchangeAlt,
} from "react-icons/fa";

import { Link as LinkR } from "react-router-dom";
// import { Link as LinkS } from "react-scroll";

export default function ImmersiveStoreNavbarScrollDis() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <NavLogo to="/">
          <div onMouseEnter={onHover} onMouseLeave={onHover}>
            {hover ? (
              <FaAngleDoubleLeft size={28} color="#44FF46" />
            ) : (
              <FaAngleLeft size={28} />
            )}
          </div>
        </NavLogo>
        <CenterText>The Future Store</CenterText>
        <NavBtn>
          <NavBtnLink
            to="//calendly.com/studio-voice2fly/product-brainstorm"
            target="_blank"
          >
            Learn more
          </NavBtnLink>
        </NavBtn>
      </NavbarContainer>
    </Nav>
  );
}

const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#010606" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const CenterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    /* background-color: #45fe47; */
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: #44ff46;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  /* margin-left: 24px; */
  font-weight: bold;
  text-decoration: none;
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
`;

const NavBtnLink = styled(LinkR)`
  /* font-family: "Orbitron", sans-serif; */
  font-weight: 100;
  border-radius: 44px;
  background: #44ff46;
  transition: 0.5s ease-in-out;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  /* letter-spacing: 3px; */
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    /* background: linear-gradient(to right, #44ff46, #ffffff); */
    color: #000;
  }
`;
