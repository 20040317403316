import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: auto; */
  /* max-width: auto; */
  /* display: flex; */
  padding: 1rem 2rem 0rem 2rem;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px 10px; */
  /* background: pink; */
  /* background: #3ddf3f; */

  @media screen and (max-width: 960px) {
    /* padding: 0rem 2.5rem 0rem 2.5rem; */
  }
`;

export const ServicesWrapper = styled.div`
  width: 940px;
  /* margin: 0 auto; */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  /* padding: 0rem 1rem; */

  @media screen and (max-width: 1000px) {
    /* height: 800px; */
    /* padding: 0 20px; */
    /* padding: 0px 20px; */
    /* margin-bottom: 0.5rem; */
    width: 100%;
  }
`;

export const ServicesHeader = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  /* margin-right: 10px; */
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding: 0 30px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    /* padding: 0 20px; */
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  /* background: transparent; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* width: 430px; */
  /* max-width: auto; */
  /* min-height: 340px; */
  padding: 1.5em 2em 1em 2em;
  /* margin-top: 10px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  @media screen and (max-width: 760px) {
    width: auto;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesCardLink = styled(LinkS)`
  text-decoration: none;
  color: #000;
  /* color: #fff; */
`;

export const ServicesIcon = styled.img`
  height: auto;
  width: auto;
  margin-top: 16px;
  margin-bottom: 10px;
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  /* font-family: "Orbitron", sans-serif; */
  /* font-weight: 900; */
  /* color: #fff; */
  width: auto;
  color: #000;
  font-size: 1rem;
  /* text-transform: uppercase; */
  /* font-size: 1rem; */
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: 760px) {
    /* font-size: 0.85rem; */
  }
`;

export const DownArrow = styled.img`
  height: 40px;
  overflow-x: hidden;
  animation: animateDown infinite 1.5s;
`;

export const ServicesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;
