const data = [
  {
    slug: "start",
    messages: [
      "Hello, fellow dreamer! Let's bring some new ideas to life together!",
      // "If you can imagine it, it can be done!",
      // "Realizing a vision is my goal... ",
      // "creativity and imagination is my talent!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/creator/characteristics",
      },
      {
        label: "Build a creator character",
        link: "/creator/build",
      },
      {
        label: "Back home.",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "I am here to create something new and exciting. I have a passion for creativity and innovation, and I love to explore new ideas and concepts. I believe that everyone has the potential to be creative and to contribute something new and valuable to the world.",
      // "I am fulfilled by creating meaningful and special things and pride myself on being original.",
      "Also known as the artist, innovator, builder, dreamer, writer, author, composer, designer, maker, inventor, producer, developer, generator, architect, engineer, and mastermind.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/creator/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a creator character",
        link: "/creator/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a creator character ",
        link: "/creator/build",
      },
      {
        label: "Get help",
        link: "/creator/contact",
      },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/creator/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "companionship",
    messages: ["Creator character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "more archetypes", link: "/mint" },
    ],
  },
  {
    slug: "fnb",
    messages: ["Creator character food and beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Creator character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Creator character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "automotive",
    messages: ["Creator character automotive under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
