import React from "react";
import styled from "styled-components";
import Termsofservice from "../images/termsofservice.jpg";
import { Link } from "react-router-dom";

export const HomeButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 50px;
  background: #43fe47;
  color: #333;
  text-decoration: none;
  border: none;
  border: 0.8px solid #000000;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
  // media queries
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #000000;
    border: 0.8px solid #43fe47;
    color: #43fe47;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  /* border-radius: 10px 10px 10px 10px; */
  margin: 10px 0px 10px 0px;
  @media (max-width: 600px) {
    border-radius: 0px;
  }
`;

const Page = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  background-color: rgba(255, 255, 255);
  @media (max-width: 600px) {
    padding: 20px;
    padding: 40px 0px;
  }
`;

const Section = styled.div`
  margin-top: 0px;
`;

const Header = styled.h1`
  padding: 20px 20px 0px 20px;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-top: 50px;
  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

const SubText = styled.h3`
  padding: 20px 20px 0px 20px;
  font-size: 26px;
  font-weight: bold;
  color: #333;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const SubHeader = styled.h4`
  padding: 20px 20px 0px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;

  @media (max-width: 600px) {
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  padding: 10px 20px;
  a {
    /* background-color: #000; */
    color: #333;
    /* color: #43fe47; */
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50px;
  }
  a:hover {
    color: #43fe47;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  /* border-radius: 10px 10px 10px 10px; */
  margin-top: 20px;
  /* margin-bottom: -20px; */
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
  }
  @media (max-width: 600px) {
    /* margin-bottom: -45px; */
  }
`;

const FundingGoal = styled.div`
  margin: 30px 0;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0px;
`;

const Progress = styled.div`
  height: 20px;
  background: #ddd;
  border-radius: 10px;
  margin: 20px 20px;
  &::after {
    content: "";
    display: block;
    height: 100%;
    background: #20a4f3;
    border-radius: 10px;
    width: ${(props) => props.progress}%;
  }
`;

const FundingSection = () => (
  <>
    <SubText>Key Features</SubText>
    <Description>
      <Description>
        <ul>
          <li>Instant Summaries - Concise summaries generated through NLP</li>
          <li>
            Plain Language Translation - Translates complex jargon into clear
            language
          </li>
          <li>Insight Extraction - Highlights key details and action items</li>
          <li>Customizable Summaries - Adjustable summary length and depth</li>
          <li>Interactive Assistance - AI-powered assistant for guidance</li>
        </ul>
      </Description>
    </Description>
    <SubText>Use Cases</SubText>
    <Description>
      <Description>
        <ul>
          <li>Insurance Policies - Summarize policy terms</li>
          <li>Banking - Simplify documents and fee lists</li>
          <li>Software/Tech - Manage EULAs and terms of service</li>
          <li>Wireless Carriers - Streamline multi-page contracts</li>
          <li>Credit Cards - Clarify credit card agreements</li>
          <li>Travel Sites - Handle reservations, cancellations, policies</li>
          <li>Pharmaceutical - Simplify health warnings on products</li>
        </ul>
      </Description>
    </Description>
    <SubText>Technology Stack</SubText>
    <Description>
      <Description>
        <ul>
          <li>UI Components - Built with React</li>
          <li>Responsive Design - Consistent experience across platforms</li>
          <li>
            NLP - Local text processing with TensorFlow Lite + WebAssembly
          </li>
          <li>Hosting - Firebase Hosting for delivery</li>
          <li>Account Management - Firebase Auth</li>
          <li>User Data/Settings - Firebase Firestore for access</li>
          <li>Serverless Logic - Firebase Functions</li>
          <li>Cross-Browser - WebExtension APIs for broad compatibility</li>
          <li>Code Structuring - TypeScript for type safety</li>
          <li>Performance - Limits DOM manipulation, async logic</li>
          <li>Accessibility - Ensures usability for all users</li>
          <li>Error Handling - Seamless user experience</li>
          <li>Guidelines - Follows platform-specific guidelines</li>
        </ul>
      </Description>
    </Description>

    {/* <Description>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. A dolor quod eum
      nemo minus quisquam enim neque, expedita suscipit incidunt officia ut
      impedit mollitia est modi unde laboriosam perspiciatis vel?
    </Description> */}
    {/* <SubHeader>Lorem ipsum dolor sit.</SubHeader> */}

    <SubText>Year 1 Plan:</SubText>
    <Description>
      <Description>
        <ul>
          <li>Develop core NLP summarization features</li>
          <li>Release beta for user feedback</li>
          <li>Optimize algorithms and UI based on feedback</li>
          <li>Officially launch the platform</li>
          <li>Run targeted marketing campaigns</li>
        </ul>
      </Description>
    </Description>

    {/* <Video autoPlay loop muted playsInline preload="auto">
      <source src={SunsetWalk} type="video/mp4" />
    </Video> */}
  </>
);

function MemoryPalAILearnMore() {
  return (
    <>
      <Page>
        <HomeButton to="/">Home</HomeButton>
        <Header>Overview</Header>
        <Description>
          RecapAI is an AI-powered platform that simplifies complex documents
          and text to enhance comprehension.
        </Description>
        <Image src={Termsofservice} />
        {/* <VideoContainer>
          <iframe src="https://www.youtube.com/embed/123" />
        </VideoContainer> */}
        {/* <Video autoPlay loop muted playsInline preload="auto">
          <source src={FamWalkintToHorizon} type="video/mp4" />
        </Video> */}

        <FundingSection />
      </Page>
    </>
  );
}

export default MemoryPalAILearnMore;
