import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: "Mark Constantine Inducil",
    subTitle: '"Reflective State of Mind" ',
  },
  {
    id: uuidv4(),
    title: "Silvio Vieira",
    subTitle: '"Enchantments Awakening"',
  },
  {
    id: uuidv4(),
    title: "Angga Tantama",
    subTitle: '"Punishment"',
  },
  {
    id: uuidv4(),
    title: "David Sossella",
    subTitle: '"The Celestial Dragon"',
  },
];

export default dataSlider;
