import styled from "styled-components";

export const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: auto;
  height: 65px;
  /* height: 150px; */
  /* color: #fff; */
`;

export const CarouselBackground = styled.div`
  /* background: #44ff46; */
  background: #45fe47;
  /* background: #3ddf3e; */
  /* background: #000000; */
  /* background: #f9f9f9; */
`;

export const BlocksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
`;

export const Blocks = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 33.3%;
  text-align: center;
`;

export const Blocks2 = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 33.3%;
  text-align: center;
  color: #000;
`;

export const Blocks3 = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 33.3%;
  text-align: center;
`;

export const Blocks4 = styled.div`
  font-family: "Orbitron", sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  /* color: #85ff87; */
  color: #3ddf3e;
  margin: 5px 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`;
