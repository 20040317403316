import styled from "styled-components";
import { Link } from "react-router-dom";

export const InfoContainer = styled.div`
  color: #fff;
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  background: transparent;
  /* background: #010606; */
  height: auto;
  padding-top: 100px;
  @media screen and (max-width: 768px) {
    padding: 50px 0px 0px 0px;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const CenterTitle = styled.div`
  margin-top: 0px;
  height: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    /* height: 50px; */
    margin-top: 50px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  /* padding: 0 15px; */

  grid-area: col1;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const Column2 = styled.div`
  /* margin-bottom: 15px; */
  padding: 0 15px;
  grid-area: col2;
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #44ff46;
  font-size: 16px;
  /* line-height: 18px; */
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    line-height: 20px;
    text-align: center;
  }
`;

export const TopLine2 = styled.p`
  font-family: "Orbitron", sans-serif;
  font-weight: 900;
  color: #fff;
  font-size: 28px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 48px;
`;

export const Heading = styled.h1`
  margin-bottom: 18px;
  letter-spacing: 0.01em;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const BtnWrapLink = styled(Link)`
  text-decoration: none;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    width: 400px;
    height: 700px;
    margin-top: -150px;
  }
  @media screen and (max-width: 480px) {
    width: 350px;
    height: 650px;
    margin-top: -120px;
  }
`;

export const OrbitronFont = styled.div`
  font-family: "Orbitron", sans-serif;
  /* font-size: 40px; */
`;
