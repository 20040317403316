import React, { useState } from "react";
import "./Slider.css";
import BtnSlider from "./BtnSlider";
import dataSlider from "./dataSlider";
import { MdClose } from "react-icons/md";

export default function Slider(props) {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return props.trigger ? (
    <div className="container-slider">
      {dataSlider.map((obj, index) => {
        return (
          <>
            <div
              key={obj.id}
              className={
                slideIndex === index + 1 ? "slide active-anim" : "slide"
              }
            >
              <MdClose
                className="react-icon-styling"
                onClick={() => props.setTrigger(false)}
              />
              <img src={process.env.PUBLIC_URL + `/Imgs/img${index + 1}.jpg`} />
              {/* <img src={Dragon} alt="loading..." /> */}
              <div className="containerBox">
                <p className="title">{obj.title}</p>
                <p className="subtitle">{obj.subTitle}</p>
              </div>
            </div>
          </>
        );
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} />

      <div className="container-dots">
        {Array.from({ length: 4 }).map((item, index) => (
          <div
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? "dot active" : "dot"}
          ></div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
}
