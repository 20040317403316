import React, { useState, useLayoutEffect, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages";
import NotFoundPage from "./pages/404";
import AboutPage from "./pages/about";
import voiceappsPage from "./pages/voiceapps";
//
import Joker from "./components/Chatbots/Joker";
import Lover from "./components/Chatbots/Lover";
import Everyman from "./components/Chatbots/Everyman";
import Hero from "./components/Chatbots/Hero";
import Magician from "./components/Chatbots/Magician";
import Rebel from "./components/Chatbots/Rebel";
import Caregiver from "./components/Chatbots/Caregiver";
import Creator from "./components/Chatbots/Creator";
import Explorer from "./components/Chatbots/Explorer";
import Sage from "./components/Chatbots/Sage";
import Ruler from "./components/Chatbots/Ruler";
import Innocent from "./components/Chatbots/Innocent";
//
import ImmersiveStore from "./pages/futurestore";
import FilmscriptsLearnMore from "./pages/filmScripts";
import Webapps2 from "./pages/webapps";
import VoiceappsPage from "./pages/voiceapps";
import Mintpage from "./pages/mintpage";
import Footer from "./components/Footer";
// import Magician from "./components/Chatbots/Magician";
import Slider from "./components/Slider/Slider";
import RevealCarousel from "./components/RevealCarousel";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import PulseLoader from "react-spinners/PulseLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import Mychatbot from "./components/MainChatbot/MyChatbot";
import MemoryPalAILearnMore from "./pages/MemoryPalAILearnMore";
import RecapAILearnMore from "./pages/RecapAILearnMore";
import PrivacyPolicy from "./pages/privacy-policy-archetypeAPI";
import backgroundImage from "./images/VoicePageBG.png";
import FilmScripts from "./pages/filmScripts";

// import GlobalStyle from "./styles/GlobalStyle";

const override = {
  display: "block",
  margin: "0 auto",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    min-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
`;

function App() {
  // const [buttonPopup, setButtonPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <PulseLoader
          color={`#45FE47`}
          loading={loading}
          cssOverride={override}
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Router>
          <GlobalStyle />
          {/* Universe background */}
          <ScrollToTop />
          <Mychatbot />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={AboutPage} />
            <Route path="/templates">
              <Webapps2 />
            </Route>
            <Route path="/recapai">
              <RecapAILearnMore />
            </Route>
            <Route path="/memorypal">
              <MemoryPalAILearnMore />
            </Route>
            <Route path="/scripts">
              <FilmScripts />
            </Route>
            <Route path="/futurestore">
              <ImmersiveStore />
            </Route>
            <Route path="/mint">
              <VoiceappsPage />
            </Route>
            <Route path="/archetypeapi-privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/magician/:slug/:status" component={Magician} />
            <Route path="/magician/:slug" component={Magician} />
            <Route path="/lover/:slug/:status" component={Lover} />
            <Route path="/lover/:slug/" component={Lover} />
            <Route path="/joker/:slug/:status" component={Joker} />
            <Route path="/joker/:slug/" component={Joker} />
            <Route path="/everyman/:slug/:status" component={Everyman} />
            <Route path="/everyman/:slug/" component={Everyman} />
            <Route path="/hero/:slug/:status" component={Hero} />
            <Route path="/hero/:slug/" component={Hero} />
            <Route path="/rebel/:slug/:status" component={Rebel} />
            <Route path="/rebel/:slug/" component={Rebel} />
            {/* 6 */}
            <Route path="/caregiver/:slug/:status" component={Caregiver} />
            <Route path="/caregiver/:slug/" component={Caregiver} />
            <Route path="/creator/:slug/:status" component={Creator} />
            <Route path="/creator/:slug/" component={Creator} />
            <Route path="/explorer/:slug/:status" component={Explorer} />
            <Route path="/explorer/:slug/" component={Explorer} />
            <Route path="/sage/:slug/:status" component={Sage} />
            <Route path="/sage/:slug/" component={Sage} />
            <Route path="/ruler/:slug/:status" component={Ruler} />
            <Route path="/ruler/:slug/" component={Ruler} />
            <Route path="/innocent/:slug/:status" component={Innocent} />
            <Route path="/innocent/:slug/" component={Innocent} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
