import React from "react";
import styled from "styled-components";
import { BsEye } from "react-icons/bs";
import { GiNoseSide, GiSoundWaves } from "react-icons/gi";
import {
  InfoContainer,
  InfoWrapper,
  Column1,
  Column2,
  TopLine,
  TopLine2,
  Subtitle,
  InfoRow,
  CenterTitle,
} from "./ArtistSectionElementsMission";
import Fade from "react-reveal/Fade";

const ArtistSectionMission = ({
  lightBg,
  id,
  imgStart,
  topLine,
  topLine2,
  lightText,
  headline,
  darkText,

  one,
  two,
  three,
  four,
  five,
  six,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          {/* <Fade duration={2000} right>
            <CenterTitle>
              <TopLine2>{topLine2}</TopLine2>
            </CenterTitle>
          </Fade> */}
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TopLine>{one}</TopLine>
              <Fade duration={1400} left>
                <Subtitle darkText={darkText}>{description1}</Subtitle>
              </Fade>
              <TopLine>{two}</TopLine>
              <Fade duration={1800} left>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
              </Fade>
              <TopLine>{three}</TopLine>
              <Fade duration={2000} left>
                <Subtitle darkText={darkText}>{description3}</Subtitle>
              </Fade>
            </Column1>
            <Column2>
              <TopLine>{four}</TopLine>
              <Fade duration={1400} right>
                <Subtitle darkText={darkText}>{description4}</Subtitle>
              </Fade>
              <TopLine>{five}</TopLine>
              <Fade duration={1800} right>
                <Subtitle darkText={darkText}>{description5}</Subtitle>
              </Fade>
              <TopLine>{six}</TopLine>
              <Fade duration={2000} right>
                <Subtitle darkText={darkText}>{description6}</Subtitle>
              </Fade>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
  // console.log(<Img />);
};

export default ArtistSectionMission;
