import React, { useState } from "react";
import { TiArrowBack } from "react-icons/ti";
import { TiChevronLeft } from "react-icons/ti";
import { FaExchangeAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Link as LinkR } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft, FaAngleDoubleLeft, FaUndo } from "react-icons/fa";

import styled from "styled-components";

function SecondaryNav() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo>
            <Link to="/">
              <div onMouseEnter={onHover} onMouseLeave={onHover}>
                {hover ? (
                  <FaAngleDoubleLeft size={28} color="#44FF46" />
                ) : (
                  <FaAngleLeft size={28} color="#45fe47" />
                )}
              </div>
            </Link>
            {/* <Logo style={{ color: "#000" }} size={42} className="logo" /> */}
          </NavLogo>
          <CenterText>AI Chatbots & Templates</CenterText>
          <NavBtn>
            <NavBtnLink
              to="//calendly.com/studio-voice2fly/30min"
              target="_blank"
            >
              {/* START A BUSINESS */}
              {/* Start a Business */}
              Let's Talk!
              {/* <HiCursorClick /> */}
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default SecondaryNav;

const CenterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    /* background-color: #45fe47; */
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`;

const Builder = styled.div`
  max-width: auto;
  height: auto;
  background-color: transparent;
  text-align: center;
  align-items: center;
`;

const HeaderTitle = styled.div`
  /* color: #44ff46; */
  color: #000000;
  text-align: center;
  flex-direction: column;
  padding: 0em 2em 0em 2em;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Orbitron", sans-serif;
  font-weight: 800;
  letter-spacing: 0.2em;
  @media screen and (max-width: 960px) {
    /* font-size: 0.6rem;
    padding: 3em 1em 0em 1em; */
  }
`;

const HeaderSubTitle = styled.div`
  /* color: #44ff46; */
  color: #000000;
  text-align: center;
  flex-direction: column;
  padding: 0em 0em 0em 0em;
  font-size: 0.4rem;
  text-transform: uppercase;
  /* font-family: "Orbitron", sans-serif; */
  /* font-weight: 800; */
  letter-spacing: 0.2em;

  /* font-weight: 800; */
  /* letter-spacing: 0.2em; */
  @media screen and (max-width: 960px) {
    /* font-size: 0.3rem;
    white-space: nowrap;
    overflow: none; */
  }
`;

const Nav = styled.nav`
  /* background: #000; */
  /* background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")}; */
  background: #010606;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

const NavLogo = styled.div`
  color: #45fe47;
  /* color: #fff; */
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  /* margin-left: 24px; */
  font-weight: bold;
  text-decoration: none;
  @media screen and (max-width: 960px) {
    margin-left: 10px;
  }
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    margin-right: 10px;
  }
`;

const NavBtnLink = styled(LinkR)`
  border-radius: 44px;
  background: #45fe47;
  /* background: #ffffff; */
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #000;
  }
`;
