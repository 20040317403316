import React from "react";
import styled from "styled-components";
import CoolLamp from "../assets/CoolLamp.jpg";
import futureSleep from "../assets/futureSleep.jpg";
import HomeEntertainment from "../assets/HomeEntertainment.jpg";
import HallwayArtDecoMark from "../assets/HallwayArtDecoMark.jpg";
import FloatingPlant from "../assets/floating-plant.jpg";
import JustAnotherMonday from "../assets/JustAnotherMonday.png";
import infiniteObjects from "../assets/infiniteObjects.jpg";
import FutureRoom from "../assets/future-room.jpg";
import Terrarium from "../assets/Terrarium.jpg";
import FourKidsPlayingARSageMain from "../assets/FourKidsPlayingARSageMain.png";
import CharacterFusionConcept from "../assets/CharacterFusionConcept.png";
import BoyCarebearLoverGirlMagcician from "../assets/BoyCarebearLoverGirlMagcician.png";
import Jungian12DisplayAllMain from "../assets/Jungian12DisplayAllMain.png";
import RoomSnakeDragonARRebel from "../assets/RoomSnakeDragonARRebel.png";
import TwelvePlanetBattle from "../assets/TwelvePlanetBattle.png";
import TwelveJungianDisorganized2 from "../assets/TwelveJungianDisorganized2.png";
import TwelveNoBubble from "../assets/TwelveNoBubble.png";
//
import shieldProjectionGlassesSolo from "../assets/shieldProjectionGlassesSolo2.png";
import PostedCarsGroupBG from "../assets/PostedCarsGroupBG.png";
//
import ArchetypeArena4Gamers from "../assets/ArchetypeArena4Gamers.png";
import ARPortalIMG from "../assets/ARPortalIMG.png";
import PinkDragon from "../assets/PinkDragon.png";
import new12jungian from "../assets/new12jungian.gif";
import carl12archetypesShadow from "../assets/carl12archetypesShadow.png";
import FourKidsPlayingARSageMainCrowdBG from "../assets/FourKidsPlayingARSageMainCrowdBG.png";
import SilverEverymanBG from "../assets/SilverEverymanBG.png";
import SilverEverymanSolo from "../assets/SilverEverymanSolo.png";

//
import { imageZoomEffect, TitleStyles } from "./ReusableStyles";
import { Link as LinkR } from "react-router-dom";
import ClearCards from "../ClearCardsFolder/ClearCards";
// import { BackgroundImage } from "../../pages/voiceapps";
import { Fade } from "react-reveal";
//
import { BsEye } from "react-icons/bs";
import { GiNoseSide, GiSoundWaves } from "react-icons/gi";
import ImmersiveStoreNavbarScrollDis from "./ImmersiveStoreNavbarScrollDis";
import { HomeButton } from "../../pages/MemoryPalAILearnMore";
import BackgroundImage from "../../images/VoicePageBG.png";

export default function Products() {
  return (
    <>
      {/* <BackgroundWrapper /> */}
      <Container>
        {/* <ImmersiveStoreNavbarScrollDis /> */}
        <HomeButton to="/">Home</HomeButton>
        <Section id="products">
          <CradsStyle>
            <CenterText>The Future Store</CenterText>
            <ClearCards />
          </CradsStyle>
          <CradsStyle2>
            <Fade bottom duration={1400}>
              <ImageSecondary2 src={Jungian12DisplayAllMain} />
              {/* <ImageSecondary src={RoomSnakeDragonARRebel} /> */}
              {/* <ImageSecondary2 src={TwelveJungianDisorganized2} /> */}
              <ImageSecondary2 src={carl12archetypesShadow} />
              <ImageSecondary src={ArchetypeArena4Gamers} />
              <ImageSecondary src={BoyCarebearLoverGirlMagcician} />
              <ImageSecondary src={FourKidsPlayingARSageMainCrowdBG} />
              <ImageSecondary src={TwelvePlanetBattle} />
              <ImageSecondary2 src={new12jungian} />
              <ImageSecondary src={PinkDragon} />
              <ImageSecondary src={shieldProjectionGlassesSolo} />
              <ImageSecondary src={PostedCarsGroupBG} />
              <ImageSecondary src={SilverEverymanSolo} />
              {/* <ImageSecondary src={TwelveJungianDisorganized2} /> */}
              {/* <ImageSecondary src={TwelveNoBubble} /> */}
              {/* <ImageSecondary src={ARPortalIMG} /> */}
              {/* <ClearCards /> */}
              {/* <ImageSecondary src={Jungian12DisplayAllMain} /> */}
            </Fade>
          </CradsStyle2>
          {/* <CradsStyle>
            <ImageSecondary src={shieldProjectionGlasses} />
          </CradsStyle> */}
        </Section>
      </Container>
    </>
  );
}

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const CenterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
  margin: 0px 0px 50px 0px;
  /*
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #45fe47;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  } */
  @media screen and (max-width: 768px) {
    margin: 50px 0px 50px 0px;
  }
`;

const Content = styled.div`
  text-align: center;
  background-color: #45fe47;
  color: #000000;
  height: 280px;
  width: 300px;
  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    height: 180px;
    margin: 1rem 3rem 1rem 3rem;
    width: auto;
  }
  @media screen and (max-width: 768px) {
    height: 200px;
    margin: 1rem 3rem 1rem 3rem;
  }
  @media screen and (max-width: 480px) {
    height: 260px;
    margin: 1rem 3rem 1rem 3rem;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: transparent;
//   /* padding-top: 100px; */
//   /* background-color: #010606; */
//   @media screen and (max-width: 1080px) {
//     display: flex;
//     flex-direction: column;
//   }
// `;

const Img = styled.div`
  padding: 1rem 0rem 1rem 0rem;
`;

const ImageSecondary = styled.img`
  width: 100%;
  height: auto;
`;

const ImageSecondary2 = styled.img`
  width: 100%;
  height: auto;
  padding: 0px 20px 0px 20px;
`;

const CradsStyle2 = styled.div`
  margin: 0px 200px 0px 200px;
  @media screen and (max-width: 992px) {
    margin: 0px 100px 0px 100px;
  }
  @media screen and (max-width: 768px) {
    margin: 0px 50px 0px 50px;
  }
  @media screen and (max-width: 480px) {
    margin: 0px 0px 0px 0px;
  }
`;

const CradsStyle = styled.div`
  padding: 100px 200px 0px 200px;
  @media screen and (max-width: 992px) {
    padding: 50px 100px 0px 100px;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 40px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    padding: 50px 20px 0px 20px;
  }
`;

const Section = styled.section`
  ${TitleStyles};
  .products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-top: 1rem;
    .product {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      h3 {
        color: #44ff46;
      }

      p {
        text-align: center;
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: 0.1rem;
        color: #ffffff;
      }
      ${imageZoomEffect};
      .image {
        max-height: 20rem;
        overflow: hidden;
        border-radius: 0.4rem;
        img {
          height: 20rem;
          width: 18rem;
          object-fit: cover;
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .products {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const DescriptionStyle = styled.p`
  width: 150px;
`;

const PButton = styled(LinkR)`
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: black;
  border-radius: 4rem;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  /* background: linear-gradient(to right, #fc4958, #e85d04); */
  background: #44ff46;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    /* background: linear-gradient(to right, #44ff46, #ffffff); */
    color: #000000;
    background: #ffffff;
  }
`;
