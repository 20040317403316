import styled from "styled-components";
import Image from "./Data";

export const InfoContainer = styled.div`
  color: #fff;
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  background: #44ff46;

  @media screen and (max-width: 768px) {
    padding: 80px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  /* height: 100vh; */
  height: 670px;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

// export const Column1 = styled.div`
//   margin-bottom: 15px;
//   padding: 0 15px;
//   grid-area: col1;
//   @media screen and (max-width: 768px) {
//     /* padding: 0 30px; */
//     /* padding: 100%; */
//     padding: 0 35px;
//   }
//   @media screen and (max-width: 450px) {
//     /* padding: auto; */
//   }
// `;

// export const Column2 = styled.div`
//   margin-bottom: 15px;
//   padding: 0 15px;
//   grid-area: col2;
//   @media screen and (max-width: 768px) {
//     margin-top: -200px;
//   }
//   @media screen and (max-width: 450px) {
//     margin-top: -250px;
//   }
// `;

export const Column1 = styled.div`
  /* margin-bottom: 15px; */
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  /* margin-bottom: 15px; */
  padding: 0 15px;
  grid-area: col2;
  @media screen and (max-width: 768px) {
    /* margin-top: -275px; */
    /* margin-left: 60px; */
  }
  /* @media screen and (max-width: 450px) {
    margin-left: 30px;
  } */
  @media screen and (max-width: 414px) {
    /* margin-top: -340px; */
    /* margin-left: 30px; */
    margin-left: 0px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
`;

export const TopLine = styled.p`
  /* color: #44ff46; */
  color: #000000;
  /* font-size: 16px;
  line-height: 16px;
  font-weight: 700; */
  font-size: 28px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 455px;
  @media screen and (max-width: 480px) {
    /* height: auto; */
    /* margin-top: 150px; */
    /* margin-top: 40px; */
  }
  @media screen and (max-width: 768px) {
    /* margin-top: 250px; */
    /* height: auto; */
    /* margin-top: 80px; */
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
