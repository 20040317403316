import React from "react";
import styled from "styled-components";
import FamWalkintToHorizon from "../images/videos/pexels-pavel-danilyuk-4812264-3840x2160-30fps.mp4";
import FamilyStoryteller from "../images/FamilyStoryteller.jpg";
import Sunsetkidsplaying from "../images/sunsetkidsplaying.jpg";
import SunsetWalk from "../images/videos/pexels-pavel-danilyuk-4812264-3840x2160-30fps.mp4";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import FundingBarSection from "../components/FundingBar";

export const HomeButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 50px;

  background: #43fe47;
  color: #333;
  text-decoration: none;
  border: none;
  border: 0.8px solid #000000;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
  // media queries
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #000000;
    border: 0.8px solid #43fe47;
    color: #43fe47;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  /* border-radius: 10px 10px 10px 10px; */
  margin: 20px 0px 0px 0px;
  @media (max-width: 600px) {
    border-radius: 0px;
  }
`;

const Page = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  background-color: rgba(255, 255, 255);
  @media (max-width: 600px) {
    padding: 20px;
    padding: 40px 0px;
  }
`;

const Section = styled.div`
  margin-top: 0px;
`;

const Header = styled.h1`
  padding: 20px 20px 0px 20px;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-top: 50px;
  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

const SubText = styled.h3`
  padding: 20px 20px 0px 20px;
  font-size: 26px;
  font-weight: bold;
  color: #333;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const SubHeader = styled.h4`
  padding: 20px 20px 0px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;

  @media (max-width: 600px) {
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  padding: 10px 20px;
  a {
    /* background-color: #000; */
    color: #333;
    /* color: #43fe47; */
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50px;
  }
  a:hover {
    color: #43fe47;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  /* border-radius: 10px 10px 10px 10px; */
  margin-top: 20px;
  /* margin-bottom: -20px; */
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
  }
  @media (max-width: 600px) {
    /* margin-bottom: -45px; */
  }
`;
const SecondButton = styled.a`
  width: 80%;
  border-radius: 30px 30px ;
  /* font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")}; */
  background: ${({ primary }) => (primary ? "#44FD47" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: 0.8px solid #000000;
  &:hover {
    color: #000;
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#44FD47")};
  }
  @media (max-width: 450px) {
  font-size: 12px;
  }
`;

const CenterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add other styles as needed */
`;

const FundingGoal = styled.div`
  margin: 30px 0;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0px;
`;

const Progress = styled.div`
  height: 20px;
  background: #ddd;
  border-radius: 10px;
  margin: 20px 20px;
  &::after {
    content: "";
    display: block;
    height: 100%;
    background: #20a4f3;
    border-radius: 10px;
    width: ${(props) => props.progress}%;
  }
`;

const FundingSection = ({}) => (
  <>
    <SubText>Target Audience:</SubText>
    <Description>
      MemoryPal is targeted at families interested in preserving memories and
      family history.
    </Description>
    <SubText>Problem Statement:</SubText>
    <Description>
      MemoryPal aims to address the problem of families losing stories and
      memories over generations as elders pass on.{" "}
    </Description>
    <Description>
      It makes family history preservation easier compared to traditional
      methods of compiling photos, documents, etc.
    </Description>
    <SubText>Key Differentiators:</SubText>
    <Description>
      <Description>
        <ul>
          <li>
            Use of AI/chatbots to create interactive conversations with family
            members' personas. This provides engaging user experience.
          </li>
          <li>
            Centralized platform for families to collectively store memories and
            access generational knowledge.
          </li>
          <li>
            Ability to create keepsakes like photo books from the preserved
            memories.
          </li>
        </ul>
      </Description>
    </Description>
    {/* And so on for each section */}
    <SubText>Company Mission:</SubText>
    <Description>
      To become the leading platform for families to preserve and treasure their
      memories and legacy for future generations.
    </Description>
    <SubText> Pre-Seed Funding Objective:</SubText>
    <Description>
      Raise a targeted $75,000 through a dynamic crowdfunding campaign on
      Kickstarter. Incentivize backers with early access and exclusive
      merchandise, driving enthusiastic participation. Utilize the funds to
      kickstart the development of our initial prototype and establish a strong
      platform foundation.
    </Description>
    <SubText> Budget & Fund Allocation:</SubText>
    <Description>
      <Description>
        <ul>
          <li>
            Platform Development - $30,000: Optimize the platform development
            budget by carefully assessing the necessary features and
            functionalities. Streamline the development process to efficiently
            utilize resources.
          </li>
          <li>
            AI Development - $10,000: Continue leveraging existing AI models but
            allocate a reduced budget for AI development. Prioritize key AI
            components essential for the product's initial release.
          </li>
          <li>
            Cloud Hosting - $10,000: Increase the cloud hosting budget to
            accommodate potential usage growth. Ensure a scalable infrastructure
            to support a larger user base without compromising performance.
          </li>
          <li>
            Marketing - $7,500: Streamline marketing efforts to maintain an
            effective baseline for awareness. Focus on targeted strategies and
            cost-effective channels to reach the intended audience efficiently.
          </li>
          <li>
            Operations - $7,500: Increase the operations budget to adequately
            cover organizational needs. Allocate funds for essential operational
            expenses, ensuring smooth day-to-day functioning and growth.
          </li>
          <li>
            Kickstarter Fees - $3,750: Maintain the budget for Kickstarter fees
            at 5% of the total funding goal to cover platform charges.
          </li>
          <li>
            Backer Rewards - $3,000: Adjust the budget for backer rewards to
            still offer attractive incentives while optimizing costs. Offer
            meaningful and appealing rewards to backers within a reduced budget.
          </li>
          <li>
            Contingency Buffer - $3,250: Maintain a reasonable contingency
            buffer to account for unexpected expenses or potential increases in
            other budget categories.
          </li>
        </ul>
      </Description>
    </Description>
    <SubText> Three-Year Vision:</SubText>
    <SubHeader>Achieve Explosive Growth</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Exceed 1 million registered users, engaging a broad audience and
            building a strong community.
          </li>
          <li>
            Garner 100,000 paid subscribers, showcasing the platform's value and
            monetization potential.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>B2C Expansion and Global Reach</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Launch a robust B2C platform, securing partnerships with over 500
            enterprise clients.
          </li>
          <li>
            Expand our presence to 5 countries across North America and Europe,
            establishing a solid international footprint.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>Diversify Revenue Streams</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Develop a suite of ancillary products generating $5 million in
            sales, enhancing our financial sustainability.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>Secure Venture Funding</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Successfully raise $20 million in venture funding, validating our
            vision and fueling our growth trajectory.
          </li>
        </ul>
      </Description>
    </Description>
    <SubText>Year 1 Plan:</SubText>
    <SubHeader>User Acquisition and Product Testing</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Attract 50,000 users through strategic viral and influencer
            marketing, generating early interest.
          </li>
          <li>
            Convert 10,000 users to paid subscriptions, highlighting the
            platform's value proposition.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>Product Development and Funding</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Launch a robust platform targeting individual consumers, reaching
            partnerships with over 500 enterprises.
          </li>
          <li>
            Introduce a unique family scrapbook that taps into a niche market.
          </li>
          <li>
            Raise a substantial $2 million seed round to drive growth and hire
            talent essential for our success.
          </li>
        </ul>
      </Description>
    </Description>
    <SubText>Year 2 Plan:</SubText>
    <SubHeader>Optimization and Scaling</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Optimize user acquisition channels to scale our user base to 250,000
            enhancing our market presence.
          </li>
          <li>
            Increase paid subscriptions to 75,000 by expanding premium features
            and engaging users effectively.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>B2C Integration and Product Diversification</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Expand ancillary products to include prints, mugs, and calendars,
            broadening our revenue streams.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>Fundraising for Development</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Secure a $5 million Series A funding round, bolstering engineering
            and product development efforts.
          </li>
        </ul>
      </Description>
    </Description>
    <SubText> Year 3 Plan:</SubText>
    <SubHeader>Leverage Growth and Diversify Reach</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Achieve the milestone of 1 million registered users through
            referrals and word-of-mouth marketing.
          </li>
          <li>
            Increase paid subscribers to 100,000 by expanding into the European
            market and adapting to diverse consumer needs.
          </li>
        </ul>
      </Description>
    </Description>
    <SubHeader>B2C Growth and Innovation</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Launch an ancestry DNA integration feature for interactive family
            trees, enhancing user engagement and value proposition.
          </li>
        </ul>
      </Description>
    </Description>

    <SubHeader>Fundraising for Global Expansion</SubHeader>
    <Description>
      <Description>
        <ul>
          <li>
            Raise a substantial $15 million Series B funding round to fund
            global expansion and further fuel our growth.
          </li>
        </ul>
      </Description>
    </Description>
    <Video autoPlay loop muted playsInline preload="auto">
      <source src={SunsetWalk} type="video/mp4" />
    </Video>
    {/* <SubText>(YTD) Funding:</SubText> */}
    {/* <Progress progress={0} /> */}
    <FundingBarSection amountRaised={0} goal={75000} />
    <CenterButton>
      <SecondButton href="//memorypalai-beta.web.app/" target="_blank">
        View PWA Demo v0.1.0-alpha
      </SecondButton>
    </CenterButton>
  </>
);

function MemoryPalAILearnMore() {
  return (
    <>
      <Page>
        <HomeButton to="/">Home</HomeButton>
        <Header>MemoryPal - A Roadmap to Preserve Family Legacy with AI</Header>
        <Description>
          In an era of rapid technological change, families face a growing
          challenge - how to effectively preserve their rich histories,
          traditions, and memories before these precious details are lost
          forever. MemoryPal offers an innovative solution.
        </Description>
        <Description>
          By harnessing the power of AI, MemoryPal allows families to easily
          digitize and interact with their memories in engaging new ways. No
          longer do histories need to languish in dusty albums or fade over
          generations.
        </Description>
        <Image src={FamilyStoryteller} />
        {/* <VideoContainer>
          <iframe src="https://www.youtube.com/embed/123" />
        </VideoContainer> */}
        {/* <Video autoPlay loop muted playsInline preload="auto">
          <source src={FamWalkintToHorizon} type="video/mp4" />
        </Video> */}

        <FundingSection />
      </Page>
    </>
  );
}

export default MemoryPalAILearnMore;
