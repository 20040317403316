const data = [
  {
    slug: "start",
    messages: [
      "Hey there! I'm an Explorer, always ready for a new adventure. How about you join me on this journey we call life?",
      // "My goal is to experience a better, more authentic, more fulfilling life...",
      // "Let the adventure begin!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/explorer/characteristics",
      },
      {
        label: "Build a explorer character ",
        link: "/explorer/build",
      },
      {
        label: "Home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "Being an explorer isn't just about exploring the physical world, it's about discovering your own identity.",
      "Also known as the seeker, wanderer, individualist, pilgrim, traveler, discoverer, globetrotter, rover, tourer, surveyor, scout, prospector, adventurer, and pioneer.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/explorer/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a explorer character ",
        link: "/explorer/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a explorer character ",
        link: "/explorer/build",
      },
      { label: "Get Help", link: "/explorer/contact" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/explorer/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Explorer character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "fnb",
    messages: ["Explorer character hospitality under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Explorer character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Explorer character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
