import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import VoicePageBG from "../images/VoicePageBG.png";

import {
  BiSolidLeftArrowSquare,
  BiSolidRightArrowSquare,
} from "react-icons/bi";

import { VscArrowSmallLeft, VscArrowSmallRight } from "react-icons/vsc";

// Wrapper to position arrows relative to this container

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${VoicePageBG});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

// Main container for the carousel, positioned relative to the background
const ParentCarouselWrapper = styled.div`
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
`;

const CarouselWrapper = styled.div`
  position: relative;
  padding: 10px 0;
  border: 1px solid #41ff46;
  border-radius: 10px;
  max-width: 1200px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  color: #ffffff;
  /* background-color: rgba(
    255,
    255,
    255,
    0.8
  );  */
  /* margin: 20px; */
  @media screen and (max-width: 1200px) {
    /* margin-right: 55px;
    margin-left: 55px; */
  }
  @media screen and (max-width: 768px) {
    /* margin-right: 20px;
    margin-left: 20px; */
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

const ScrollArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  &:first-of-type {
    left: 0px;
  }
  &:last-of-type {
    right: 0px;
  }
  @media screen and (max-width: 768px) {
    &:first-of-type {
      /* left: 20px; */
    }
    &:last-of-type {
      /* right: 20px; */
    }
  }
  & svg {
    color: #45fe47; /* Bright green */
  }
`;

const Tweet = styled.div`
  min-width: 300px;
  padding: 0 40px;
  border-radius: 10px;
`;

const TweetsCarousel = () => {
  const scrollContainer = useRef(null);

  useEffect(() => {
    const loadTwitterScript = () => {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);
    };

    if (!window.twttr) {
      loadTwitterScript();
    } else {
      window.twttr.widgets.load();
    }
  }, []);

  const scrollLeft = () =>
    scrollContainer.current.scrollBy({ left: -300, behavior: "smooth" });
  const scrollRight = () =>
    scrollContainer.current.scrollBy({ left: 300, behavior: "smooth" });
  return (
    <>
      <BackgroundWrapper />
      <ParentCarouselWrapper>
        <CarouselWrapper>
          <ScrollArrow onClick={scrollLeft}>
            <div
              style={{
                width: "60px",
                height: "60px",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0 10px 10px 0", // top-left, top-right, bottom-right, bottom-left
                borderTop: "1px solid #41ff46",
                borderBottom: "1px solid #41ff46",
                borderLeft: "none",
                borderRight: "1px solid #41ff46",
              }}
            >
              <VscArrowSmallLeft size={60} />
            </div>
          </ScrollArrow>
          <CarouselContainer ref={scrollContainer}>
            {/* <Tweet>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/I64PbXpiBQ">https://t.co/I64PbXpiBQ</a>{" "}
              </p>
              &mdash; The Archetype Academy (@12jungian){" "}
              <a href="https://twitter.com/12jungian/status/1797332069313569091?ref_src=twsrc%5Etfw">
                June 2, 2024
              </a>
            </blockquote>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/YwsQ952Tub">https://t.co/YwsQ952Tub</a>
              </p>
              &mdash; Jamesrrs (@jrq3rq){" "}
              <a href="https://twitter.com/jrq3rq/status/1796719807360127156?ref_src=twsrc%5Etfw">
                June 1, 2024
              </a>
            </blockquote>
          </Tweet> */}
            {/* <Tweet>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/vViRBxxDVQ">https://t.co/vViRBxxDVQ</a>
              </p>

              <a href="https://twitter.com/jrq3rq/status/1791638597210435907?ref_src=twsrc%5Etfw">
                May 18, 2024
              </a>
            </blockquote>
          </Tweet> */}
            {/* <Tweet>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/vViRBxxDVQ">https://t.co/vViRBxxDVQ</a>
              </p>
              &mdash; Jamesrrs (@jrq3rq){" "}
              <a href="https://twitter.com/jrq3rq/status/1791638597210435907?ref_src=twsrc%5Etfw">
                May 18, 2024
              </a>
            </blockquote>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/OYLc4cRMD3">https://t.co/OYLc4cRMD3</a>
              </p>
              &mdash; The Archetype Academy (@12jungian){" "}
              <a href="https://twitter.com/12jungian/status/1797776932492513301?ref_src=twsrc%5Etfw">
                June 3, 2024
              </a>
            </blockquote>
          </Tweet> */}
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;AI in the hands of artists is like a new hue in their
                  palette: expanding visions, not constricting dreams.&quot;{" "}
                  <a href="https://t.co/CHC20EsOSQ">
                    pic.twitter.com/CHC20EsOSQ
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773153603668058334?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            {/* <Tweet>
            <blockquote class="twitter-tweet">
              <p lang="zxx" dir="ltr">
                <a href="https://t.co/zvxy9CtBFk">pic.twitter.com/zvxy9CtBFk</a>
              </p>
              &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
              <a href="https://twitter.com/studiovoice2fly/status/1626015553184993281?ref_src=twsrc%5Etfw">
                February 16, 2023
              </a>
            </blockquote>{" "}
          </Tweet> */}
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;Every stroke, digital or not, carries the heartbeat of
                  our collective human journey, echoing our stories.&quot;{" "}
                  <a href="https://t.co/XvuXlCMaMv">
                    pic.twitter.com/XvuXlCMaMv
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773154231635104093?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;Embrace AI as a collaborator, but let your soul&#39;s
                  vision be the guide. Fear not the future, for your creativity
                  is the beacon.&quot;{" "}
                  <a href="https://t.co/xVKEX2FmQe">
                    pic.twitter.com/xVKEX2FmQe
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773154327453945912?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>

            <Tweet>
              <blockquote className="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;Artists are the visionaries of our time, sculpting the
                  future with ideas that transcend the canvas, regardless of the
                  tools at hand.&quot;
                </p>
                <a href="https://t.co/lYO716qido">pic.twitter.com/lYO716qido</a>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773154520219922918?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;In the symphony of future art, AI may provide the
                  instruments, but humans will write the melodies.&quot;{" "}
                  <a href="https://t.co/PqilY82tOJ">
                    pic.twitter.com/PqilY82tOJ
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773154111761887735?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;The magic of art lies not in the medium, but in the
                  artist&#39;s touch, transforming the ordinary into the
                  sublime.&quot;{" "}
                  <a href="https://t.co/U8kW0HrwVQ">
                    pic.twitter.com/U8kW0HrwVQ
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773153944790843625?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;Artists, the architects of imagination, will always
                  pioneer the frontiers of creativity, with AI as their
                  compass.&quot;{" "}
                  <a href="https://t.co/WusbDOV52J">
                    pic.twitter.com/WusbDOV52J
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773153787542172113?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;True artistry isn&#39;t wielded; it blossoms from the
                  essence of being uniquely human.&quot;{" "}
                  <a href="https://t.co/JHMZhOeqFy">
                    pic.twitter.com/JHMZhOeqFy
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773153458251530288?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  &quot;AI may map the canvas, but only the human heart can
                  color it with soul.&quot;{" "}
                  <a href="https://t.co/NCMTObPiHN">
                    pic.twitter.com/NCMTObPiHN
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773153068990767346?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="en" dir="ltr">
                  “AI is the brush, creativity is the palette: together, they
                  paint unimaginable futures.”{" "}
                  <a href="https://t.co/LJyLPJh4hN">
                    pic.twitter.com/LJyLPJh4hN
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1773152895740850583?ref_src=twsrc%5Etfw">
                  March 28, 2024
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="zxx" dir="ltr">
                  <a href="https://t.co/4LkOnaWDx0">
                    pic.twitter.com/4LkOnaWDx0
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1618393513305178112?ref_src=twsrc%5Etfw">
                  January 25, 2023
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="zxx" dir="ltr">
                  <a href="https://t.co/WAAn08CqVW">
                    pic.twitter.com/WAAn08CqVW
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1574858149873725440?ref_src=twsrc%5Etfw">
                  September 27, 2022
                </a>
              </blockquote>{" "}
            </Tweet>
            <Tweet>
              <blockquote class="twitter-tweet">
                <p lang="zxx" dir="ltr">
                  <a href="https://t.co/IySDpq6WnS">
                    pic.twitter.com/IySDpq6WnS
                  </a>
                </p>
                &mdash; 🍳 Archetypal Solutions (@studiovoice2fly){" "}
                <a href="https://twitter.com/studiovoice2fly/status/1579629216819482625?ref_src=twsrc%5Etfw">
                  October 11, 2022
                </a>
              </blockquote>{" "}
            </Tweet>
          </CarouselContainer>
          <ScrollArrow onClick={scrollRight}>
            <div
              style={{
                width: "60px",
                height: "60px",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px 0 0 10px", // top-left, top-right, bottom-right, bottom-left
                borderTop: "1px solid #41ff46",
                borderBottom: "1px solid #41ff46",
                borderLeft: "1px solid #41ff46",
                borderRight: "none", // No border on the right
              }}
            >
              <VscArrowSmallRight size={60} />
            </div>
          </ScrollArrow>
        </CarouselWrapper>
      </ParentCarouselWrapper>
    </>
  );
};

export default TweetsCarousel;

// Make sure to include Twitter's widget JS in your application
// <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
