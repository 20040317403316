import styled from "styled-components";

export const Spacer = styled.div`
  height: 90px;
  width: auto;
  background: #000;
`;

export const Spacer2 = styled.div`
  height: 3rem;
  width: auto;
  /* background: #000; */
`;
