import React from "react";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as GiIcons from "react-icons/gi";
import * as ImIcons from "react-icons/im";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import MagicianStone from "./images/MagicianStone.png";
import RebelStone from "./images/RebelStone.png";
import InnocentStone from "./images/InnocentStone.png";
import SageStone from "./images/SageStone.png";
import LoverStone from "./images/LoverStone.png";
import CaregiverStone from "./images/CaregiverStone.png";
import JokerStone from "./images/JokerStone.png";
import ExplorerStone from "./images/ExplorerStone.png";
import HeroStone from "./images/HeroStone.png";
import RulerStone from "./images/RulerStone.png";
import CreatorStone from "./images/CreatorStone.png";
import EverymanStone from "./images/EverymanStone.png";
//
import TwelveSkinnyLogo from "./images/TwelveSkinnyLogo.png";
//
import HexagonBG from "./images/HexagonBG.png";
import OctagonBG from "./images/OctagonBG.png";
import DiamondBG from "./images/DiamondBG.png";
import TeardropBG from "./images/TeardropBG.png";
//
import styled from "styled-components";
import ScrollToTop from "../ScrollToTop";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import Characters from "./Characters";
import { FadeIn } from "../../pages/voiceapps";

const MainContainer = styled(LinkS)`
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 50px)) 1fr;
  grid-gap: 30px;
  /* padding: 40px 0px; */
  background: transparent;
  /* background: #000; */
  margin-bottom: 30px;
  @media screen and (max-width: 700px) {
    grid-gap: 20px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 10px repeat(6, 1fr) 10px;
    grid-gap: 10px;
  }
`;

const CardsSection = styled.div`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: 40px;
  @media screen and (max-width: 500px) {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
  }
`;

const Card1 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  /* background-color: #f18229; */
  background-color: rgba(241, 130, 41, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #f18229;
  border-style: solid;
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${HexagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card2 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(241, 172, 42, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #f1ac2a;
  border-style: solid;
  /* background-color: #f1ac2a; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${HexagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card3 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(25, 116, 73, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #197449;
  border-style: solid;
  /* background-color: #197449; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${TeardropBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card4 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(53, 178, 118, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #35b276;
  border-style: solid;
  /* background-color: #35b276; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${TeardropBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card5 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(159, 34, 143, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #9f228f;
  border-style: solid;
  /* background-color: #9f228f; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${DiamondBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card6 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(2, 167, 223, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #00a6df;
  border-style: solid;
  /* background-color: #372c94; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${OctagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card7 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(237, 47, 63, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #ed2f3f;
  border-style: solid;
  /* background-color: #ed2f3f; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${DiamondBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card8 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(168, 204, 103, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #a8cc67;
  border-style: solid;
  /* background-color: #a8cc67; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${TeardropBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card9 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(254, 233, 2, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #fee902;
  border-style: solid;
  /* background-color: #fee902; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${HexagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card10 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(5, 116, 189, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #0474bc;
  border-style: solid;
  /* background-color: #0574bd; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${OctagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card11 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(55, 44, 148, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #372c94;
  border-style: solid;
  /* background-color: #fee902; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${OctagonBG});
  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const Card12 = styled(LinkR)`
  text-decoration: none;
  min-width: 250px;
  border-radius: 4px;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: rgba(231, 13, 126, 0.5);
  border-width: 2px 2px 2px 2px;
  border-color: #e70d7e;
  border-style: solid;
  /* background-color: #e70d7e; */
  border-radius: 10px;
  /* background: rgb(2, 0, 36);
  background: linear-gradient(#e66465, #9198e5); */
  background-size: cover;
  background-image: url(${DiamondBG});

  /* cursor: pointer; */
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
  @media screen and (max-width: 1000px) {
    grid-column-end: span 6;
  }

  @media screen and (max-width: 700px) {
    grid-column-end: span 12;
  }
  @media screen and (max-width: 500px) {
    grid-column-end: span 6;
  }
`;

const CardPerimeterLineCard1 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #f18229;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard2 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #f1ac2a;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard9 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #fee902;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;
// HexagonBG

const CardPerimeterLineCard4 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #35b276;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard3 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #197449;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard8 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #a8cc67;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

// TeardropBG

const CardPerimeterLineCard10 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #0574bd;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard6 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #02a7df;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard11 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #372c94;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;
// OctagonBG

const CardPerimeterLineCard7 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #ed2f3f;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard5 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #9f228f;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const CardPerimeterLineCard12 = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  ); */
  background-color: #e70d7e;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;
// DiamondBG

const CardInfo = styled.div`
  display: inline-block;
  align-items: center;
  text-align: center;
  color: #fff;
  /* max-height: 100px; */
  /* letter-spacing: 0.1em; */
  /* background-color: #282c34; */
  /* border-radius: 10px; */
  /* padding: 1rem 0rem 1rem 0rem; */
  /* width: 240px; */
  margin-bottom: 1.5rem;
  /* padding: 1rem 0.5rem 0.5rem 0.5rem; */
  /* padding: 1rem; */
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    border-left: 1px solid #e6ecf8;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  @media screen and (max-width: 700px) {
    text-align: center;
    /* margin-top: -30px; */
  }
`;
const ServicesIcon = styled.img`
  margin: 1rem 0rem 0rem 0rem;
  height: 150px;
  width: 150px;
  /* margin-bottom: 10px; */
`;
const CardContent = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 20px;
`;
const PricingCardInfo = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  /* margin-top: -20px; */
  flex-direction: column;
  height: auto;
  padding: 0rem;
  color: #fff;
`;

const CardPerimeterLine = styled.div`
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 10px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37); */
  /* animation: fade-in 1s ease-out forwards; */
`;

const Mission = styled.div`
  padding: 1rem;
`;

const MissionRebel = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #f18229;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #f18229;
  border-style: solid;
`;
const MissionMagician = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #f1ac2a;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #f1ac2a;
  border-style: solid;
`;
const MissionHero = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #fee900;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #fee900;
  border-style: solid;
`;
//
const MissionCreator = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #372b93;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #372b93;
  border-style: solid;
`;
const MissionRuler = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #0474bc;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #0474bc;
  border-style: solid;
`;
const MissionCaregiver = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #00a6df;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #00a6df;
  border-style: solid;
`;
//
const MissionInnocent = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #1a7349;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #1a7349;
  border-style: solid;
`;
const MissionSage = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #35b276;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #35b276;
  border-style: solid;
`;
const MissionExplorer = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #a8cc67;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #a8cc67;
  border-style: solid;
`;
//
const MissionLover = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #9f228f;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #9f228f;
  border-style: solid;
`;
const MissionJoker = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #ed2f3e;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #ed2f3e;
  border-style: solid;
`;
const MissionEveryman = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center; /* Vertically center items */
  text-align: center;
  padding: 1rem;
  color: #ffffff;
  background-color: #e70d7e;
  border-radius: 8px 8px 0px 0px;
  border-width: 0px 0px 0px 0px;
  border-color: #e70d7e;
  border-style: solid;
`;

export const ImageSecondary = styled.img`
  width: 100%;
  height: auto;
`;

const JungianCards = () => {
  return (
    <>
      <FadeIn style={{ animationDelay: "1s" }}>
        <MainContainer id="jungian-cards">
          <CardsSection>
            <Card1 to="/rebel/start">
              <MissionRebel>Follow your own rules.</MissionRebel>{" "}
              {/* <EditionTag>Magician</EditionTag> */}
              <Container>
                <ServicesIcon src={RebelStone} />
              </Container>
              <CardPerimeterLineCard1>
                <CardContent>
                  <Characters.RebelJSON />
                </CardContent>
              </CardPerimeterLineCard1>
            </Card1>
            <Card2 to="/magician/start">
              {/* <EditionTag>Rebel</EditionTag> */}
              <MissionMagician>Make dreams a reality.</MissionMagician>
              <Container>
                <ServicesIcon src={MagicianStone} />
              </Container>
              <CardPerimeterLineCard2>
                <CardContent>
                  <Characters.MagicianJSON />
                </CardContent>
              </CardPerimeterLineCard2>
            </Card2>
            <Card9 to="/hero/start">
              {/* <EditionTag>Hero</EditionTag> */}
              <MissionHero>Prove yourself.</MissionHero>
              <Container>
                <ServicesIcon src={HeroStone} />
              </Container>

              <CardPerimeterLineCard9>
                <CardContent>
                  <Characters.HeroJSON />
                </CardContent>
              </CardPerimeterLineCard9>
            </Card9>

            <Card11 to="/creator/start">
              {/* <EditionTag>Creator</EditionTag> */}
              <MissionCreator>Create something new.</MissionCreator>
              <Container>
                <ServicesIcon src={CreatorStone} />
              </Container>
              <CardPerimeterLineCard11>
                <CardContent>
                  <Characters.CreatorJSON />
                </CardContent>
              </CardPerimeterLineCard11>
            </Card11>
            <Card10 to="/ruler/start">
              {/* <EditionTag>Ruler</EditionTag> */}
              <MissionRuler>Lead with strength and vision.</MissionRuler>
              <Container>
                <ServicesIcon src={RulerStone} />
              </Container>
              <CardPerimeterLineCard10>
                <CardContent>
                  <Characters.RuleJSON />
                </CardContent>
              </CardPerimeterLineCard10>
            </Card10>
            <Card6 to="/caregiver/start">
              {/* <EditionTag>Caregiver</EditionTag> */}
              <MissionCaregiver>Care and protect others.</MissionCaregiver>
              <Container>
                <ServicesIcon src={CaregiverStone} />
              </Container>
              <CardPerimeterLineCard6>
                <CardContent>
                  <Characters.CaregiverJSON />
                </CardContent>
              </CardPerimeterLineCard6>
            </Card6>

            <Card3 to="/innocent/start">
              {/* <EditionTag>Innocent</EditionTag> */}
              <MissionInnocent>Be happy.</MissionInnocent>
              <Container>
                <ServicesIcon src={InnocentStone} />
              </Container>
              <CardPerimeterLineCard3>
                <CardContent>
                  <Characters.InnocentJSON />
                </CardContent>
              </CardPerimeterLineCard3>
            </Card3>
            <Card4 to="/sage/start">
              {/* <EditionTag>Sage</EditionTag> */}
              <MissionSage>Gain wisdom and insight.</MissionSage>
              <Container>
                <ServicesIcon src={SageStone} />
              </Container>
              <CardPerimeterLineCard4>
                <CardContent>
                  <Characters.SageJSON />
                </CardContent>
              </CardPerimeterLineCard4>
            </Card4>
            <Card8 to="/explorer/start">
              {/* <EditionTag>Explorer</EditionTag> */}
              <MissionExplorer>Break free.</MissionExplorer>
              <Container>
                <ServicesIcon src={ExplorerStone} />
              </Container>
              <CardPerimeterLineCard8>
                <CardContent>
                  <Characters.ExplorerJSON />
                </CardContent>
              </CardPerimeterLineCard8>
            </Card8>
            <Card5 to="/lover/start">
              {/* <EditionTag>Lover</EditionTag> */}
              <MissionLover>Indulge your senses.</MissionLover>
              <Container>
                <ServicesIcon src={LoverStone} />
              </Container>
              <CardPerimeterLineCard5>
                <CardContent>
                  <Characters.LoverJSON />
                </CardContent>
              </CardPerimeterLineCard5>
            </Card5>
            <Card7 to="/joker/start">
              {/* <EditionTag>Joker</EditionTag> */}
              <MissionJoker>Bring joy.</MissionJoker>
              <Container>
                <ServicesIcon src={JokerStone} />
              </Container>
              <CardPerimeterLineCard7>
                <CardContent>
                  <Characters.JokerJSON />
                </CardContent>
              </CardPerimeterLineCard7>
            </Card7>
            <Card12 to="/everyman/start">
              {/* <EditionTag>Everyman</EditionTag> */}
              <MissionEveryman>Belong.</MissionEveryman>
              <Container>
                <ServicesIcon src={EverymanStone} />
              </Container>
              <CardPerimeterLineCard12>
                <CardContent>
                  <Characters.EverymanJSON />
                </CardContent>
              </CardPerimeterLineCard12>
            </Card12>
          </CardsSection>
        </MainContainer>
      </FadeIn>
    </>
  );
};

export default JungianCards;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
`;
const Item = styled.div``;
