import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import MatrixScreenCopy from "../assets/matrixScreenCopy.png";
import TheArchetypeBrandTransformer from "../assets/TheArchetypeBrandTransformer.png";
import NewStorySpinnerLogo from "../assets/newStorySpinnerLogo.png";
import landscapeImg from "../assets/landscapeImg.jpg";
import cleaningHomecare from "../assets/cleaningHomecare.jpg";
import electricianHandyman from "../assets/electricianHandyman.jpg";
import mechanicRepairman from "../assets/mechanicRepairman.jpg";
import authorWriter from "../assets/authorWriter.jpg";
import artistCreators from "../assets/artistCreators.jpg";
import smallshopMarketplace from "../assets/smallshopMarketplace.jpg";
import showcaseRealEstate from "../assets/showcaseRealEstate.jpg";
import TermsofserviceMagnizying from "../assets/termsofserviceMagnizying.jpg";
import StrategicBusinessPlanner from "../assets/StrategicBusinessPlanner.png";
import RebelAdvisorIMG from "../assets/RebelAdvisorIMG.png";
import BusinessRebrandingTool from "../assets/BusinessRebrandingTool.png";
// import GoogleChromeExtensions from "../assets/googleChromeExtensions.png";
import tutorAI from "../assets/TutorAI.jpg";
import SpecializedAI from "../assets/SpecializedAI.jpg";
import CustomerServiceAI from "../assets/CustomerServiceAI.jpg";
// import FamilyStoryteller from "../assets/FamilyStoryteller.jpg";
import { imageZoomEffect, TitleStyles } from "./ReusableStyles";
// import Services from "../ImmersiveStoreComponents/Services";
// import HeroHeaderContent from "./HeroHeaderContent";

const Section = styled.section`
  /* margin: 8rem 6rem; */
  max-width: 1200px;
  ${TitleStyles};
  .ReactSectionCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-top: 4rem;
    .service {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      margin-bottom: 0rem;
      @media screen and (min-width: 280px) and (max-width: 690px) {
        margin-top: 0rem;
        margin-bottom: 0rem;
      }
      h3 {
        color: #44ff46;
      }
      ${imageZoomEffect};
      .image {
        max-height: 20rem;
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        border: 1px solid #44fd47;
        img {
          height: 20rem;
          width: 300px;
          object-fit: cover;
          filter: brightness(60%);
          @media screen and (min-width: 280px) and (max-width: 690px) {
            /* width: 300px; */
            /* object-fit: cover; */
          }
        }
        p {
          top: 13rem;
          position: absolute;
          width: auto;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 1rem;
          font-weight: 6rem;
          letter-spacing: 0.1rem;
          /* color: #44fd47; */
          color: #ffffff;
          /* color: transparent; */
          padding: 1.5rem;
          font-family: "Orbitron", sans-serif;
        }
      }
      .content {
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 690px) {
    .ReactSectionCards {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  @media screen and (min-width: 690px) and (max-width: 1080px) {
    .ReactSectionCards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const CardInfo = styled.div`
  color: #44fd47;
  font-family: "Orbitron", sans-serif;
  /* color: #fff; */
  width: 80%;
  overflow: auto;
  display: inline-block;
  text-align: center;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  @media screen and (min-width: 280px) and (max-width: 720px) {
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 0rem 1rem 1rem 1rem;
`;

const PricingButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 44px;
  white-space: nowrap;
  margin: 0 auto 9px;
  padding: 10px 20px;
  /* background: #44fd47; */
  font-family: "Orbitron", sans-serif;

  /* background: #ffffff; */
  color: #44ff46;
  /* color: #010606; */
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 0.5rem;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #44ff46;
    /* background: #ff4b4b; */
    color: #010606;
  }
`;

const DownloadPricingButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 44px;
  white-space: nowrap;
  margin: 0 auto 9px;
  padding: 10px 20px;
  color: #44fd47;
  /* color: #fff; */
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: "Orbitron", sans-serif;

  &:hover {
    transition: all 0.2s ease-in-out;
    /* background: #ff4b4b; */
    background: #44ff46;
    color: #010606;
  }
`;

const BannerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
`;

const Banner = styled.div`
  position: absolute;
  top: 18%; /* Adjust as needed */
  right: -20%; /* Adjust as needed */
  transform: translateY(-20%) rotate(45deg); /* Adjust as needed */
  background-color: ${(props) => props.backgroundColor || "#ff4b4b"};
  color: ${(props) => props.textColor || "#fff"};
  padding: 0.5rem; /* Adjust padding as needed */
  font-weight: bold;
  width: 280px;
  @media screen and (max-width: 768px) {
    transform: translateY(-20%) rotate(45deg) scale(0.8); /* Adjust as needed */
    top: 16%; /* Adjust as needed */
    right: -24%; /* Adjust as needed */
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  padding: 2rem 0rem 0rem 0rem;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    width: 350px;
  }
`;

export default function ReactSectionCards() {
  const openLinkInNewWindow = (url) => {
    if (url) {
      const newWindow = window.open(url, "_blank");
      newWindow.document.body.style.backgroundColor = "black";
    }
  };

  const data = [
    {
      image: TheArchetypeBrandTransformer,
      name: " AI-Enhanced Branding and Marketing Solutions",
      // desc: "AI-Enhanced Branding and Marketing Solutions",
      // link: false,
      link: "https://chat.openai.com/g/g-SXTcCxFtV-archetype-brand-transformer",
      hasBanner: true,
      bannerText: "Custom GPT", // New property
    },
    {
      image: NewStorySpinnerLogo,
      name: " Remixing Stories, Inspiring New Creations",
      // desc: "Story Spinner: Remixing Stories, Sparking Joy",
      // link: false,
      link: "https://chat.openai.com/g/g-7T3hhDJO7-storyspinner",
      hasBanner: true,
      bannerText: "Custom GPT", // New property
    },
    {
      image: RebelAdvisorIMG,
      name: " Rebel Advisor",
      // desc: "A critical analyzer of ideas, providing honest feedback to improve business projects.",
      // link: false,
      link: "https://chat.openai.com/g/g-KjtcrDj33-rebel-advisor",
      hasBanner: true,
      bannerText: "Custom GPT", // New property
    },
    {
      image: BusinessRebrandingTool,
      name: " Business Rebranding Tool",
      // desc: " Generates closely related and outside-the-box rebranding ideas.",
      // link: false,
      link: "https://chat.openai.com/g/g-np3Dj7gOE-business-rebranding-tool",
      hasBanner: true,
      bannerText: "Custom GPT", // New property
    },
    {
      image: StrategicBusinessPlanner,
      name: "Strategic Business Partner",
      // desc: "Mastering Business Strategy: Unlocking Potential with SWOT Analysis",
      // link: false,
      link: "https://chat.openai.com/g/g-3aqW0lmMT-strategic-business-partner",
      hasBanner: true,
      bannerText: "Custom GPT", // New property
    },
    {
      image: MatrixScreenCopy,
      name: "Automated Invoice Generator",
      desc: "Streamline Invoice Creation",
      link: false,
      // link: "https://github.com/jrq3rq/automated-invoice-generator",
      hasBanner: true,
      bannerText: "Business Scripts", // New property
    },
    {
      image: MatrixScreenCopy,
      name: "Order Fulfillment Tracker",
      desc: "Track Orders with Ease",
      link: false,
      link2: false,
      hasBanner: true,
      bannerText: "Business Scripts", // New property

      // hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: MatrixScreenCopy,
      name: "Inventory Alert System",
      desc: "Get Proactive Inventory Notifications",
      link: false,
      link2: false,
      hasBanner: true,
      bannerText: "Business Scripts", // New property
      // hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: TermsofserviceMagnizying,
      name: "Recap AI",
      desc: " Making the fine print crystal clear.",
      link: false,
      // link: "https://github.com/jrq3rq/recapai",
      link2: false,
      // link2: "https://github.com/jrq3rq/FamiliAI",
      hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: SpecializedAI,
      name: "Specialized Assistant",
      desc: "Unlocking Potential with Expert AI Guidance.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner

      // hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: smallshopMarketplace,
      name: "AI Chatbots for Small businesses",
      desc: "Drive more traffic and generate more sales.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
      // hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: tutorAI,
      name: "Coaching Assistant",
      desc: "Revolutionize education with AI tech.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
      // hasBanner: true, // Add this property for the card with a banner
    },
    {
      image: CustomerServiceAI,
      name: "Customer Service Assistant",
      desc: "Enhance customer support with 24/7 AI chatbot.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
    },
    {
      image: landscapeImg,
      name: "Landscapers & Lawn care",
      desc: "Promote your products or services.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
      // price: "$22.4/pcs",
    },
    {
      image: cleaningHomecare,
      name: "Professional Cleaning Services",
      desc: "Tell your customers how to contact you.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
      // price: "$5.5/pcs",
    },
    {
      image: electricianHandyman,
      name: "Handymen & Electricians",
      desc: "Provide better service to your customers.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
      // price: "$8/pcs",
    },

    {
      image: mechanicRepairman,
      name: "Automotive Professionals",
      desc: "Make more money and provide better one-on-one service.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
    },
    {
      image: authorWriter,
      name: "Book Authors & Illustrators",
      desc: "Increasing your online influence and credibility",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
    },
    {
      image: artistCreators,
      name: "Visual Artists & Creators",
      desc: "Show you are serious about your art.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
    },
    {
      image: showcaseRealEstate,
      name: "Real Estate Agents",
      desc: "Save more on marketing and look more professional.",
      link: false,
      link2: false,
      hasBanner: false, // Add this property for the card with a banner
    },
  ];
  const [toggledState, setToggledState] = useState(data.map(() => true));

  const toggleState = (index) => {
    const newState = [...toggledState];
    newState[index] = !newState[index];
    setToggledState(newState);
  };

  return (
    <>
      <Section id="ReactSectionCards">
        <div className="ReactSectionCards">
          {/* <HeaderTitle>PWA Business Templates & AI Chatbots</HeaderTitle> */}
          {data.map((service, index) => {
            const isToggled = toggledState[index];

            return (
              <div className="service" key={index}>
                <div className="image">
                  {service.hasBanner ? (
                    <BannerContainer>
                      <img src={service.image} alt="" />
                      <Banner
                        backgroundColor={
                          service.bannerText === "Business Scripts"
                            ? "#44ff46"
                            : service.bannerText === "Custom GPT"
                            ? "#32CD32"
                            : "#ff4b4b" // New color for Custom GPT
                        }
                        textColor={
                          service.bannerText === "Business Scripts"
                            ? "#000000"
                            : service.bannerText === "Custom GPT"
                            ? "#ffffff"
                            : "#ffffff" // Assuming white text for Custom GPT
                        }
                      >
                        {service.bannerText || "Browser Extension"}
                      </Banner>
                    </BannerContainer>
                  ) : (
                    <img src={service.image} alt="" />
                  )}
                  <p>{service.desc}</p>
                </div>
                <CardInfo>{service.name}</CardInfo>
                <ButtonContainer>
                  {service.hasBanner &&
                  service.bannerText === "Custom GPT" &&
                  index < 6 ? (
                    // Render a single button for the first two cards with Custom GPT banners
                    <PricingButton
                      onClick={() => openLinkInNewWindow(service.link)}
                    >
                      Try Out
                    </PricingButton>
                  ) : (
                    // Render the usual two buttons for the rest of the cards
                    <>
                      <PricingButton
                        onClick={() => openLinkInNewWindow(service.link)}
                      >
                        Preview
                      </PricingButton>
                      <DownloadPricingButton
                        onClick={() => openLinkInNewWindow(service.link2)}
                      >
                        Download
                      </DownloadPricingButton>
                    </>
                  )}
                </ButtonContainer>
              </div>
            );
          })}
        </div>
      </Section>
    </>
  );
}
