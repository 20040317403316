import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Videos = () => {
  return (
    <>
      <MainContainer>
        <Content2>
          <Video
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/iosNuIdQoy8"
              frameBorder="0"
            />
          </Video>
        </Content2>
      </MainContainer>
      ;
    </>
  );
};

export default Videos;

const Video = styled.div``;
const Content2 = styled.div`
  width: 100%;
  /* background-color: #44ff46; */
  /* background-color: transparent; */
  /* background-color: #000000; */
  /* background-color: #ffffff; */
  /* background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  ); */

  /* background-color: #f6f6f6; */
  /* color: #000000; */
  /* padding: 1rem 1rem 1rem 1rem; */
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 700px) {
  }
  /* @media screen and (max-width: 500px) {
  } */
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: center;
  justify-content: center;
`;
