import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { HiCursorClick } from "react-icons/hi";
import { GiClick } from "react-icons/gi";
import { TiArrowBack } from "react-icons/ti";
import { TiChevronLeft } from "react-icons/ti";

import {
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaPlus,
  FaUndo,
  FaExchangeAlt,
} from "react-icons/fa";
import { Link as LinkR } from "react-router-dom";
import TwelveSkinnyLogo from "../JungianCards/images/TwelveSkinnyLogo.png";

const ImageSecondary = styled.img`
  width: 100%;
  height: auto;
`;

const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#010606" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
    /* background-color: #010606; */
  }
`;

const CenterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    /* background-color: #45fe47; */
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`;
const NavbarContainer = styled.div`
  display: flex;
  justify-content: center; /* Update this line */
  align-items: center;
  height: auto;
  z-index: 1;
  width: 60%;
  padding: 0 24px;
  /* background-color: pink; */
  /* max-width: 1100px; */
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const NavLogo = styled(LinkR)`
  color: #44ff46;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  /* margin-left: 24px; */
  font-weight: bold;
  text-decoration: none;
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  /* background-color: #010606; */
`;

const NavBtnLink = styled(LinkR)`
  /* font-family: "Orbitron", sans-serif; */
  font-weight: 100;
  border-radius: 44px;
  background: #44ff46;
  transition: 0.5s ease-in-out;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  /* letter-spacing: 3px; */
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    /* background: linear-gradient(to right, #44ff46, #ffffff); */
    color: #000;
  }
`;

const AdditionalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 40px;
  height: 40px; */
  background-color: #44ff46;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
`;
const CenterContent2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 500px) {
    /* flex-direction: column; */
    /* width: 100%; */
  }
`;

const ButtonDemo2 = styled(LinkR)`
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 28px 10px 28px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
  @media (max-width: 500px) {
    /* width: 100%; */
    /* margin-top: 20px; */
  }
`;
const ButtonBreakdown2 = styled(LinkR)`
  height: 100%;
  width: 100%;
  border-radius: 0px 30px 30px 0px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 28px 10px 28px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
  @media (max-width: 500px) {
    /* width: 100%; */
    /* margin-top: 20px; */
  }
`;

export default function VoiceAppsNav() {
  const [hover, setHover] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const onHover = () => {
    setHover((prevHover) => !prevHover);
  };

  const changeNav = () => {
    setScrollNav(window.scrollY >= 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        {/* <NavLogo to="/">
          <div onMouseEnter={onHover} onMouseLeave={onHover}>
            {hover ? (
              <FaAngleDoubleLeft size={28} color="#44FF46" />
            ) : (
              <FaAngleLeft size={28} />
            )}
          </div>
        </NavLogo> */}
        <CenterContent2>
          <ButtonDemo2 to="/">Home</ButtonDemo2>
          <ButtonBreakdown2
            to="//calendly.com/studio-voice2fly/product-brainstorm"
            target="_blank"
          >
            Free brainstorm
          </ButtonBreakdown2>
        </CenterContent2>
        {/* <CenterText>
          <ImageSecondary src={TwelveSkinnyLogo} />
        </CenterText> */}
        {/* <NavBtn>
          <NavBtnLink
            to="//calendly.com/studio-voice2fly/product-brainstorm"
            target="_blank"
          >
            Connect
          </NavBtnLink>
        </NavBtn> */}
      </NavbarContainer>
    </Nav>
  );
}
