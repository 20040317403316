import styled from "styled-components";
import "./Chat.scss";
// import { GiClick } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { GiDiamondHard } from "react-icons/gi";

import { Link as LinkR } from "react-router-dom";

export const LinkTo = styled(LinkR)`
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #dd823f;
  }
`;

const data = [
  {
    slug: "start",
    messages: [
      // "Let's explore this magical world together!"
      "Hello, curious one. I am the Magician, and I am here to unfold the mysteries of the world for you.",
    ],
    answers: [
      {
        label: "Tell me a little bit about yourself",
        link: "/magician/characteristics",
      },
      {
        label: "Create a magician character",
        link: "/magician/build",
      },
      {
        label: <FaHome />,
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "My secret knowledge is exclusively for you, and I take great pride in my ability to dream big. My favourite way to solve problems is through science or magic. I am especially interested in the magic powers often found in fiction and the arts.",
      "Also known as the visionary, sorcerer, sorceress, witch, wizard, warlock, enchanter, enchantress, necromancer, spellcaster, druid, shaman, witch doctor, magus, and alchemist.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/magician/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a magician character",
        link: "/magician/build",
      },
      {
        label: <FaHome />,
        link: "/voiceapps",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a magician character ",
        link: "/magician/build",
      },
      {
        label: "More info ",
        link: "/magician/contact",
      },
      {
        label: <FaHome />,
        link: "/mint",
      },
    ],
  },
  {
    slug: "contact",
    messages: [
      <LinkTo
        to="//calendly.com/studio-voice2fly/free-30min-consulting"
        target="_blank"
      >
        Build New <GiDiamondHard />
      </LinkTo>,

      "Email: Jamesrrs.me@gmail.com ",
    ],
    answers: [
      { label: "Back to main page", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/magician/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Magician character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "contact",
    messages: ["Phone: (971) 249-2717"],
    answers: [
      { label: "Back to main page", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "fnb",
    messages: ["Magician character hospitality under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Magician character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: ["Web3 Magician character representative under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Magician character customer support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
