import React from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import styled from "styled-components";

const Innocent = styled.div`
  height: 18px;
  width: auto;
  background-color: #1a7349;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Sage = styled.div`
  height: 18px;
  width: auto;
  background-color: #35b276;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Explorer = styled.div`
  height: 18px;
  width: auto;
  background-color: #a8cc67;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
//
const Hero = styled.div`
  height: 18px;
  width: auto;
  background-color: #fde900;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Rebel = styled.div`
  height: 18px;
  width: auto;
  background-color: #f1ac2a;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Magician = styled.div`
  height: 18px;
  width: auto;
  background-color: #f08229;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
//
const Everyman = styled.div`
  height: 18px;
  width: auto;
  background-color: #e70d7e;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Joker = styled.div`
  height: 18px;
  width: auto;
  background-color: #ed2f3e;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Lover = styled.div`
  height: 18px;
  width: auto;
  background-color: #9f228f;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
//
const Creator = styled.div`
  height: 18px;
  width: auto;
  background-color: #372b93;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Ruler = styled.div`
  height: 18px;
  width: auto;
  background-color: #0474bc;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;
const Caregiver = styled.div`
  height: 18px;
  width: auto;
  background-color: #00a6df;
  @media screen and (max-width: 768px) {
    /* height: 10px; */
  }
`;

const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: auto;
  height: 18px;
  background-color: #000000;
  /* background-color: #f9f9f9; */
  /* height: 150px; */
  /* color: #fff; */
`;

const CarouselBackground = styled.div`
  /* background: #44ff46; */
  /* background: #3ddf3e; */
  /* background-color: transparent; */
`;

const BlocksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
`;

const Blocks = styled.div`
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  width: 33.33%;
  text-align: center;
  /* background-color: #000000; */
`;

const TwelveJungianCarouselLeft = () => {
  const CarouselUI = ({ children }) => <Container>{children}</Container>;
  const Carousel = makeCarousel(CarouselUI);

  return (
    <>
      {/* <CarouselBackground> */}
      <Carousel
        defaultWait={1500}
        maxTurns={100} /*wait for 1000 milliseconds*/
      >
        <Slide left>
          <BlocksContainer>
            <Blocks>
              <Creator />
            </Blocks>
            <Blocks>
              <Ruler />
            </Blocks>
            <Blocks>
              <Caregiver />
            </Blocks>
          </BlocksContainer>
        </Slide>
        <Slide left>
          <BlocksContainer>
            <Blocks>
              <Innocent />
            </Blocks>
            <Blocks>
              <Sage />
            </Blocks>
            <Blocks>
              <Explorer />
            </Blocks>
          </BlocksContainer>
        </Slide>
        <Slide left>
          <BlocksContainer>
            <Blocks>
              <Hero />
            </Blocks>
            <Blocks>
              <Rebel />
            </Blocks>
            <Blocks>
              <Magician />
            </Blocks>
          </BlocksContainer>
        </Slide>
        <Slide left>
          <BlocksContainer>
            <Blocks>
              <Joker />
            </Blocks>
            <Blocks>
              <Everyman />
            </Blocks>
            <Blocks>
              <Lover />
            </Blocks>
          </BlocksContainer>
        </Slide>
      </Carousel>
      {/* </CarouselBackground> */}
    </>
  );
};

export default TwelveJungianCarouselLeft;
