const data = [
  {
    slug: "start",
    messages: [
      "Hello! It's great to see you! How are you doing today?",
      // "Love your neighbour as yourself!",
      // "I am driven by caring for and protecting others without expecting anything in return.",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/caregiver/characteristics",
      },
      {
        label: "Build a caregiver character",
        link: "/caregiver/build",
      },
      {
        label: "Back home",
        link: "/voiceapps",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "Their goal is to please everyone they interact with, and their values include generosity, self-sacrifice, empathy, and selflessness.",
      "They are also known as minder, nanny (also nannie), nurse, sitter custodian, guard, keep, keeper, protector attendant, chaperone (or chaperon), companion, carer, caretaker, guardian, an altruist, a parent, a helper, or a supporter.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a caregiver character",
        link: "/caregiver/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a caregiver character",
        link: "/caregiver/build",
      },
      {
        label: "Get Help",
        link: "/caregiver/contact",
      },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/caregiver/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a Web app", link: "/webapps" },
      { label: "more archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Caregiver character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Caregiver character food and beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "support",
    messages: ["Caregiver character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Caregiver character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
