import React from "react";
import voice2 from "../../images/voice2.svg";
import Development from "../../images/development.svg";
import Interaction_design from "../../images/interaction_design.svg";
import LostOnline from "../../images/lostOnline.svg";
import Progressive_app from "../../images/progressive_app.svg";
import builder from "../../images/builder.svg";
import landing_page from "../../images/landing_page.svg";
import undraw_new_ideas from "../../images/undraw_new_ideas.svg";
import undraw_programming from "../../images/undraw_programming.svg";
import { MdFingerprint } from "react-icons/md";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Services = () => {
  return (
    <>
      <ServicesContainer>
        <ServicesWrapper>
          <Fade bottom duration={1200}>
            <ServicesCard>
              <ServicesIcon src={undraw_new_ideas} />
              <ServicesH2>Imagination-Powered Solutions</ServicesH2>
              <ServicesP>
                Transform imaginative ideas into innovative solutions that drive
                business success.
              </ServicesP>
            </ServicesCard>
          </Fade>
          <Fade bottom duration={1400}>
            <ServicesCard>
              <ServicesIcon src={Progressive_app} />
              <ServicesH2>Tech-Infused Engagement</ServicesH2>
              <ServicesP>
                Leveraging customizable AI chatbots and small business templates
                to boost visibility and engagement.
              </ServicesP>
            </ServicesCard>
          </Fade>
          <Fade bottom duration={1600}>
            <ServicesCard>
              <ServicesIcon src={LostOnline} />
              <ServicesH2>Delightful Interactions </ServicesH2>
              <ServicesP>
                Create amazing customer experiences by making interactions with
                your products and services captivating and enjoyable.
              </ServicesP>
            </ServicesCard>
          </Fade>
        </ServicesWrapper>
        {/* <Fade bottom duration={1800}>
          <DisclamerText>
            Our website provides valuable information and showcases how our
            services can be of great assistance to small businesses eager to
            innovate, writers, game developers, and storytellers. For a
            personalized and detailed quote, we encourage you to get in touch
            with us directly. We look forward to discussing how we can best
            support you on your journey.
          </DisclamerText>
        </Fade> */}
      </ServicesContainer>
    </>
  );
};

export default Services;

const ServicesContainer = styled.div`
  padding: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #f9f9f9; */
  background-color: #41ff46;
  /* background-image: linear-gradient(to bottom, #41ff46, #2db231 50%, #010606); */
  /* background-image: linear-gradient(to bottom, #41ff46, #010606); */
  border: none; /* Add this line to remove the border */
  height: auto;
  /* max-width: 1200px; */
  @media screen and (max-width: 1000px) {
    /* height: 900px; */
  }

  @media screen and (max-width: 768px) {
    /* height: 1100px; */
  }
`;

const DisclamerText = styled.div`
  /* font-family: "Orbitron", sans-serif; */
  /* font-weight: 100; */
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  font-size: 0.9rem;
  color: #ffffff;
  /* color: #000; */
  padding: 2rem 2rem 2rem 2rem;
  width: 700px;
  border-radius: 10px;
  background-color: #010606;
  /* background-color: #ffffff; */
  margin: 1.5rem 1.5rem 0rem 1.5rem;
  border: 0.3px solid #000;

  /* background-color: #42ff46; */
  @media screen and (max-width: 768px) {
    width: auto;
    margin: 1.5rem 1rem 0rem 1rem;
    /* border-top: 0.3px solid #000; */
    /* border-bottom: 0.3px solid #000; */
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  padding: 0 30px;
  color: black;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background-color: #fff;
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  border: 0.3px solid #000;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

// #44FF46
export const ServicesCardLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
  font-family: "Orbitron", sans-serif;
  /* text-decoration: underline; */
  font-weight: 900;
  font-size: 2.5rem;
  color: #85ff87;
  /* color: #6fff72; */
  /* color: #fff; */
  margin-bottom: 64px;
  /* text-transform: uppercase; */
  @media screen and (max-width: 1000px) {
    /* margin-top: 64px; */
    font-size: 2rem;
  }

  @media screen and (max-width: 700px) {
    /* margin-bottom: 24px; */
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #000;
  text-align: center;
  /* color: #44fd47; */
`;

export const ServicesP = styled.p`
  font-size: 0.8rem;
  text-align: center;
  color: #000;
  letter-spacing: 0.2px;
`;
