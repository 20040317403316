import React from "react";
import styled from "styled-components";
import {
  InfoContainer,
  InfoWrapper,
  Column1,
  Column2,
  InfoRow,
  Img,
} from "./ArtistSectionElements";
import Fade from "react-reveal/Fade";

const ArtistSection = ({ lightBg, id, imgStart, topLine2, img, alt }) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TopLine2>{topLine2}</TopLine2>
            </Column1>
            <Column2>
              <Fade duration={1600} left>
                <Img src={img} alt={alt} />
              </Fade>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
  // console.log(<Img />);
};

export default ArtistSection;

const TopLine2 = styled.div`
  font-size: 0.4em;
  line-height: 2em;
  max-width: 420px;
  font-weight: bold;
  color: #ffed0c;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;
