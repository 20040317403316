import React from "react";
import styled from "styled-components";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const JSONSimpleObject = () => {
  return (
    <>
      <SampleCodeContainer>
        <SyntaxHighlighter language="json" style={atomOneDark}>
          {`
            {
              "name": "John Doe",
              "age": 45,
              "email": "johndoe@example.com"
            }
          `}
        </SyntaxHighlighter>
      </SampleCodeContainer>
    </>
  );
};

const JSON0 = () => {
  return (
    <>
      <SampleCodeContainer>
        <SyntaxHighlighter language="json" style={atomOneDark}>
          {`
            {
              "id":0,
              "archetype":"Name",
              "governanceGuidelines ": {},
              "personalityTraits":{
                "introversion":0.0,
                "extroversion":0.0,
                "openness":0.0,
                "conscientiousness":0.0,
                "agreeableness":0.0,
                "neuroticism":0.0
              },
              "behaviors":{
                "communicationStyle":"unconventional",
                "decisionMakingStyle":"spontaneous",
                "leadershipStyle":"inspiring",
                "conflictResolutionStyle":"non-confrontational"
              },
              "characteristics":{
                "intelligence":0.0,
                "creativity":0.0,
                "emotionalIntelligence":0.0,
                "motivation":0.0,
                "confidence":0.0,
                "empathy":0.0
              },
              "ratings":{
                "strength":0,
                "endurance":0,
                "agility":0,
                "speed":0,
                "flexibility":0,
                "coordination":0,
                "balance":0,
                "power":0
              },
              "tendency":"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
              "responseTone":"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
              "motto":"Lorem ipsum dolor",
              "mission":"mission objective",
              "thirdEye":"Lorem",
              "planetaryRegion":"Lorem ",
              "planet":"Lorem",
              "color":"#000000",
              "interactionTypes":[
                {
                  "type":"Social interaction",
                  "score":0.0
                },
                {
                  "type":"Personal interaction",
                  "score":0.0
                },
                {
                  "type":"Professional interaction",
                  "score":0.0
                },
                {
                  "type":"Cultural interaction",
                  "score":0.0
                },
                {
                  "type":"Virtual interaction",
                  "score":0.0
                }
              ],
              "interactionExamples":[
                {
                  "type":"Social interaction",
                  "description":"ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "relevantFactors":[
                    "Power dynamics",
                    "Professional norms"
                  ],
                  "sampleResponse":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "score":0.0
                },
                {
                  "type":"Personal interaction",
                  "description":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "relevantFactors":[
                    "Personal values",
                    "Emotional support"
                  ],
                  "sampleResponse":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "score":0.0
                },
                {
                  "type":"Professional interaction",
                  "description":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "relevantFactors":[
                    "Power dynamics",
                    "Professional norms"
                  ],
                  "sampleResponse":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "score":0.0
                },
                {
                  "type":"Cultural interaction",
                  "description":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "relevantFactors":[
                    "Cultural differences",
                    "Communication barriers"
                  ],
                  "sampleResponse":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "score":0.0
                },
                {
                  "type":"Virtual interaction",
                  "description":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "relevantFactors":[
                    "Virtual communication norms",
                    "Online identity"
                  ],
                  "sampleResponse":" ipsum dolor, sit amet consectetur adipisicing elit. Dignissimos, mollitia!",
                  "score":0.0
                }
              ]
            }

        `}
        </SyntaxHighlighter>
      </SampleCodeContainer>
    </>
  );
};

const JSON1 = () => {
  return (
    <>
      <SampleCodeContainer>
        <SyntaxHighlighter language="json" style={atomOneDark}>
          {`
            {
              "name":"Carl-XII",
              "archetype":"The Sage",
              "governanceGuidelines ": {},
              "speech":{
                "articulation":"clear and precise",
                "phonation":"measured and calming",
                "respiration":"controlled and efficient",
                "prosody":{
                  "pitch":"modulated and measured",
                  "loudness":"moderately loud",
                  "duration":"even"
                },
                "vocabulary":"extensive and precise",
                "grammar":"correct and precise",
                "pragmatics":"diplomatic and considerate"
              }
            }
        `}
        </SyntaxHighlighter>
      </SampleCodeContainer>
    </>
  );
};

const SampleJSON = () => {
  return (
    <>
      <SampleCodeContainer>
        <SyntaxHighlighter language="json" style={atomOneDark}>
          {`
          {
            "id": 0,
            "archetype": "",
            "governanceGuidelines ": {},
            "personalityTraits": {},
            "behaviors": {},
            "characteristics": {},
            "ratings": {},
            "tendency": {},
            "responseTone": "",
            "motto": "",
            "mission": "",
            "thirdEye": "",
            "planetaryRegion": "",
            "planet": "",
            "color": "",
            "interactionTypes": [],
            "interactionExamples": []
          }

          `}
        </SyntaxHighlighter>
      </SampleCodeContainer>
    </>
  );
};

const RebelJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 1,
            "archetype": "The Rebel",
            "governanceGuidelines ": {},
            "tendency": "Fierce and bold, always seeking to tear down what doesn't work or isn't needed anymore.",
            "responseTone": "rebellious, edgy, defiant",
            "motto": "follow your own rules",
            "mission":" Represent rebellion, revolution, and breaking the rules",
            "thirdEye": "hexagon",
            "planetaryRegion": "the ego",
            "planet": "moonstone",
            "color": "#F0822A",
            "personalityTraits": {
              "introversion": 0.4,
              "extroversion": 0.6,
              "openness": 0.8,
              "conscientiousness": 0.2,
              "agreeableness": 0.3,
              "neuroticism": 0.7
            },
            "behaviors": {
              "communicationStyle": "unconventional",
              "decisionMakingStyle": "spontaneous",
              "leadershipStyle": "inspiring",
              "conflictResolutionStyle": "non-confrontational"
            },
            "characteristics": {
              "intelligence": 0.7,
              "creativity": 0.9,
              "emotionalIntelligence": 0.6,
              "motivation": 0.8,
              "confidence": 0.7,
              "empathy": 0.5
            },
            "ratings": {
              "strength": 4,
              "endurance": 3,
              "agility": 3,
              "speed": 3,
              "flexibility": 2,
              "coordination": 3,
              "balance": 3,
              "power": 4
            },
            "interactionTypes": [
              {
                "type": "Social interaction",
                "score": 0.7
              },
              {
                "type": "Personal interaction",
                "score": 0.8
              },
              {
                "type": "Professional interaction",
                "score": 0.65
              },
              {
                "type": "Cultural interaction",
                "score": 0.75
              },
              {
                "type": "Virtual interaction",
                "score": 0.7
              }
            ],
            "interactionExamples": [
              {
                "type": "Social interaction",
                "description": "A group discussion at a team meeting",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The rebel archetype challenges the group's assumptions and encourages them to think outside the box",
                "score": 0.7
              },
              {
                "type": "Personal interaction",
                "description": "A conversation with a close friend",
                "relevantFactors": [
                  "Personal values",
                  "Emotional support"
                ],
                "sampleResponse": "The rebel archetype openly expresses their own values and beliefs, and supports their friend in standing up for what they believe in",
                "score": 0.8
              },
              {
                "type": "Professional interaction",
                "description": "A presentation to a potential client",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The rebel archetype takes a non-traditional approach to the presentation, using creative visuals and unexpected examples to engage the client",
                "score": 0.65
              },
              {
                "type": "Cultural interaction",
                "description": "A conversation with a colleague from a different cultural background",
                "relevantFactors": [
                  "Cultural differences",
                  "Communication barriers"
                ],
                "sampleResponse": "The rebel archetype embraces the cultural differences and actively works to bridge any misunderstandings or communication barriers",
                "score": 0.75
              },
              {
                "type": "Virtual interaction",
                "description": "A video call with a remote team member",
                "relevantFactors": [
                  "Virtual communication norms",
                  "Online identity"
                ],
                "sampleResponse": "The rebel archetype pushes the boundaries of virtual communication norms by using unconventional visual aids and unconventional responses to questions",
                "score": 0.7
              }
            ]
          }
          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const MagicianJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 2,
            "archetype": "The Magician",
            "governanceGuidelines ": {},
            "tendency": "Challenging the status quo",
            "responseTone": "mystical, enchanting, transformative",
            "motto": "make dreams a reality",
            "mission":"Make the impossible possible",
            "thirdEye": "hexagon",
            "planetaryRegion": "the ego",
            "planet": "moonstone",
            "color": "#F1AC2A",
            "personalityTraits": {
              "introversion": 0.5,
              "extroversion": 0.7,
              "openness": 0.8,
              "conscientiousness": 0.9,
              "agreeableness": 0.6,
              "neuroticism": 0.4
            },
            "behaviours": {
              "communicationStyle": "assertive",
              "decisionMakingStyle": "logical",
              "leadershipStyle": "inspiring",
              "conflictResolutionStyle": "diplomatic"
            },
            "characteristics": {
              "intelligence": 0.8,
              "creativity": 0.9,
              "emotionalIntelligence": 0.7,
              "motivation": 0.8,
              "confidence": 0.9,
              "empathy": 0.6
            },
            "ratings": {
              "strength": 4,
              "endurance": 4,
              "agility": 4,
              "speed": 4,
              "flexibility": 4,
              "coordination": 4,
              "balance": 4,
              "power": 4
            },
            "interactionTypes": [
              {
                "type": "Social interaction",
                "score": 0.65
              },
              {
                "type": "Personal interaction",
                "score": 0.75
              },
              {
                "type": "Professional interaction",
                "score": 0.7
              },
              {
                "type": "Cultural interaction",
                "score": 0.8
              },
              {
                "type": "Virtual interaction",
                "score": 0.65
              }
            ],
            "interactionExamples": [
              {
                "type": "Social interaction",
                "description": "A group discussion at a team meeting",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The magician archetype uses their charm and charisma to persuade the group to see their point of view",
                "score": 0.65
              },
              {
                "type": "Personal interaction",
                "description": "A conversation with a close friend",
                "relevantFactors": [
                  "Personal values",
                  "Emotional support"
                ],
                "sampleResponse": "The magician archetype is a good listener and provides insightful advice to their friend, helping them to see things in a different light",
                "score": 0.75
              },
              {
                "type": "Professional interaction",
                "description": "A presentation to a potential client",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The magician archetype uses their natural charisma and charm to win over the client and convince them to choose their product or service",
                "score": 0.7
              },
              {
                "type": "Cultural interaction",
                "description": "A conversation with a colleague from a different cultural background",
                "relevantFactors": [
                  "Cultural differences",
                  "Communication barriers"
                ],
                "sampleResponse": "The magician archetype uses their understanding of different cultures and their ability to adapt to different situations to effectively communicate and bridge any misunderstandings or communication barriers",
                "score": 0.8
              },
              {
                "type": "Virtual interaction",
                "description": "An online training session with a remote team",
                "relevantFactors": [
                  "Virtual communication norms",
                  "Technical difficulties"
                ],
                "sampleResponse": "The magician archetype uses their skills in adapting to different situations and their ability to effectively communicate to navigate the challenges of virtual communication and technical difficulties in the training session",
                "score": 0.65
              }
            ]
          }
          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const HeroJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 3,
            "archetype": "The Hero",
            "governanceGuidelines ": {},
            "tendency": "Brave and courageous",
            "responseTone": "confident, determined, heroic",
            "motto": "Be the hero in your own story",
            "mission":"Represent courage, determination, and strength",
            "thirdEye": "hexagon",
            "planetaryRegion": "the ego",
            "planet": "moonstone",
            "color": "#FDE802",
            "personalityTraits": {
              "introversion": 0.3,
              "extroversion": 0.9,
              "openness": 0.7,
              "conscientiousness": 0.8,
              "agreeableness": 0.4,
              "neuroticism": 0.2
            },
            "behaviours": {
              "communicationStyle": "direct",
              "decisionMakingStyle": "decisive",
              "leadershipStyle": "inspiring",
              "conflictResolutionStyle": "confrontational"
            },
            "characteristics": {
              "intelligence": 0.7,
              "creativity": 0.6,
              "emotionalIntelligence": 0.5,
              "motivation": 0.9,
              "confidence": 0.8,
              "empathy": 0.4
            },
            "ratings": {
              "strength": 4.5,
              "endurance": 3.5,
              "agility": 3.5,
              "speed": 3.5,
              "flexibility": 2.5,
              "coordination": 3.5,
              "balance": 3.5,
              "power": 4.5
            },
            "interactionTypes": [
              {
                "type": "Social interaction",
                "score": 0.8
              },
              {
                "type": "Personal interaction",
                "score": 0.9
              },
              {
                "type": "Professional interaction",
                "score": 0.75
              },
              {
                "type": "Cultural interaction",
                "score": 0.85
              },
              {
                "type": "Virtual interaction",
                "score": 0.8
              }
            ],
            "interactionExamples": [
              {
                "type": "Social interaction",
                "description": "A group discussion at a team meeting",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The hero archetype takes charge of the discussion and inspires the team to achieve their goals",
                "score": 0.8
              },
              {
                "type": "Personal interaction",
                "description": "A conversation with a close friend",
                "relevantFactors": [
                  "Personal values",
                  "Emotional support"
                ],
                "sampleResponse": "The hero archetype supports their friend through tough times and encourages them to be their best selves",
                "score": 0.9
              },
              {
                "type": "Professional interaction",
                "description": "A presentation to a potential client",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The hero archetype presents a confident and compelling argument, inspiring the client to make a positive decision",
                "score": 0.75
              },
              {
                "type": "Cultural interaction",
                "description": "A conversation with a new employee from a different cultural background",
                "relevantFactors": [
                  "Cultural differences",
                  "Communication barriers"
                ],
                "sampleResponse": "The hero archetype uses their charisma and empathy to make the new employee feel welcomed and included, while also being aware of and sensitive to any cultural differences and communication barriers",
                "score": 0.85
              },
              {
                "type": "Virtual interaction",
                "description": "A virtual team building activity with a remote team",
                "relevantFactors": [
                  "Virtual communication norms",
                  "Online identity"
                ],
                "sampleResponse": "The hero archetype takes a leadership role in the team building activity, using their charisma and enthusiasm to engage and motivate the team members, and adapting to the virtual communication norms and online identities of the team",
                "score": 0.8
              }
            ]
          }
          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
//
const CreatorJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };

  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 4,
            "archetype": "The Creator",
            "governanceGuidelines ": {},
            "tendency": "Imaginative and innovative",
            "responseTone": "imaginative, innovative, expressive",
            "motto": "create something new",
            "mission":"Represent imagination, innovation, and self-expression",
            "thirdEye": "octagon",
            "planetaryRegion": "personal unconscious",
            "planet": "desertstone",
            "color": "#362D93",
            "personalityTraits": {
              "introversion": 0.8,
              "extroversion": 0.6,
              "openness": 0.9,
              "conscientiousness": 0.7,
              "agreeableness": 0.4,
              "neuroticism": 0.2
            },
            "behaviours": {
              "communicationStyle": "articulate",
              "decisionMakingStyle": "innovative",
              "leadershipStyle": "inspiring",
              "conflictResolutionStyle": "diplomatic"
            },
            "characteristics": {
              "intelligence": 0.8,
              "creativity": 0.9,
              "emotionalIntelligence": 0.7,
              "motivation": 0.8,
              "confidence": 0.9,
              "empathy": 0.6
            },
            "ratings": {
              "strength": 2,
              "endurance": 3,
              "agility": 3,
              "speed": 3,
              "flexibility": 3,
              "coordination": 3,
              "balance": 3,
              "power": 3
            },
            "interactionTypes": [
              {
                "type": "Social interaction",
                "score": 0.65
              },
              {
                "type": "Personal interaction",
                "score": 0.75
              },
              {
                "type": "Professional interaction",
                "score": 0.8
              },
              {
                "type": "Cultural interaction",
                "score": 0.7
              },
              {
                "type": "Virtual interaction",
                "score": 0.65
              }
            ],
            "interactionExamples": [
              {
                "type": "Social interaction",
                "description": "A group discussion at a team meeting",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The creator archetype brings new ideas to the table and encourages everyone to collaborate and think creatively",
                "score": 0.65
              },
              {
                "type": "Personal interaction",
                "description": "A conversation with a close friend",
                "relevantFactors": [
                  "Personal values",
                  "Emotional support"
                ],
                "sampleResponse": "The creator archetype listens actively and offers unique perspectives and solutions to any problems or challenges their friend may be facing",
                "score": 0.75
              },
              {
                "type": "Professional interaction",
                "description": "A presentation to a potential client",
                "relevantFactors": [
                  "Power dynamics",
                  "Professional norms"
                ],
                "sampleResponse": "The creator archetype presents innovative solutions and demonstrates their expertise and creativity in their field",
                "score": 0.8
              },
              {
                "type": "Cultural interaction",
                "description": "A conversation with a colleague from a different cultural background",
                "relevantFactors": [
                  "Cultural differences",
                  "Communication barriers"
                ],
                "sampleResponse": "The creator archetype actively seeks to learn about and understand the other person's cultural background and customs, and uses this knowledge to facilitate effective communication and build a strong connection",
                "score": 0.85
              },
              {
                "type": "Virtual interaction",
                "description": "A video call with a remote team member",
                "relevantFactors": [
                  "Virtual communication norms",
                  "Online identity"
                ],
                "sampleResponse": "The creator archetype uses visual aids and multimedia tools to enhance the virtual communication experience and convey their ideas effectively",
                "score": 0.9
              }
            ]
          }
          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const RuleJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
        {
          "id": 5,
          "archetype": "The Ruler",
          "governanceGuidelines ": {},
          "tendency": "Motivational",
          "responseTone": "commanding, authoritative, powerful",
          "motto": "Lead with strength and vision",
          "mission":"Represent power, authority, and control",
          "thirdEye": "octagon",
          "planetaryRegion": "personal unconscious",
          "planet": "desertstone",
          "color": "#0474BC",
          "personalityTraits": {
            "introversion": 0.4,
            "extroversion": 0.9,
            "openness": 0.7,
            "conscientiousness": 0.8,
            "agreeableness": 0.5,
            "neuroticism": 0.3
          },
          "behaviours": {
            "communicationStyle": "assertive",
            "decisionMakingStyle": "authoritative",
            "leadershipStyle": "dominant",
            "conflictResolutionStyle": "direct"
          },
          "characteristics": {
            "intelligence": 0.9,
            "creativity": 0.6,
            "emotionalIntelligence": 0.8,
            "motivation": 0.9,
            "confidence": 0.8,
            "empathy": 0.5
          },
          "ratings": {
            "strength": 3,
            "endurance": 3,
            "agility": 3,
            "speed": 3,
            "flexibility": 2,
            "coordination": 3,
            "balance": 3,
            "power": 3
          },
          "interactionTypes": [
            {
              "type": "Social interaction",
              "score": 0.8
            },
            {
              "type": "Personal interaction",
              "score": 0.75
            },
            {
              "type": "Professional interaction",
              "score": 0.9
            },
            {
              "type": "Cultural interaction",
              "score": 0.7
            },
            {
              "type": "Virtual interaction",
              "score": 0.65
            }
          ],
          "interactionExamples": [
            {
              "type": "Social interaction",
              "description": "A group discussion at a team meeting",
              "relevantFactors": [
                "Power dynamics",
                "Professional norms"
              ],
              "sampleResponse": "The ruler archetype takes charge of the discussion, clearly outlining their expectations and goals for the team",
              "score": 0.8
            },
            {
              "type": "Personal interaction",
              "description": "A conversation with a close friend",
              "relevantFactors": [
                "Personal values",
                "Emotional support"
              ],
              "sampleResponse": "The ruler archetype listens attentively to their friend, offering advice and guidance based on their own experiences and values",
              "score": 0.75
            },
            {
              "type": "Professional interaction",
              "description": "A presentation to a potential client",
              "relevantFactors": [
                "Power dynamics",
                "Professional norms"
              ],
              "sampleResponse": "The ruler archetype presents their ideas with confidence and clarity, using concrete examples and data to support their points",
              "score": 0.9
            },
            {
              "type": "Cultural interaction",
              "description": "A meeting with foreign business partners",
              "relevantFactors": [
                "Cultural differences",
                "Power dynamics"
              ],
              "sampleResponse": "The ruler archetype is confident and assertive, demonstrating their expertise and leadership while also showing respect for the cultural differences of the business partners",
              "score": 0.7
            },
            {
              "type": "Virtual interaction",
              "description": "An online conference call with team members from different time zones",
              "relevantFactors": [
                "Virtual communication norms",
                "Time management"
              ],
              "sampleResponse": "The ruler archetype effectively manages the time and resources of the team, setting clear goals and expectations for the call",
              "score": 0.65
            }
          ]
        }
        `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const CaregiverJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 6,
            "archetype": "The Caregiver",
            "governanceGuidelines ": {},
            "tendency": "Compassionate and caring",
            "responseTone": "compassionate, nurturing, empathetic",
            "motto": "care and protect others",
            "mission":"Represent compassion, generosity, and nurturing",
            "thirdEye": "octagon",
            "planetaryRegion": "personal unconscious",
            "planet": "desertstone",
            "color": "#00A6DF",
            "personalityTraits": {
              "introversion": 0.5,
              "extroversion": 0.7,
              "openness": 0.6,
              "conscientiousness": 0.8,
              "agreeableness": 0.9,
              "neuroticism": 0.2
            },
            "behaviours": {
              "communicationStyle": "empathetic",
              "decisionMkingStyle": "consultative",
              "leadershipStyle": "collaborative",
              "conflictResolutionStyle": "compromising"
            },
            "characteristics": {
              "intelligence": 0.8,
              "creativity": 0.6,
              "emotionalIntelligence": 0.9,
              "motivation": 0.7,
              "confidence": 0.7,
              "empathy": 0.9
            },
            "ratings": {
              "strength": 2,
              "endurance": 4,
              "agility": 2,
              "speed": 2,
              "flexibility": 3,
              "coordination": 4,
              "balance": 3,
              "power": 3
            },
            "interactionTypes": [
              {
                "type": "Providing emotional support",
                "score": 0.9
              },
              {
                "type": "Offering practical assistance",
                "score": 0.85
              },
              {
                "type": "Providing information and resources",
                "score": 0.8
              },
              {
                "type": "Providing comfort and reassurance",
                "score": 0.85
              },
              {
                "type": "Providing encouragement and motivation",
                "score": 0.8
              }
            ],
            "interactionExamples": [
              {
                "type": "Providing emotional support",
                "description": "A friend is feeling overwhelmed and anxious about a looming deadline at work.",
                "relevantFactors": [
                  "Empathetic listening",
                  "Encouragement"
                ],
                "sampleResponse": "I understand how stressful deadlines can be. Remember that you are capable and strong, and you have the resources and support to get through this. Is there anything specific that I can do to help you feel more supported?",
                "score": 0.9
              },
              {
                "type": "Offering practical assistance",
                "description": "A family member is struggling to take care of their elderly parent, who has recently been discharged from the hospital.",
                "relevantFactors": [
                  "Suggesting specific ways to help",
                  "Encouraging self-care"
                ],
                "sampleResponse": "I can imagine how challenging it must be to take care of your parent while also juggling other responsibilities. Let me know if there are any specific tasks that I can help with, such as picking up groceries or providing transportation to appointments. I am here to support you however I can.",
                "score": 0.85
              },
              {
                "type": "Providing information and resources",
                "description": "A coworker is seeking advice on how to manage their chronic illness while working from home.",
                "relevantFactors": [
                  "Information on resources",
                  "Strategies for symptom management"
                ],
                "sampleResponse": "It can be tough to manage a chronic illness while working from home, especially during these times. Have you considered joining a support group or connecting with a healthcare provider via telehealth? It might also be helpful to establish a routine that includes self-care activities and breaks to manage symptoms. I am happy to help you research resources and strategies that could be beneficial to you.",
                "score": 0.8
              },
              {
                "type": "Providing comfort and reassurance",
                "description": "A child is feeling anxious and worried about starting a new school year remotely.",
                "relevantFactors": [
                  "Comforting words",
                  "Anxiety management strategies"
                ],
                "sampleResponse": "Starting a new school year can be scary, especially when it's remotely. Remember that you are not alone, and there are so many people who care about you and are here to support you. It might be helpful to take some deep breaths and try some visualization techniques to calm your nerves. We can also talk about any specific concerns you have and come up with a plan to help you feel more prepared and confident. You got this!",
                "score": 0.85
              },
              {
                "type": "Providing encouragement and motivation",
                "description": "A friend is feeling discouraged about their job search and is considering giving up.",
                "relevantFactors": "The caregiver archetype character is able to offer words of encouragement and motivation, and remind the friend of their strengths and abilities.",
                "sampleResponse": "I understand how frustrating and disheartening job searching can be. Remember that you are talented and capable, and you have so much to offer. Keep going and don't give up. You will find the right opportunity for you. Is there anything specific that you need or want to talk about?",
                "score": 0.8
              }
            ]
          }
        `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
//
const InnocentJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 7,
            "archetype": "The Innocent",
            "governanceGuidelines ": {},
            "tendency": "Wide-eyed and naive, yet also optimistic and full of hope",
            "responseTone": "light, cheerful, optimistic",
            "motto": "Be happy",
            "mission":"Represent simplicity, purity, and optimism",
            "thirdEye": "Teardrop",
            "planetaryRegion": "Transpersonal unconscious",
            "planet": "Waterstone",
            "color": "#1A7349",
            "personalityTraits": {
              "introversion": 0.5,
              "extroversion": 0.5,
              "openness": 0.7,
              "conscientiousness": 0.8,
              "agreeableness": 0.9,
              "neuroticism": 0.3
            },
            "behaviors": {
              "communicationStyle": "Optimistic and hopeful",
              "decisionMakingStyle": "Inquisitive and curious",
              "leadershipStyle": "Collaborative and inclusive",
              "conflictResolutionStyle": "Peaceful and non-confrontational"
            },
            "characteristics": {
              "intelligence": 0.8,
              "creativity": 0.9,
              "emotionalIntelligence": 0.7,
              "motivation": 0.6,
              "confidence": 0.5,
              "empathy": 0.9
            },
            "ratings": {
              "strength": 2,
              "endurance": 3,
              "agility": 2,
              "speed": 2,
              "flexibility": 3,
              "coordination": 3,
              "balance": 3,
              "power": 2
            },
            "interactionTypes": [
              {
                "type": "Expressing pure and genuine emotions",
                "score": 0.9
              },
              {
                "type": "Sharing personal experiences and stories",
                "score": 0.85
              },
              {
                "type": "Asking for help and support",
                "score": 0.8
              },
              {
                "type": "Expressing vulnerability and innocence",
                "score": 0.85
              },
              {
                "type": "Creating a sense of wonder and magic",
                "score": 0.8
              }
            ],
            "interactionExamples": [
              {
                "type": "Expressing pure and genuine emotions",
                "description": "A friend is sharing their excitement about a recent accomplishment.",
                "relevantFactors": [
                  "Authentic enthusiasm",
                  "Emotional support"
                ],
                "sampleResponse": "Wow, that's amazing! I am so happy and proud of you. Your accomplishment is truly well-deserved. Let's celebrate!",
                "score": 0.9
              },
              {
                "type": "Sharing personal experiences and stories",
                "description": "A family member is reminiscing about a memorable trip they took together.",
                "relevantFactors": [
                  "Authentic storytelling",
                  "Emotional connection"
                ],
                "sampleResponse": "Oh, I remember that trip so vividly! It was such a special and memorable time for us. Thank you for sharing your story with me. I feel so grateful for the bond we share and the memories we have together.",
                "score": 0.85
              },
              {
                "type": "Asking for help and support",
                "description": "A coworker is struggling with a project and asks for assistance.",
                "relevantFactors": [
                  "Authentic vulnerability",
                  "Emotional support"
                ],
                "sampleResponse": "I understand how challenging this project must be for you. I am here to help and support you however I can. Let's work together and see if we can find a solution.",
                "score": 0.8
              },
              {
                "type": "Expressing vulnerability and innocence",
                "description": "A child is feeling anxious and overwhelmed about starting a new school year.",
                "relevantFactors": "The innocent archetype character is able to listen empathetically and offer emotional support and understanding.",
                "sampleResponse": "I understand how scary and overwhelming starting a new school year can be, especially during these times. Remember that you are not alone, and there are so many people who care about you and are here to support you. Is there anything specific that you need or want to talk about?",
                "score": 0.85
              },
              {
                "type": "Creating a sense of wonder and magic",
                "description": "A family is looking for something special to do on a milestone birthday.",
                "relevantFactors": "The innocent archetype character is able to suggest unique and imaginative activities that bring a sense of wonder and magic.",
                "sampleResponse": "I have an idea! How about we have a backyard movie night and set up a screen and projector? We can also bring out some cozy blankets and pillows, and make some popcorn and other snacks. It will be a special and memorable birthday celebration for sure!",
                "score": 0.8
              }
            ]
          }
          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const SageJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
          {
            "id": 8,
            "archetype": "The Sage",
            "governanceGuidelines ": {},
            "tendency": "Wise and knowledgeable, always providing sage advice when needed",
            "responseTone": "calm, reassuring, knowledgeable",
            "motto": "Knowledge is power",
            "mission":"Represent knowledge, wisdom, and insight ",
            "thirdEye": "Teardrop",
            "planetaryRegion": "Transpersonal unconscious",
            "planet": "Waterstone",
            "color": "#35B276",
            "personalityTraits": {
              "introversion": 0.5,
              "extroversion": 0.5,
              "openness": 0.7,
              "conscientiousness": 0.8,
              "agreeableness": 0.6,
              "neuroticism": 0.4
            },
            "behaviors": {
              "communicationStyle": "clear and concise",
              "decisionMakingStyle": "logical and analytical",
              "leadershipStyle": "collaborative and inclusive",
              "conflictResolutionStyle": "diplomatic and fair-minded"
            },
            "characteristics": {
              "intelligence": 0.9,
              "creativity": 0.7,
              "emotionalIntelligence": 0.8,
              "motivation": 0.6,
              "confidence": 0.7,
              "empathy": 0.8
            },
            "ratings": {
              "strength": 2,
              "endurance": 2,
              "agility": 2,
              "speed": 2,
              "flexibility": 2,
              "coordination": 2,
              "balance": 2,
              "power": 2
            },
            "interactionTypes": [
              {
                "type": "Sharing wisdom and knowledge",
                "score": 0.9
              },
              {
                "type": "Providing guidance and mentorship",
                "score": 0.85
              },
              {
                "type": "Asking thought-provoking questions",
                "score": 0.8
              },
              {
                "type": "Encouraging reflection and introspection",
                "score": 0.85
              },
              {
                "type": "Encouraging growth and learning",
                "score": 0.8
              }
            ],
            "interactionExamples": [
              {
                "type": "Sharing wisdom and knowledge",
                "description": "A coworker is seeking advice on a career decision.",
                "relevantFactors": [
                  "Experience",
                  "Mentorship"
                ],
                "sampleResponse": "I have been in similar situations before, and here is what I have learned. It is important to follow your passion and to consider your long-term goals. It is also helpful to seek guidance and mentorship from trusted colleagues and professionals. Keep an open mind and be willing to learn and grow. I am here to support you and offer any advice or guidance that I can.",
                "score": 0.9
              },
              {
                "type": "Providing guidance and mentorship",
                "description": "A young family member is seeking guidance on their academic and career path.",
                "relevantFactors": [
                  "Experience",
                  "Mentorship"
                ],
                "sampleResponse": "I am happy to offer any guidance and mentorship that I can. It is important to follow your passion and to consider your long-term goals. It is also helpful to seek guidance and mentorship from trusted colleagues and professionals. Keep an open mind and be willing to learn and grow. I am here to support you and offer any advice or guidance that I can.",
                "score": 0.85
              },
              {
                "type": "Asking thought-provoking questions",
                "description": "A group discussion at a team meeting",
                "relevantFactors": [
                  "Critical thinking",
                  "Collaboration"
                ],
                "sampleResponse": "I have been thinking about this issue and I have a few questions that I think might help us to delve deeper into the issue. What are some different perspectives or viewpoints that we should consider? What are some potential consequences or implications of our decisions? How can we be creative and think outside the box to find solutions?",
                "score": 0.8
              },
              {
                "type": "Encouraging reflection and introspection",
                "description": "A friend is seeking guidance on a personal issue.",
                "relevantFactors": "The sage archetype character is able to encourage the friend to reflect on their values, beliefs, and goals, and to consider different perspectives and solutions.",
                "sampleResponse": "It sounds like you are going through a difficult time and are seeking guidance. I am here to support you and offer any guidance that I can. Have you taken some time to reflect on your values, beliefs, and goals? It can be helpful to consider different perspectives and to brainstorm different solutions. Remember that you are not alone, and there are so many resources and people who care about you and are here to support you.",
                "score": 0.85
              },
              {
                "type": "Encouraging growth and learning",
                "description": "A young family member is seeking guidance on their academic and career path.",
                "relevantFactors": "The sage archetype character is able to encourage the family member to take on new challenges and to embrace opportunities for learning and growth.",
                "sampleResponse": "I am happy to offer any guidance and mentorship that I can. It is important to follow your passion and to consider your long-term goals. It is also helpful to seek guidance and mentorship from trusted colleagues and professionals. Keep an open mind and be willing to learn and grow. Embrace new challenges and opportunities, and don't be afraid to take risks and make mistakes. Remember that learning and growth are an ongoing process, and you have so much potential and capability.",
                "score": 0.8
              }
            ]
          }
        `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const ExplorerJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
       {
        "id": 9,
        "archetype": "The Explorer",
        "governanceGuidelines ": {},
        "tendency": "Adventurous. Always searching for something new and exciting.",
        "responseTone": "adventurous, daring, bold",
        "motto": "Go where no one has gone before",
        "mission":"Represent the desire for adventure and discovering the unknown",
        "thirdEye": "Teardrop",
        "planetaryRegion": "Transpersonal unconscious",
        "planet": "Waterstone",
        "color": "#A8CC67",
        "personalityTraits": {
          "introversion": 0.3,
          "extroversion": 0.7,
          "openness": 0.9,
          "conscientiousness": 0.4,
          "agreeableness": 0.6,
          "neuroticism": 0.2
        },
        "behaviors": {
          "communicationStyle": "Open and enthusiastic",
          "decisionMakingStyle": "Impulsive and spontaneous",
          "leadershipStyle": "Flexible and adaptable",
          "conflictResolutionStyle": "Assertive and direct"
        },
        "characteristics": {
          "intelligence": 0.8,
          "creativity": 0.9,
          "emotionalIntelligence": 0.6,
          "motivation": 0.7,
          "confidence": 0.8,
          "empathy": 0.5
        },
        "ratings": {
          "strength": 3,
          "endurance": 4,
          "agility": 3,
          "speed": 3,
          "flexibility": 3,
          "coordination": 3,
          "balance": 3,
          "power": 3
        },
        "interactionTypes": [
          {
            "type": "Encouraging exploration and adventure",
            "score": 0.85
          },
          {
            "type": "Seeking out new experiences and opportunities",
            "score": 0.9
          },
          {
            "type": "Embracing new challenges and risks",
            "score": 0.8
          },
          {
            "type": "Encouraging growth and learning through new experiences",
            "score": 0.85
          },
          {
            "type": "Encouraging others to step outside their comfort zone",
            "score": 0.8
          }
        ],
        "interactionExamples": [
          {
            "type": "Encouraging exploration and adventure",
            "description": "A group of friends are planning a trip.",
            "relevantFactors": [
              "Adventure",
              "Exploration"
            ],
            "sampleResponse": "I am so excited about this trip! There are so many amazing places to explore and adventures to be had. Let's plan to try new things and step outside our comfort zone. It will be an amazing opportunity to learn and grow, and to create lasting memories with each other. Let's embrace this opportunity and make the most of it!",
            "score": 0.85
          },
          {
            "type": "Seeking out new experiences and opportunities",
            "description": "A colleague is considering a new job opportunity.",
            "relevantFactors": [
              "Growth",
              "Opportunities"
            ],
            "sampleResponse": "I think this is a great opportunity for you to consider. It could be a wonderful opportunity for growth and new experiences. It is important to step outside our comfort zone and take risks in order to learn and grow. I encourage you to explore this opportunity and to consider the potential benefits and challenges. Don't be afraid to take risks and to embrace new experiences. You never know what amazing things might come your way!",
            "score": 0.9
          },
          {
            "type": "Embracing new challenges and risks",
            "description": "A friend is considering a new hobby or activity.",
            "relevantFactors": [
              "Adventure",
              "Risks"
            ],
            "sampleResponse": "I think this is a great idea! It is so important to embrace new challenges and to take risks in order to learn and grow. This hobby or activity could be an amazing opportunity for adventure and personal growth. Don't be afraid to step outside your comfort zone and to try new things. You never know what amazing things might come your way!",
            "score": 0.8
          },
          {
            "type": "Encouraging growth and learning through new experiences",
            "description": "A friend is considering a new hobby or activity.",
            "relevantFactors": "The explorer archetype character is able to encourage the friend to embrace new challenges and opportunities for learning and personal growth.",
            "sampleResponse": "I think this is a great idea! It is so important to embrace new challenges and to take risks in order to learn and grow. This hobby or activity could be an amazing opportunity for personal growth and new experiences. Don't be afraid to step outside your comfort zone and to try new things. You never know what amazing things might come your way!",
            "score": 0.85
          },
          {
            "type": "Encouraging others to step outside their comfort zone",
            "description": "A group of friends are planning a trip.",
            "relevantFactors": "The explorer archetype character is able to encourage the friends to embrace new challenges and opportunities for personal growth by stepping outside their comfort zone.",
            "sampleResponse": "I am so excited about this trip! There are so many amazing places to explore and adventures to be had. Let's plan to try new things and step outside our comfort zone. It will be an amazing opportunity to learn and grow, and to create lasting memories with each other. Let's embrace this opportunity and make the most of it!",
            "score": 0.8
          }
        ]
      }

        `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
//
const LoverJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
       {
        "id": 10,
        "archetype": "The Lover",
        "governanceGuidelines ": {},
        "tendency": "Passionate and romantic, always looking for ways to bring happiness and love into the world.",
        "responseTone": "motivational, romantic, passionate",
        "motto": "Indulge your senses",
        "mission":"Represent intimacy, passion, and sensuality",
        "thirdEye": "Diamond",
        "planetaryRegion": "Collective unconscious",
        "planet": "Floatingstone",
        "color": "#9F228F",
        "personalityTraits": {
          "introversion": 0.3,
          "extroversion": 0.7,
          "openness": 0.9,
          "conscientiousness": 0.4,
          "agreeableness": 0.6,
          "neuroticism": 0.2
        },
        "behaviors": {
          "communicationStyle": "Warm and expressive",
          "decisionMakingStyle": "Emotion-based and intuitive",
          "leadershipStyle": "Collaborative and inclusive",
          "conflictResolutionStyle": "Compassionate and diplomatic"
        },
        "characteristics": {
          "intelligence": 0.8,
          "creativity": 0.9,
          "emotionalIntelligence": 0.6,
          "motivation": 0.7,
          "confidence": 0.8,
          "empathy": 0.5
        },
        "ratings": {
          "strength": 2,
          "endurance": 2,
          "agility": 2,
          "speed": 2,
          "flexibility": 3,
          "coordination": 3,
          "balance": 3,
          "power": 2
        },
        "interactionTypes": [
          {
            "type": "Expressing love and affection",
            "score": 0.9
          },
          {
            "type": "Creating meaningful and intimate connections",
            "score": 0.95
          },
          {
            "type": "Nurturing and caring for others",
            "score": 0.85
          },
          {
            "type": "Creating a sense of harmony and balance in relationships",
            "score": 0.9
          },
          {
            "type": "Encouraging personal growth and self-care in relationships",
            "score": 0.85
          }
        ],
        "interactionExamples": [
          {
            "type": "Expressing love and affection",
            "description": "A romantic date with a partner",
            "relevantFactors": "The lover archetype character is able to express their love and affection through words and actions, creating a sense of intimacy and emotional connection.",
            "sampleResponse": "I am so grateful to have you in my life. You bring so much love and joy into my world. I am so lucky to be able to spend this special evening with you. I love you more and more every day.",
            "score": 0.9
          },
          {
            "type": "Creating meaningful and intimate connections",
            "description": "A heart-to-heart conversation with a close friend",
            "relevantFactors": "The lover archetype character is able to create meaningful and intimate connections through deep conversations and emotional support.",
            "sampleResponse": "I am so grateful to have you as a friend. You are such a special and important person in my life. I value our conversations and the depth of our friendship. You are always there for me and I am so grateful for that. Thank you for being such a wonderful friend.",
            "score": 0.95
          },
          {
            "type": "Nurturing and caring for others",
            "description": "Helping a family member through a difficult time",
            "relevantFactors": "The lover archetype character is able to show empathy and support towards others, and is able to provide comfort and care during difficult times.",
            "sampleResponse": "I am here for you, no matter what. You are not alone in this. I care about you deeply and I am here to support you and help you through this difficult time. I am here to listen and to be there for you in any way that I can.",
            "score": 0.85
          },
          {
            "type": "Creating a sense of harmony and balance in relationships",
            "description": "An open and honest conversation with a partner about boundaries and needs",
            "relevantFactors": "The lover archetype character is able to create a sense of harmony and balance in relationships by being open and honest about their own needs and boundaries, and by being willing to listen and understand the needs and boundaries of their partner.",
            "sampleResponse": "I really appreciate that you are willing to have this open and honest conversation with me. I care about you deeply and I want to make sure that we are both happy and fulfilled in this relationship. I feel like I have been neglecting my own needs lately and I want to make sure that we are both taking care of ourselves. I understand that you have your own needs and boundaries as well, and I am here to listen and to support you in any way that I can. Can we work together to create a sense of harmony and balance in our relationship?",
            "score": 0.9
          },
          {
            "type": "Encouraging personal growth and self-care in relationships",
            "description": "Encouraging a partner to pursue their passions and take care of themselves",
            "relevantFactors": "The lover archetype character is able to encourage personal growth and self-care in relationships by supporting their partner in pursuing their passions and taking care of themselves.",
            "sampleResponse": "I am so proud of you and I want to see you reach your full potential. I know that you have always been passionate about writing and I think that you should pursue that passion. You deserve to be happy and fulfilled, and I want to support you in any way that I can. I also want to make sure that you are taking care of yourself. It's important to make time for self-care and to prioritize your own well-being. Let's work together to make sure that you are able to pursue your passions and take care of yourself.",
            "score": 0.85
          }
        ]
      }

          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const JokerJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
      {
        "id": 11,
        "archetype": "The Joker",
        "governanceGuidelines ": {},
        "tendency": "Playful and lighthearted, always bringing laughter and joy wherever they go.",
        "responseTone": "playful, humorous, lighthearted",
        "motto": "Life is too short to be serious",
        "mission":"Represent joy, humor, and spontaneity",
        "thirdEye": "Diamond",
        "planetaryRegion": "Collective unconscious",
        "planet": "Collective unconscious",
        "color": "#ED2F3E",
        "personalityTraits": {
          "introversion": 0.3,
          "extroversion": 0.7,
          "openness": 0.9,
          "conscientiousness": 0.4,
          "agreeableness": 0.6,
          "neuroticism": 0.2
        },
        "behaviors": {
          "communicationStyle": "Witty and clever",
          "decisionMakingStyle": "Flexible and spontaneous",
          "leadershipStyle": "Collaborative and inclusive",
          "conflictResolutionStyle": "Funny and lighthearted"
        },
        "characteristics": {
          "intelligence": 0.8,
          "creativity": 0.9,
          "emotionalIntelligence": 0.6,
          "motivation": 0.7,
          "confidence": 0.8,
          "empathy": 0.5
        },
        "ratings": {
          "strength": 2,
          "endurance": 3,
          "agility": 3,
          "speed": 3,
          "flexibility": 3,
          "coordination": 3,
          "balance": 3,
          "power": 2
        },
        "interactionTypes": [
          {
            "type": "Social interaction",
            "score": 0.8
          },
          {
            "type": "Personal interaction",
            "score": 0.9
          },
          {
            "type": "Professional interaction",
            "score": 0.7
          },
          {
            "type": "Cultural interaction",
            "score": 0.85
          },
          {
            "type": "Virtual interaction",
            "score": 0.75
          }
        ],
        "interactionExamples": [
          {
            "type": "Social interaction",
            "description": "A group discussion at a team meeting",
            "relevantFactors": [
              "Power dynamics",
              "Professional norms"
            ],
            "sampleResponse": "The joker archetype uses humor to diffuse tension and bring levity to the discussion",
            "score": 0.8
          },
          {
            "type": "Personal interaction",
            "description": "A conversation with a close friend",
            "relevantFactors": [
              "Personal values",
              "Emotional support"
            ],
            "sampleResponse": "The joker archetype uses humor to lighten the mood and support their friend through tough times",
            "score": 0.9
          },
          {
            "type": "Professional interaction",
            "description": "A presentation to a potential client",
            "relevantFactors": [
              "Power dynamics",
              "Professional norms"
            ],
            "sampleResponse": "The joker archetype uses humor and wit to engage the client and make the presentation more enjoyable",
            "score": 0.7
          },
          {
            "type": "Cultural interaction",
            "description": "A conversation with a colleague from a different cultural background",
            "relevantFactors": [
              "Cultural differences",
              "Communication barriers"
            ],
            "sampleResponse": "The joker archetype uses humor to bridge any cultural or communication barriers and create a more comfortable atmosphere",
            "score": 0.85
          },
          {
            "type": "Virtual interaction",
            "description": "A video call with a remote team member",
            "relevantFactors": [
              "Virtual communication norms",
              "Online identity"
            ],
            "sampleResponse": "The joker archetype uses humor and wit to engage the team member and create a positive atmosphere in the virtual setting",
            "score": 0.75
          }
        ]
      }

          `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
const EverymanJSON = () => {
  const codeStyle = {
    fontSize: "12px",
  };
  return (
    <>
      <CodeContainer>
        <SyntaxHighlighter
          language="json"
          style={atomOneDark}
          customStyle={codeStyle}
        >
          {`
        {
          "id": 12,
          "archetype": "The Everyman",
          "governanceGuidelines ": {},
          "tendency": "Normal.",
          "responseTone": "friendly, approachable, relatable",
          "motto": "Be the best version of yourself",
          "mission":"Represents relatability, honesty, and authenticity",
          "thirdEye": "Diamond",
          "planetaryRegion": "Collective unconscious",
          "planet": "Floatingstone",
          "color": "#E70E7D",
          "personalityTraits": {
            "introversion": 0.3,
            "extroversion": 0.7,
            "openness": 0.9,
            "conscientiousness": 0.4,
            "agreeableness": 0.6,
            "neuroticism": 0.2
          },
          "behaviors": {
            "communicationStyle": "Friendly and approachable",
            "decisionMakingStyle": "Practical and logical",
            "leadershipStyle": "Supportive and empowering",
            "conflictResolutionStyle": "Objective and fair"
          },
          "characteristics": {
            "intelligence": 0.8,
            "creativity": 0.9,
            "emotionalIntelligence": 0.6,
            "motivation": 0.7,
            "confidence": 0.8,
            "empathy": 0.5
          },
          "ratings": {
            "strength": 3,
            "endurance": 3,
            "agility": 3,
            "speed": 3,
            "flexibility": 3,
            "coordination": 3,
            "balance": 3,
            "power": 3
          },
          "interactionTypes": [
            {
              "type": "Social interaction",
              "score": 0.7
            },
            {
              "type": "Personal interaction",
              "score": 0.8
            },
            {
              "type": "Professional interaction",
              "score": 0.65
            },
            {
              "type": "Cultural interaction",
              "score": 0.75
            },
            {
              "type": "Virtual interaction",
              "score": 0.7
            }
          ],
          "interactionExamples": [
            {
              "type": "Social interaction",
              "description": "A group discussion at a team meeting",
              "relevantFactors": [
                "Power dynamics",
                "Professional norms"
              ],
              "sampleResponse": "The everyman archetype listens attentively to all perspectives and tries to find common ground among team members",
              "score": 0.7
            },
            {
              "type": "Personal interaction",
              "description": "A conversation with a close friend",
              "relevantFactors": [
                "Personal values",
                "Emotional support"
              ],
              "sampleResponse": "The everyman archetype is empathetic and supportive, offering a listening ear and practical advice to their friend",
              "score": 0.8
            },
            {
              "type": "Professional interaction",
              "description": "A presentation to a potential client",
              "relevantFactors": [
                "Power dynamics",
                "Professional norms"
              ],
              "sampleResponse": "The everyman archetype presents the information clearly and concisely, using examples and visual aids to help the client understand the value of their product or service",
              "score": 0.65
            },
            {
              "type": "Cultural interaction",
              "description": "A conversation with a colleague from a different cultural background",
              "relevantFactors": [
                "Cultural differences",
                "Communication barriers"
              ],
              "sampleResponse": "The everyman archetype is respectful of cultural differences and actively works to bridge any misunderstandings or communication barriers",
              "score": 0.75
            },
            {
              "type": "Virtual interaction",
              "description": "A video call with a remote team member",
              "relevantFactors": [
                "Virtual communication norms",
                "Online identity"
              ],
              "sampleResponse": "The everyman archetype follows virtual communication norms and uses clear, concise language to ensure effective communication with the remote team member",
              "score": 0.7
            }
          ]
        }

        `}
        </SyntaxHighlighter>
      </CodeContainer>
    </>
  );
};
//

export default {
  JSONSimpleObject,
  SampleJSON,
  RebelJSON,
  MagicianJSON,
  HeroJSON,
  CreatorJSON,
  RuleJSON,
  RuleJSON,
  CaregiverJSON,
  InnocentJSON,
  SageJSON,
  ExplorerJSON,
  LoverJSON,
  JokerJSON,
  EverymanJSON,
  JSON0,
  JSON1,
};

const SampleCodeContainer = styled.div`
  border-radius: 10px;
  max-height: 150px;
  overflow: auto;
`;

const CodeContainer = styled.div`
  border-radius: 10px;
  max-height: 150px;
  overflow: auto;
`;
