import React from "react";
import "./Slider.css";
// import leftArrow from "./icons/left-arrow.svg";
// import rightArrow from "./icons/right-arrow.svg";

// import leftArrow from "./icons/left-arrow-head-white.svg";
// import rightArrow from "./icons/right-arrow-head-white.svg";

import leftArrow from "./icons/left-arrow-head-black.svg";
import rightArrow from "./icons/right-arrow-head-black.svg";

export default function BtnSlider({ direction, moveSlide }) {
  console.log(direction, moveSlide);
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} />
    </button>
  );
}
