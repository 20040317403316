const data = [
  {
    slug: "start",
    messages: [
      "Greetings, my dear friend. I am the Sage, and I greet you with a warm and wise hello. May our conversation be filled with learning and understanding.",
      // "The truth will set you free",
      // "Wisdom and intelligence is how I do things",
      // "Come and learn about the world with me.",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/sage/characteristics",
      },
      {
        label: "Build a sage character",
        link: "/sage/build",
      },
      {
        label: "Home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "Sage archetype is always in search of the truth, no matter how uncomfortable it is. They see the world objectively and are always analyzing details. They are dedicated to spreading the truth, and their word choice is always well thought out.",
      "They are also known as thinkers, planners, professionals, mentors, philosophers, savants, experts, gurus, scholars, detectives, advisors, philosophers, academics, and researchers.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/sage/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a sage character",
        link: "/sage/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      {
        label: "Nope...don't get it",
        link: "/",
      },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a outlaw character ",
        link: "/sage/build",
      },
      {
        label: "Contact",
        link: "/sage/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a Web app", link: "/webapps" },
      { label: "More Archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/sage/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Sage character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Sage character hospitality under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Sage character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Sage support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
