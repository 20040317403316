import React from "react";
import { BsEye } from "react-icons/bs";
import { GiNoseSide, GiSoundWaves } from "react-icons/gi";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  /* padding: 2rem 0rem 0rem 0rem; */
  /* padding-top: 100px; */
  /* background-color: #010606; */
  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  text-align: center;
  background-color: #45fe47;
  color: #000000;
  height: 280px;
  width: 350px;
  margin: 2rem 1rem 1rem 1rem;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    height: 180px;
    /* margin: 1rem 3rem 1rem 3rem; */
    width: auto;
  }
  @media screen and (max-width: 768px) {
    height: 200px;
    /* margin: 1rem 3rem 1rem 3rem; */
  }
  @media screen and (max-width: 480px) {
    height: 260px;
    /* margin: 1rem 3rem 1rem 3rem; */
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.div`
  padding: 1rem 0rem 1rem 0rem;
`;

const TheSensesCard = () => {
  return (
    <>
      {" "}
      <Container>
        <Stack>
          <Content>
            <Img>
              <BsEye color={"#000000"} size={56} />
              {/* Sight */}
            </Img>
            Discover the art of visual storytelling and bring your surroundings
            to life with immersive visuals that ignite your imagination and
            stimulate your creativity.
          </Content>
        </Stack>
        <Stack>
          <Content>
            <Img>
              <GiSoundWaves color={"#000000"} size={56} />
              {/* <GiSoundWaves color={"#44ff46"} size={56} /> */}
              {/* Sound */}
            </Img>
            Elevate your environment and cultivate a sense of peace and
            tranquility with the soothing sounds of nature, designed to enhance
            your mood and reduce stress.
          </Content>
        </Stack>
        <Stack>
          <Content>
            <Img>
              <GiNoseSide color={"#000000"} size={56} />
            </Img>
            Immerse yourself in a world of scents that transport you to another
            time and place, evoke powerful emotions, and enhance your overall
            well-being.
          </Content>
        </Stack>
      </Container>
    </>
  );
};

export default TheSensesCard;
