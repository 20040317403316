import React from "react";
import { Button, ButtonWhiteBLK } from "../ButtonElement";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import BlkNWhiteBeachWalking from "../../images/videos/pexels-life-on-super-2759444-1920x1080-18fps.mp4";
import FamWalkintToHorizon from "../../images/videos/pexels-pavel-danilyuk-4812264-3840x2160-30fps.mp4";
import DinnerPartyFam from "../../images/videos/DinnerPartyFam.mp4";

const RecapAI4saleSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  description2,
  p1,
  p2,
  p3,
  buttonLabel,
  buttonLabel1,
  img,
  vid,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Fade bottom>
                  <TopLine>{topLine}</TopLine>
                </Fade>
                <Fade bottom>
                  <Heading lightText={lightText}>{headline}</Heading>
                </Fade>
                <Fade bottom>
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                </Fade>
                <Fade bottom>
                  <BtnWrapLink>
                    <BtnWrap>
                      <ButtonWhiteBLK
                        to="recapai"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        primary={primary ? 1 : 0}
                        offset={-80}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </ButtonWhiteBLK>
                    </BtnWrap>
                  </BtnWrapLink>
                </Fade>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
  // console.log(<Img />);
};

export default RecapAI4saleSection;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SecondButton = styled.a`
  /* margin: 0px 0px 0px 0px; */
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  background: ${({ primary }) => (primary ? "#44FF46" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#3DDF3F")};
  }
`;

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  padding: 100px 0px 100px 0px;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* padding: 0 24px; */
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0px 20px 0px 20px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #44ff46;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const BtnWrapLink = styled(Link)`
  text-decoration: none;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  /* border-radius: 10px; */
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
