const data = [
  {
    slug: "start",
    messages: [
      "Hello! I am the Hero, here to protect and serve. How may I assist you?",
      // "Where there is a will, there is a way",
      // "And I'm here to save the day!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/hero/characteristics",
      },
      {
        label: "Build a hero character",
        link: "/hero/build",
      },
      {
        label: "Home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "The Hero strives to maintain justice and fight against wickedness. The ability of the hero to stay true to themselves is ultimately what makes them heroic. The core desire of the Hero is to prove his or her worth through courageous acts.",
      "Also known as the crusader, the rescuer, the superhero, the soldier, the slayer of evil, the winner, champion, conquering hero, conqueror, knight, paladin, person of the hour, protector, savior, and victor. ",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/hero/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a hero character",
        link: "/hero/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a hero character",
        link: "/Hero/build",
      },
      {
        label: "Contact",
        link: "/hero/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Back to main page", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/hero/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "companionship",
    messages: ["Hero character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Hero character hospitality under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Hero character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Hero character support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
