import React from "react";
import styled from "styled-components";
import { HomeButton } from "./RecapAILearnMore";
import Timer from "../components/Timer";

const PrivacyPolicyContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding: 80px 80px 20px 80px;
  background-color: #f8f9fa;
  color: #333;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  @media screen and (max-width: 450px) {
    padding: 80px 20px 20px 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 1em;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const Policy = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
`;

const MainHeader = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  text-transform: uppercase;
`;

const Header = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #666;
`;

const PHeader = styled.p`
  /* text-align: center; */
  font-size: 20px;
  font-weight: 500;
  color: #666;
  margin: 30px 0px 10px 0px;
`;

const UnderlinedText = styled.span`
  border-bottom: 1px solid #666; // Custom underline
  padding-bottom: 5px; // Adjust this value to control the space between text and underline
`;

const SubHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #666;
  margin-top: 30px;
`;

const List = styled.ul`
  margin-left: 20px;
  font-size: 14px;
  line-height: 1.5;
  color: #777;
`;
const Link = styled.a`
  color: #0077cc;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const specifiedDate = "2024-01-17";

const PrivacyPolicy = () => {
  return (
    <>
      <HomeButton to="/">Home</HomeButton>
      <PrivacyPolicyContainer>
        <MainHeader> Archetype API</MainHeader>
        <Header>Privacy Policy</Header>
        <Timer date={specifiedDate} />
        <Section>
          <Paragraph>
            <Policy>
              <p>
                Welcome to the{" "}
                <Link
                  href="https://github.com/jrq3rq/ArchetypeAPI"
                  target="_blank"
                >
                  ArchetypeAPI
                </Link>
                . We are committed to protecting your privacy and ensuring you
                have a positive experience on our Archetype Data Gateway. This
                Privacy Policy outlines our practices regarding the collection,
                use, and disclosure of information through{" "}
                <Link href="https://chat.openai.com/gpts" target="_blank">
                  ChatGPT
                </Link>{" "}
                and the{" "}
                <Link
                  href="https://openai.com/blog/introducing-the-gpt-store"
                  target="_blank"
                >
                  GPT Store
                </Link>
                .
              </p>
              <PHeader>
                Information We Collect
                {/* <UnderlinedText>Information We Collect</UnderlinedText> */}
              </PHeader>

              <List>
                <li>
                  <b>User-Provided Information:</b> Currently, our API is
                  designed exclusively for GET requests and does not require or
                  collect any personal information from its users. This includes
                  the absence of data collection such as names, email addresses,
                  or any other personally identifiable information.
                </li>
                <li>
                  <b>Usage Data:</b> We do not collect usage data such as IP
                  addresses, access times, or request types. Our API's sole
                  function is to provide archetype data without tracking user
                  activity or data.
                </li>
              </List>
              <PHeader>How We Use Your Information</PHeader>
              <List>
                <li>
                  How We Use Your Information Our API is built to serve
                  archetype data through GET requests without collecting or
                  using any personal information from our users.
                </li>
                <li>
                  We aim to maintain the highest standards of privacy and data
                  security in line with industry best practices.
                </li>
              </List>
              <PHeader>Sharing of Information</PHeader>

              <List>
                <li>
                  Since we do not collect personal information, there is no
                  sharing of such data with third parties.
                </li>
                <li>
                  Any future changes to this policy, especially concerning data
                  collection and sharing, will be communicated explicitly and
                  will only be implemented with clear user consent.
                </li>
              </List>
              <PHeader>Data Security</PHeader>
              <List>
                <li>
                  We are committed to protecting our API and its users from
                  unauthorized access, alteration, disclosure, or destruction of
                  any data we might collect in the future.
                </li>
                <li>
                  Our security measures are designed to ensure the integrity and
                  confidentiality of all data.
                </li>
              </List>
              <PHeader>International Data Transfers</PHeader>

              <List>
                <li>
                  As of now, there are no international data transfers to be
                  concerned about, given our policy of not collecting personal
                  data.
                </li>
              </List>
              <PHeader>Your Rights</PHeader>
              <List>
                <li>
                  Although we currently do not collect personal data, we
                  recognize the importance of user rights in data privacy
                  matters. Should our policy change, we will ensure mechanisms
                  for accessing, updating, or deleting personal information.
                </li>
              </List>
              <PHeader>Changes to This Privacy Policy</PHeader>

              <List>
                <li>
                  This Privacy Policy may be updated over time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. We will notify you of any significant
                  changes by updating the policy on our website.
                </li>
              </List>
              <PHeader>Contact Us</PHeader>
              <List>
                <li>
                  To address any inquiries or concerns you may have about our
                  Privacy Policy, we warmly invite you to schedule a
                  consultation with our team. For your convenience, please use
                  the following Calendly link to{" "}
                  <Link
                    href="https://calendly.com/studio-voice2fly/30min"
                    target="_blank"
                  >
                    'Arrange a Meeting'
                  </Link>
                  , allowing you to select a time that best suits your schedule
                  for our appointment.
                </li>

                {/* <li>
                  Your access and use of our API signify your understanding and
                  acceptance of the terms outlined in this policy.
                </li> */}
              </List>
            </Policy>
          </Paragraph>
        </Section>

        {/* Add more sections for different parts of the policy */}
      </PrivacyPolicyContainer>
    </>
  );
};

export default PrivacyPolicy;
