import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 700px; // Fixed maximum height
  min-height: 500px; // Minimum height to ensure it's not too small on larger screens
  padding: 2rem;
  box-sizing: border-box;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  color: #e0e0e0;
  border: 1px solid #41ff46;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px; // Maximum width to prevent excessive stretching
  /* margin: 0 auto; // Center the container */
  margin: 1.2em;

  @media (max-width: 1200px) {
    max-height: 90vh; // Use viewport units for smaller screens
    min-height: 70vh;
  }

  @media (max-width: 790px) {
    /* margin: 1em; */
    width: calc(100% - 2em);
    padding: 1rem;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem; // Add some padding for the scrollbar
  padding-left: 1rem; // Add some padding for the scrollbar
`;

const Title = styled.h1`
  font-size: clamp(1rem, 5vw, 2.5rem);
  font-weight: bold;
  margin-bottom: 5vh;
  color: #ffffff;
  text-align: center;
  @media (max-width: 790px) {
    margin-bottom: 3vh;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-right: 0; // Remove any right padding
  margin-right: 0; // Remove any right margin
  /* border: 1px solid #ffffff; */
  /* border: 1px solid #41ff46; */
  border-radius: 8px;
  @media (max-width: 390px) {
    margin-left: -3vw;
    margin-right: -3vw;
    width: calc(100% + 6vw);
  }
`;

const Td = styled.td`
  padding: 2vh 2vw;
  border-top: 1px solid rgba(51, 51, 51, 0.5);
  font-size: clamp(0.8rem, 2vw, 1rem);
  @media (max-width: 390px) {
    padding: 1.5vh 3vw;
  }
`;
const ActionTd = styled(Td)`
  padding-right: 0; // Remove right padding for the last column
  width: 1%; // This makes the column as narrow as possible
  white-space: nowrap; // Prevents the button from wrapping
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: rgba(30, 30, 30, 0.7);
  border-radius: 8px;
  overflow: hidden;
  table-layout: fixed; // This ensures the table respects the width of its container
`;

const Th = styled.th`
  background-color: rgba(44, 44, 44, 0.8);
  color: #ffffff;
  padding: 2vh 2vw;
  text-align: left;
  font-size: clamp(0.8rem, 2vw, 1rem);

  @media (max-width: 390px) {
    padding: 1.5vh 3vw;
  }
`;

const Button = styled.button`
  background-color: #44fd47;
  border: none;
  color: #000000;
  padding: 1vh 2vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  margin: 1vh 1vw;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.1s;

  &:hover {
    background-color: #3ae63d;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vw;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  background-color: rgba(30, 30, 30, 1);
  border: 2px solid ${(props) => props.color || "#41ff46"};
  color: #e0e0e0;
  padding: 5vh 5vw;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const ModalTitle = styled.h2`
  text-transform: uppercase;
  color: ${(props) =>
    props.color || "#41ff46"}; // Use the provided color or default to white
  margin-bottom: 3vh;
  text-align: center;
  font-size: clamp(1.2rem, 4vw, 1.8rem);
`;
const ModalSection = styled.div`
  margin-bottom: 3vh;
`;

const SectionTitle = styled.h3`
  color: #ffffff;
  /* color: #44fd47; */
  margin-bottom: 1vh;
  font-size: clamp(1rem, 3vw, 1.4rem);
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 1vh;
  position: relative;
  padding-left: 1.5em;
  font-size: clamp(0.8rem, 2vw, 1rem);

  &:before {
    content: "•";
    color: ${(props) => props.color || "#44fd47"};
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.2em;
  }
`;

const InfoItem = styled.p`
  margin-bottom: 1vh;
  font-size: clamp(0.8rem, 2vw, 1rem);
`;

const ColorWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const ColorLabel = styled.strong`
  margin-right: 0.2em; // Add space after "Color:"
`;

const ColorValue = styled.span`
  margin-right: 0em; // Add space before the color square
`;

const ColorSquare = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  vertical-align: middle;
  border: 1px solid #000;
`;
// const TableRow = styled.tr`
//   @media (max-width: 480px) {
//     display: flex;
//     flex-wrap: wrap;
//   }
// `;
const ArchetypeUtilizationChecklist = () => {
  const [archetypes, setArchetypes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedArchetype, setSelectedArchetype] = useState(null);
  const containerRef = useRef(null);
  const modalContentRef = useRef(null);
  const closeModal = () => {
    setModalVisible(false);
    setSelectedArchetype(null);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    fetchArchetypes();
  }, []);

  useEffect(() => {
    if (modalVisible && containerRef.current) {
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();
      const scrollableParent = getScrollableParent(container);

      if (scrollableParent) {
        const scrollTop = scrollableParent.scrollTop;
        const scrollableParentHeight = scrollableParent.clientHeight;
        const centerPosition =
          scrollTop +
          (containerRect.top +
            containerRect.height / 2 -
            // scrollableParentHeight / 2);
            scrollableParentHeight * 0.6); // Change 0.5 to a smaller fraction
        scrollableParent.scrollTo({
          top: centerPosition,
          behavior: "smooth",
        });
      }
    }
  }, [modalVisible]);

  const getScrollableParent = (element) => {
    if (!element) {
      return document.documentElement;
    }

    if (element.scrollHeight > element.clientHeight) {
      return element;
    } else {
      return getScrollableParent(element.parentElement);
    }
  };

  const fetchArchetypes = async () => {
    try {
      const response = await fetch(
        "https://us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes"
      );
      const data = await response.json();
      setArchetypes(data);
    } catch (error) {
      console.error("Error fetching archetypes:", error);
    }
  };

  const fetchArchetypeDetails = async (name) => {
    try {
      const response = await fetch(
        `https://us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes/${name.toLowerCase()}`
      );
      const data = await response.json();

      // Fetch mythology data
      const mythologyResponse = await fetch(
        `https://us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes/${name.toLowerCase()}/mythology`
      );
      const mythologyData = await mythologyResponse.json();

      // Combine the data
      setSelectedArchetype({ ...data, mythology: mythologyData });
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching archetype details:", error);
    }
  };

  return (
    <Container ref={containerRef}>
      <Title>XII Archetypes Compendium</Title>
      <ContentWrapper>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <Th>Order</Th>
                <Th>Name</Th>
                <Th>Motto</Th>
                <Th>Actions</Th>
              </tr>
            </thead>
            <tbody>
              {archetypes.map((archetype) => (
                <tr key={archetype.id}>
                  <Td>{archetype.order}</Td>
                  <Td>{archetype.name}</Td>
                  <Td>{archetype.motto}</Td>
                  <ActionTd>
                    <Button
                      onClick={() => fetchArchetypeDetails(archetype.name)}
                    >
                      View
                    </Button>
                  </ActionTd>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </ContentWrapper>

      {modalVisible && selectedArchetype && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent
            ref={modalContentRef}
            onClick={handleModalClick}
            color={selectedArchetype.color}
          >
            <ModalTitle color={selectedArchetype.color}>
              {selectedArchetype.name}
            </ModalTitle>
            <ModalSection>
              <InfoItem>
                <strong>Motto:</strong> {selectedArchetype.motto}
              </InfoItem>
              <InfoItem>
                <ColorWrapper>
                  <ColorLabel>Color:</ColorLabel>
                  <ColorValue>{selectedArchetype.color}</ColorValue>
                  <ColorSquare
                    style={{ backgroundColor: selectedArchetype.color }}
                  />
                </ColorWrapper>
              </InfoItem>
              <InfoItem>
                <strong>Planet:</strong> {selectedArchetype.planet}
              </InfoItem>
              <InfoItem>
                <strong>Third Eye:</strong> {selectedArchetype.thirdEye}
              </InfoItem>
            </ModalSection>
            <ModalSection>
              <SectionTitle>Mythology</SectionTitle>
              <List>
                {selectedArchetype.mythology &&
                  selectedArchetype.mythology.map((myth, index) => (
                    <ListItem key={index} color={selectedArchetype.color}>
                      <strong>{myth.name}:</strong> {myth.description}
                    </ListItem>
                  ))}
              </List>
            </ModalSection>
            <ModalSection>
              <SectionTitle>Traits</SectionTitle>
              <List>
                {selectedArchetype.traits.map((trait, index) => (
                  <ListItem key={index} color={selectedArchetype.color}>
                    {trait}
                  </ListItem>
                ))}
              </List>
            </ModalSection>
            <ModalSection>
              <SectionTitle>Motivations</SectionTitle>
              <List>
                {selectedArchetype.motivations.map((motivation, index) => (
                  <ListItem key={index} color={selectedArchetype.color}>
                    {motivation}
                  </ListItem>
                ))}
              </List>
            </ModalSection>
            <ModalSection>
              <SectionTitle>Behaviors</SectionTitle>
              <List>
                {selectedArchetype.behaviors.map((behavior, index) => (
                  <ListItem key={index} color={selectedArchetype.color}>
                    {behavior}
                  </ListItem>
                ))}
              </List>
            </ModalSection>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default ArchetypeUtilizationChecklist;
