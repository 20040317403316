// Write your code here
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { TiChevronLeft } from "react-icons/ti";
import { TiWeatherNight, TiWeatherSunny } from "react-icons/ti";

import ThemeContext from "../../mint-context/ThemeContext";

import "./index.css";
import styled from "styled-components";

const Navbar = () => (
  <ThemeContext.Consumer>
    {(value) => {
      const { isDarkTheme, toggleTheme } = value;
      return (
        <>
          {!isDarkTheme ? (
            <div className="nav-bar-container-light">
              <Link to="/">
                <TiChevronLeft size={42} color="#000" />
              </Link>
              {/* <MiddleItems>
                <ListItems>
                  <LinkLight>Home</LinkLight>
                </ListItems>
              </MiddleItems> */}
              <button
                type="button"
                className="theme-button"
                testid="theme"
                onClick={toggleTheme}
              >
                <TiWeatherNight size={42} color="#000" />
              </button>
            </div>
          ) : (
            <div className="nav-bar-container-dark">
              <Link to="/">
                <TiChevronLeft size={42} color="#fff" />
              </Link>
              <button
                type="button"
                className="theme-button"
                testid="theme"
                onClick={toggleTheme}
              >
                <TiWeatherSunny size={42} color="#fff" />
              </button>
            </div>
          )}
        </>
      );
    }}
  </ThemeContext.Consumer>
);

export default Navbar;

const MiddleItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0px;
`;
const ListItems = styled.div`
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;
`;
const LinkLight = styled.div`
  color: #171f46;
  text-decoration: none;
`;
