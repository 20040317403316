import test from "../../images/2flycharacter.svg";
import test1 from "../../images/2flycharacter2.svg";
// import currentIMG from "../../images/updated-2fly-sketch.svg";
import currentIMG from "../../images/crazy-2fly-sketch.svg";
import MainIMG from "../../images/updated-2fly-character2022.svg";
import New_tv_2flyGreen from "../../images/new_tv_2flyGreen.svg";

export const ArtData = {
  id: "art",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "adventurer",
  topLine2:
    "01001001 01110100 00100111 01110011 00100000 01100011 01101111 01101111 01101100 00101100 00100000 01001001 00100111 01101100 01101100 00100000 01100100 01110010 01101001 01110110 01100101 00100001",
  // headline: "If you can imagine it, it can be done!",
  // description: "If you can imagine it, you can achieve it.",
  // description: "I value resourcefulness, grit, insight, and commitment.",
  // description: "The Mission",
  description: "",
  description1:
    "At Studio Voice2fly, our mission is to inspire and enable innovation. We believe that every challenge presents a learning opportunity and a chance to sharpen our problem-solving abilities.",
  // "It is our mission to help create innovative solutions no matter the problem. I believe that nearly every situation can become an opportunity to gain knowledge and enhance our problem-solving skills.  ",
  // description2:
  //     "It is for this reason that we strive to find inventive solutions to even the most challenging problems.",
  description3:
    "Not only do we help you develop your brand and position your project strategically, but we also strive to make growing your business a truly enriching experience. Our tools and services are designed to ignite your creativity and drive your success.",
  // "In addition to helping you build your identity and position your project more effectively, we strive to enhance the experience of growing your business by offering tools and services that will spark creativity.",
  // description4:
  //   "In addition to helping you create a brand identity and position your business more effectively, we aim to enhance and boost the joy of growing your business by offering tools that encourage the discovery of new ideas.",
  buttonLabel: "Explore",
  imgStart: true,
  img: New_tv_2flyGreen,
  // img: MainIMG,
  alt: "art",
  dark: true,
  primary: true,
  darkText: false,
};
