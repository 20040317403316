import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import ScrollToTop from "../components/ScrollToTop";
import SecondaryNav from "../components/Webapps2Folder/SecondaryNav";
import { DownArrow } from "../components/ArchetypesInfo/HeroServices/HeroServicesElements";
import ReactSectionCards from "../components/Webapps2Folder/SectionCards";
import SectionDesc from "../components/Webapps2Folder/SectionDesc";
import Accordion from "../components/AccordionFolder";
import { TiStar } from "react-icons/ti";
import Footer from "../components/Footer";
import { FiChevronUp, FiArrowUpCircle } from "react-icons/fi";

import WebappsIntroSection from "../components/Webapps2Folder/SectionDesc";
import RevealCarousel from "../components/RevealCarousel";
import Videos from "../components/Webapps2Folder/Videos";
import VoiceAppsNav from "../components/Webapps2Folder/VoiceAppsNav";
import { HomeButton } from "./MemoryPalAILearnMore";
import { BackgroundImage } from "./voiceapps";
import VoicePageBG from "../images/VoicePageBG.png";

export default function Webapps2() {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected("null");
    }
    setSelected(i);
  };
  const [hover, setHover] = React.useState(false);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {/* <ContainScroll> */}

      <ScrollToTop />
      <BackgroundWrapper />
      {/* <SecondaryNav /> */}
      {/* <Container maxWidth={true} className="container" disableGutters={true}> */}

      {/* <Fade bottom duration={1500}> */}
      {/* <Space /> */}
      {/* <VoiceAppsNav /> */}
      <HomeButton to="/">Home</HomeButton>
      <ParentCarouselWrapper>
        <Container>
          {/* <HeaderTitle>PWA Business Templates & AI Chatbots</HeaderTitle> */}
          <Container1>
            {/* <Box> */}
            <Fade bottom duration={1500}>
              <ReactSectionCards />
            </Fade>
            {/* </Box> */}
          </Container1>
        </Container>
        <Wedge />
      </ParentCarouselWrapper>

      {/* <div
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          position: "fixed",
          // top: 40,
          bottom: 40,
          right: 40,
          cursor: "pointer",
        }}
      >
        {hover ? (
          <FiArrowUpCircle size={30} color={"#ffffff"} />
        ) : (
          <FiChevronUp size={30} color={"#ffffff"} />
        )}
      </div> */}
    </>
  );
}

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${VoicePageBG});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

// Main container for the carousel, positioned relative to the background
const ParentCarouselWrapper = styled.div`
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
`;

const device = {
  mobile: `(max-width: 768px)`,
  tablet: `(min-width: 769px) and (max-width: 1024px)`,
  desktop: `(min-width: 1025px)`,
};

const Space = styled.div`
  height: 100px;
  width: auto;
`;

const P = styled.p`
  color: #44fd47;
  /* color: #ffffff; */
  padding: 10px 0px 10px 0px;
`;

const Container2 = styled.div`
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  /* background-color: #ffffff; */
  border-radius: 8px;

  @media ${device.mobile} {
    max-width: 90%;
  }

  @media ${device.tablet} {
    max-width: 800px;
  }

  @media ${device.desktop} {
    max-width: 860px;
  }
`;

const Title2 = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #44fd47;
  /* color: #ffffff; */
  /* color: #2c3e50; */
  margin-bottom: 1rem;
  @media ${device.mobile} {
    font-size: 1.6rem;
  }
`;

const SubTitle2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #44fd47;
  /* color: #ffffff; */
  /* color: #34495e; */
  margin-bottom: 1rem;

  @media ${device.mobile} {
    font-size: 1.3rem;
  }
`;

const List2 = styled.ol`
  margin-left: 1.5rem;
  margin-bottom: 2rem;
`;

const ListItem2 = styled.li`
  margin-bottom: 1rem;
`;

const CodeBlock2 = styled.pre`
  /* background-color: #44fd47; */
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 1rem;
  font-family: "Courier New", Courier, monospace;
  overflow-x: auto;
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledComponent = styled.div`
  font-family: "Orbitron", sans-serif;
  font-size: 18px;
  color: #333;
  text-align: center;
`;

const VideoContainer = styled.div`
  width: 920px;
`;

const H22 = styled.div`
  padding: 0rem 0rem 0rem 0rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  /* color: #000000; */
`;

const SubHeader2 = styled.div`
  padding: 0rem 1rem 0rem 1rem;
  font-size: 0.8rem;
  /* font-weight: 600; */
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  /* color: #000000; */
`;

const SubHeader22 = styled.div`
  padding: 0rem 1rem 0rem 1rem;
  font-size: 0.8rem;
  /* font-weight: 600; */
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  /* color: #000000; */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wedge = styled.div`
  height: 1rem;
`;

const CodeContent = styled.div`
  /* color: #ffffff; */
  color: #000000;
  /* background-color: #ffffff; */
  /* background-color: #44ff46; */
  padding: 0.5rem;
`;

const List = styled.div`
  color: #ffffff;
  margin: 1rem 1rem 1rem 6rem;
  line-height: 1.5em;
  @media screen and (max-width: 720px) {
    margin: 1rem 1rem 1rem 3rem;
  }
`;

const DisplayLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  /* color: #000000; */
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #44ff46;
  }
`;
const BoldLink = styled.a`
  margin: 0rem 0.5frem 0rem 0rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  /* color: #000000; */
  /* color: #44ff46; */
  &:hover {
    transition: all 0.2s ease-in-out;
    /* color: #ffffff; */
    color: #44ff46;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PP = styled.p`
  color: #fff;
  /* color: #000; */
  /* text-align: center; */
  padding: 0rem 0rem 0rem 0.5rem;
  /* margin: 18px; */
  /* line-height: 1.5em; */
  @media screen and (max-width: 720px) {
    /* padding: 1rem 0rem 1rem 0rem;
    margin: 0px; */
  }
`;

const P2 = styled.p`
  color: #fff;
  /* color: #000; */
  /* text-align: center; */
  padding: 0.5rem 4rem 1rem 4rem;
  /* margin: 18px; */
  line-height: 1.5em;
  @media screen and (max-width: 720px) {
    padding: 1rem 0rem 1rem 0rem;
    margin: 0px;
  }
`;

const P22 = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* color: #000; */
  /* text-align: center; */
  padding: 2rem 1rem 1rem 1rem;
  /* margin: 18px; */
  line-height: 1.5em;
  @media screen and (max-width: 720px) {
    padding: 1rem 0rem 1rem 0rem;
    margin: 0px;
  }
`;
const Container1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 720px) {
    margin-top: 70px;
  }
`;
const TitleHeader = styled.div`
  /* color: #fff; */
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 4rem 1rem 4rem;
  @media screen and (max-width: 720px) {
    padding: 1rem 0rem 1rem 0rem;
    margin: 0px;
  }
`;
const MainTitle = styled.p`
  /* color: #fff; */
  /* color: #000; */
  text-transform: uppercase;
  text-align: center;
  padding: 1rem 4rem 0rem 4rem;
  @media screen and (max-width: 720px) {
    /* padding: 1rem 2rem 0rem 2rem; */
    padding: 1rem 0rem 0rem 0rem;
    /* margin: 0px; */
  }
`;
const SubTitle = styled.p`
  /* color: #fff; */
  font-weight: bold;
  /* color: #000; */

  text-align: center;
  padding: 1rem 4rem 1rem 4rem;
  @media screen and (max-width: 720px) {
    padding: 1rem 0rem 0rem 0rem;
    /* padding: 1rem 2rem 0rem 2rem; */

    /* margin: 0px; */
  }
`;

const Box = styled.div`
  /* margin: 1rem 6rem 1rem 6rem; */
  /* background-color: #fff; */
  /* background-color: #44fd47; */
  height: auto;
  /* max-width: 1000px; */
  @media screen and (max-width: 794px) {
    margin: 1rem 0rem 1rem 0rem;
  }
`;

const Text = styled.div`
  color: #fff;
  /* color: #000; */
  /* text-align: center; */
  padding: 0rem 4rem 0rem 4rem;
  margin: 18px;
  @media screen and (max-width: 720px) {
    padding: 0rem 2rem 1rem 2rem;
    margin: 0px;
  }
`;

const Child2 = styled.p`
  text-align: left;
  /* color: #fff; */
  line-height: 2rem;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  padding: 35px 35px 0px 35px;
  width: 50%;
  margin: -100px 0px 0px 0px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Child3 = styled.p`
  text-align: left;
  /* color: #fff; */
  line-height: 2rem;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  padding: 35px 35px 0px 35px;
  width: 50%;
  margin: -100px 0px 0px 0px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    margin: 0px 0px 0px 0px;
  }
`;

const P1 = styled.div`
  font-size: 1rem;
  @media screen and (max-width: 720px) {
  }
`;

const PageLink = styled(Link)`
  text-decoration: none;
  /* color: #44ff46; */
  color: #fff;
  /* color: #000000; */
  font-weight: bold;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  padding: 2rem 0rem 0rem 0rem;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
`;
