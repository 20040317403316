import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import "../components/ArchetypesInfo/Typography.css";
import JungianCards from "../components/JungianCards/JungianCards";
// import Bar4th from "../JungianCards/images/Bar4th.png";
import { HomeButton } from "./MemoryPalAILearnMore";

import BackgroundImage from "../images/VoicePageBG.png";
import AIUtilizationChecklist from "../components/AIUtilizationChecklist";

function VoiceappsPage(props) {
  return (
    <>
      <BackgroundWrapper />
      <Container>
        <HomeButton to="/">Home</HomeButton>
        <ContainScroll>
          <MainContainer2>
            <MainHeader>
              <FadeIn style={{ animationDelay: "0.6s" }}>
                <PLINK>
                  <ButtonBreakdown
                    href="//jamesrrs-projects-app.web.app/character-creation-tool"
                    target="_blank"
                  >
                    Detailed Overview
                  </ButtonBreakdown>
                </PLINK>
                {/* <PLINK2>
                  <StyledButtonLink
                    href="https://us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View API
                  </StyledButtonLink>
                </PLINK2> */}
                <P22Top>
                  Explore 12 limited-functionality interactive chatbot demos
                  representing Jungian archetypes. These demos provide hands-on
                  insight into each archetype's personality, useful for writers,
                  game developers, and educators.
                </P22Top>
                <P22>
                  {" "}
                  <b>ArchetypeAPI</b> is a platform that enhances character
                  creation using Carl Jung's archetypal theory. It provides a
                  range of Jungian archetype templates and integrates with the
                  analytical capabilities of{" "}
                  <a
                    href="https://github.com/jrq3rq/beta-bigfive-test-MindPulse-150"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#44FD47",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Mind<i>Pulse</i>-150
                  </a>
                  . This combination offers various customization options,
                  allowing users to explore human behavior, tailor character
                  traits, and develop narrative arcs with the provided tools.{" "}
                  <b>ArchetypeAPI</b> supports storytelling, psychological
                  analysis, and character development.
                </P22>
                <P22>
                  Explore further by accessing the code on{" "}
                  <a
                    href="https://github.com/jrq3rq/archetypeapi"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#44FD47",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    GitHub
                  </a>
                  , where you can discover the full potential of ArchetypeAPI.
                  Join a community of creators, thinkers, and innovators
                  dedicated to advancing character development and narrative
                  enrichment.
                </P22>
              </FadeIn>
              <FadeIn style={{ animationDelay: "0.8s" }}>
                <P22>
                  Join us in exploring this innovative concept to enhance the
                  depth of your narratives and bring your characters to life in
                  new ways.
                </P22>
              </FadeIn>
              <FadeIn style={{ animationDelay: "0.2s" }}>
                <H2>The Archetype Academy </H2>
              </FadeIn>
              <FadeIn style={{ animationDelay: "0.4s" }}>
                <SubHeader> v0.1.0-alpha</SubHeader>
                {/* Character Assembly */}
              </FadeIn>
            </MainHeader>
          </MainContainer2>
          <TopMainContainer2>
            <Content2>
              <JungianCards />
            </Content2>
          </TopMainContainer2>
          <FadeIn style={{ animationDelay: "0.2s" }}>
            <H2>Builder</H2>
          </FadeIn>
          <FadeIn style={{ animationDelay: "0.4s" }}>
            <SubHeader> Versions</SubHeader>
          </FadeIn>
          <BetaLink>
            <BetaLinkMain>
              <P22Top>
                <BetaLinkButton href="//carl-xii.web.app/" target="_blank">
                  0.2.0-alpha
                </BetaLinkButton>
              </P22Top>
            </BetaLinkMain>
          </BetaLink>
          <BetaLink>
            <BetaLinkMain>
              <P22Top>
                <BetaLinkButton
                  href="//archetype-academy-nexus.web.app/library"
                  target="_blank"
                >
                  0.3.0-alpha ( Latest Version )
                </BetaLinkButton>
              </P22Top>
            </BetaLinkMain>
          </BetaLink>
        </ContainScroll>
      </Container>
    </>
  );
}

// export default withStyles(styles)(VoiceappsPage);
export default VoiceappsPage;

export const FadeIn = styled.div`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  opacity: 0;
  animation: fadeInUp 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation-delay: ${(props) => props.delay || "0s"};

  &:nth-child(1) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    animation-delay: 0.4s;
  }
  &:nth-child(5) {
    animation-delay: 0.5s;
  }
  &:nth-child(6) {
    animation-delay: 0.6s;
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    opacity: 1;
  }
`;

const BetaLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 20px 0px;
  /* padding: 0px 100px 0px 100px; */
  /* margin-top: 10px 10px 10px 10px; */
  @media screen and (max-width: 700px) {
    /* margin: 80px 10px 10px 10px; */
  }
`;

const BetaLinkMain = styled.div`
  width: 925px;
  /* background-color: #44ff46; */
  background-color: transparent;
  /* background-color: #000000; */
  /* background-color: #ffffff; */
  padding: 0rem 0rem 0rem 0rem;
  /* border-radius: 10px; */
  margin: 0rem 2rem 0rem 2rem;
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 700px) {
  }
  /* @media screen and (max-width: 500px) {
} */
`;

const BetaLinkButton = styled.a`
  width: auto;
  border-radius: 50px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #000000;
  color: #41ff46; // Button text color
  white-space: nowrap;
  padding: 10px 28px 10px 28px;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;

  &:hover {
    color: #000000; // Button text color
    background-color: #41ff46; // Color on hover
    border: 1px solid #41ff46;
  }
`;
const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  transform: translateZ(0);
  will-change: transform;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const CenterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  margin: 40px 0px 40px 0px;
  /* margin-top: 20px; */
  /* margin-bottom: 26px; */
  /* padding: 0px 28px 20px 28px; */
  @media (max-width: 700px) {
    flex-direction: column;
    margin: 40px 0px 40px 0px;
  }
`;

const ButtonDemo = styled.a`
  /* width: 250px; */
  width: auto;
  border-radius: 50px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 28px 10px 28px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
  @media (max-width: 700px) {
    /* width: 100%; */
    /* margin-top: 20px; */
    margin: 20px 0px 0px 0px;
  }
`;

const StyledButtonLink = styled.a`
  display: flex; // Use flex to center text inside
  justify-content: center; // Center content horizontally in the button
  align-items: center; // Center content vertically in the button
  border-radius: 50px;
  padding: 10px 10px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #000000;
  color: #41ff46; // Button text color
  /* color: #010606; // Button text color */
  text-decoration: none;
  /* font-weight: bold; */
  transition: background-color 0.3s ease;
  /* border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px; */
  width: auto;
  &:hover {
    color: #000000; // Button text color
    background-color: #41ff46; // Color on hover
    border: 1px solid #41ff46;
  }
`;

const ButtonBreakdown = styled.a`
  width: auto;
  border-radius: 50px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #000000;
  color: #41ff46; // Button text color
  white-space: nowrap;
  padding: 10px 28px 10px 28px;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  margin: 2em 0em 1em 0em;
  &:hover {
    color: #000000; // Button text color
    background-color: #41ff46; // Color on hover
    border: 1px solid #41ff46;
  }
`;

const Wedge = styled.div`
  height: 4rem;
`;

const bTagStyles2 = {
  // fontWeight: "bold",
  color: "#45FE47",
  // color: "#ffffff",
  // color: "#000000",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  // fontWeight: "",
  // color: "#44ff46",
};
const SubHeader = styled.div`
  padding: 0rem 1rem 2rem 1rem;
  font-size: 0.8rem;
  /* font-weight: 600; */
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  /* color: #000000; */
  @media screen and (max-width: 700px) {
    padding: 0rem 1rem 1rem 1rem;
  }
`;

const PPMain = styled.div`
  /* margin: 1rem 1rem 1rem 1rem; */
  /* text-transform: uppercase; */
  /* font-style: italic; */
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 0rem 0rem 0rem 0rem;
  @media screen and (max-width: 700px) {
    margin: 0rem 0rem 0rem 0rem;
  }

  color: #ffffff;
  /* color: #000000; */
  line-height: 1.6;
  @media screen and (max-width: 700px) {
    margin: 1rem 0rem 1rem 0rem;
  }
`;

const P22Top = styled.div`
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 0rem 0rem 0rem 0rem;
  /* color: #000000; */
  line-height: 1.6;
  /* text-align: center; */
  @media screen and (max-width: 700px) {
  }
  /* font-style: italic;
  color: #ffffff;
  letter-spacing: 0.05em;
  margin: 0rem 0rem 2rem 0rem;
  @media screen and (max-width: 700px) {

    font-size: 0.9em;
  } */
`;

const P22 = styled.div`
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 1rem 0rem 1rem 0rem;
  /* color: #000000; */
  line-height: 1.6;
  @media screen and (max-width: 700px) {
  }
  /* font-style: italic;
  color: #ffffff;
  letter-spacing: 0.05em;
  margin: 0rem 0rem 2rem 0rem;
  @media screen and (max-width: 700px) {

    font-size: 0.9em;
  } */
`;

const P222 = styled.div`
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 2rem 0rem 1rem 0rem;
  line-height: 1.6;
  @media screen and (max-width: 700px) {
  }
  /* font-style: italic;
  color: #ffffff;
  letter-spacing: 0.05em;
  margin: 0rem 0rem 2rem 0rem;
  @media screen and (max-width: 700px) {

    font-size: 0.9em;
  } */
`;

const PLINK = styled.div`
  letter-spacing: 0.05em;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  margin: 2rem 0rem 1rem 0rem;
  /* color: #000000; */
  /* line-height: 1.6; */
  @media screen and (max-width: 700px) {
  }
`;
const PLINK2 = styled.div`
  letter-spacing: 0.05em;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0rem 0rem 1rem 0rem;
  /* color: #000000; */
  /* line-height: 1.6; */
  @media screen and (max-width: 700px) {
  }
`;

const H2 = styled.div`
  padding: 1rem 0rem 0rem 0rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  /* color: #000000; */
`;

const MainContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 20px 0px;
  /* padding: 0px 100px 0px 100px; */
  /* margin-top: 10px 10px 10px 10px; */
  @media screen and (max-width: 700px) {
    /* margin: 80px 10px 10px 10px; */
  }
`;
const TopMainContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0px 100px 0px 100px; */
  /* margin-top: 10px 10px 10px 10px; */
  margin-top: 25px;
  @media screen and (max-width: 700px) {
    /* margin-top: 100px; */
    margin-top: 15px;
  }
`;

const MainHeader = styled.div`
  width: 925px;
  /* background-color: #44ff46; */
  background-color: transparent;
  /* background-color: #000000; */
  /* background-color: #ffffff; */
  padding: 0rem 0rem 0rem 0rem;
  /* border-radius: 10px; */
  margin: 2rem 2rem 0rem 2rem;
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 700px) {
  }
  /* @media screen and (max-width: 500px) {
} */
`;

const Content2 = styled.div`
  width: 925px;
  /* background-color: #44ff46; */
  background-color: transparent;
  /* background-color: #000000; */
  /* background-color: #ffffff; */
  /* padding: 0rem 1rem 0rem 1rem; */
  /* border-radius: 10px; */
  @media screen and (max-width: 1000px) {
    /* margin: 0rem 2rem 0rem 2rem; */
  }
  @media screen and (max-width: 700px) {
    /* margin: 0rem 1.4rem 0rem 1.4rem; */
  }
  /* @media screen and (max-width: 500px) {
  } */
`;

VoiceappsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ImageComponent2 = styled.img`
  max-width: 100%;
  max-height: 100%;
  /* @media screen and (max-width: 458px) {
    width: 500px;
  } */
`;

// export const BackgroundImage = styled.div`
//   background-image: url(${VoicePageBG});
//   background-position: absolute;
//   background-size: auto;
// `;

const ContainScroll = styled.div`
  max-width: 100% !important;
  overflow-x: hidden !important;
  background-color: transparent !important;
`;
