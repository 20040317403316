import { React, Component } from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Home from "../mint-components/Home";
import ThemeContext from "../mint-context/ThemeContext";
// import NotFound from "../mint-components/NotFound";

// import "./mintpage.css";

class Mintpage extends Component {
  state = { isDarkTheme: false };

  toggleTheme = () =>
    this.setState((prevState) => ({ isDarkTheme: !prevState.isDarkTheme }));

  render() {
    const { isDarkTheme } = this.state;

    return (
      <ThemeContext.Provider
        value={{ isDarkTheme, toggleTheme: this.toggleTheme }}
      >
        <Home />
      </ThemeContext.Provider>
    );
  }
}

export default Mintpage;
