const data = [
  {
    slug: "start",
    messages: [
      "Hey! I'm the Joker, here to bring some joy and laughter into your life. Want to have some fun?",
      // "Let's have a great time and lighten up the world",
      // "But if I can't dance, I won't join your revolution.",
      // "Pull my finger!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/joker/characteristics",
      },
      {
        label: "Build a joker character",
        link: "/joker/build",
      },
      {
        label: "Back home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "The jester/joker archetypes live wildly and enjoy life. They try to be funny and spontaneous. They experience emotions to the maximum. They make a lot of jokes and are usually in a good mood. They do not dwell on problems and issues for a long time. They use every opportunity to show their sense of humor and do not take a lot of conversations seriously.",
      "Also known as Joker, trickster, jester, huckster, practical joker, humorist, comic, hoaxer or comedian.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/joker/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a joker character",
        link: "/joker/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a joker character",
        link: "/joker/build",
      },
      {
        label: "Contact",
        link: "/joker/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/joker/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Joker character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "fnb",
    messages: ["Joker character food & beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Joker character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Joker character customer support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "automotive",
    messages: ["Joker character automotive professionals under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
