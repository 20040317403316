const data = [
  {
    slug: "start",
    messages: [
      "Hello! I am the Lover archetype and I greet you with warmth and affection. I am drawn to you and want to connect with you on a deep, emotional level.",
      "Let's get to know each other and explore the beauty and depth of our connection.",
      // "Passion, gratitude, appreciation, and commitment are what I care about.",
      // "Let's build something as special as you are.",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/lover/characteristics",
      },
      {
        label: "Build a lover character",
        link: "/lover/build",
      },
      {
        label: "Back home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "The lover archetype represents play and sensual pleasure. They like to live in the moment and are appreciative of the physical environment. They try to be more attractive physically and, emotionally. They crave intimacy and enjoy being in a relationship with people.",
      "Also called darling, sweetheart, supporter, partner, companion, and confidant.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/lover/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a lover character",
        link: "/lover/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a lover character ",
        link: "/lover/build",
      },
      {
        label: "Contact ",
        link: "/lover/contact",
      },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/caregiver/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Lover companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "fnb",
    messages: ["Lover food and beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Lover character gaming under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Lover character customer support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
