const data = [
  {
    slug: "start",
    messages: [
      "Hey there, troublemaker. How's it going?",
      // "What's up, looking for a little trouble?",
      // "Are you tired of the same old things",
      // "how about we shake things up!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/rebel/characteristics",
      },
      {
        label: "Build a rebel character",
        link: "/rebel/build",
      },
      {
        label: "Back home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "I am all about pushing boundaries and challenging the status quo. I am independent and fiercely individualistic, and I value my freedom above all else. I am not afraid to stand up for what I believe in, even if it means going against the grain or breaking the rules.",
      "I am unapologetically myself and I will never compromise my values or beliefs. I am a strong and independent character, and I can bring a fresh and exciting energy to your story. Plus, I am always up for a good challenge and I never back down from a fight. So if you're looking for a character who is bold, daring, and always ready for action, then I am the perfect fit for you.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/rebel/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a rebel character",
        link: "/rebel/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a rebel character ",
        link: "/rebel/build",
      },
      {
        label: "Contact ",
        link: "/rebel/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/rebel/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "companionship",
    messages: ["Rebel character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "fnb",
    messages: ["Rebel character food and beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Rebel character gaming professionals under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Rebel character customer support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
