import React from "react";
import { Button } from "../ButtonElement";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Button2 = styled.a`
  width: 50%;
  margin: 10px 10px 10px 0px;
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#44FF46" : "#010606")};
  white-space: nowrap;
  padding: 8px;
  color: #010606;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#3DDF3F")};
  }
`;

const AlexaSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  description2,
  p1,
  p2,
  p3,
  buttonLabel,
  buttonLabel2,
  buttonLabel3,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Fade bottom>
                  <TopLine>{topLine}</TopLine>
                </Fade>
                <Fade bottom>
                  <Heading lightText={lightText}>{headline}</Heading>
                </Fade>
                <Fade bottom>
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                </Fade>
                <Fade bottom>
                  <BtnWrapLink>
                    <BtnWrap>
                      <FirstdButton
                        to="mint"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        primary={primary ? 1 : 0}
                        offset={-80}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </FirstdButton>
                    </BtnWrap>
                  </BtnWrapLink>
                </Fade>
                {/* <Fade bottom>
                  <ThirdButton
                    href="//carl-xii.web.app/"
                    target="_blank"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    primary={primary ? 1 : 0}
                    offset={-80}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel3}
                  </ThirdButton>
                </Fade> */}
                {/* <Fade bottom>
                  <SecondButton
                    href="//jamesrrs-projects-app.web.app/"
                    target="_blank"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    primary={primary ? 1 : 0}
                    offset={-80}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel2}
                  </SecondButton>
                </Fade> */}
              </TextWrapper>
            </Column1>
            <Column2>
              <Fade right>
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              </Fade>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
  // console.log(<Img />);
};

export default AlexaSection;

// const SecondWrapLink = styled.a`
//   text-decoration: none;
// `;

// const SecondBtnWrap = styled.div`
//   text-decoration: none;
// `;

const SecondButton = styled.a`
  margin: 10px 0px 10px 0px;
  width: auto;
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#44FF46" : "#ffffff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#44FF46")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#ffffff" : "#010606")};
  }
`;

const FirstdButton = styled(Link)`
  margin: 0px 0px 0px 0px;
  width: 100%;
  border-radius: 50px;
  /* background: ${({ primary }) => (primary ? "#ffffff" : "#010606")}; */
  background: ${({ primary }) => (primary ? "#44FF46" : "#ffffff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#44FF46")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  /* font-style: italic; */
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#ffffff" : "#010606")};
    /* background: ${({ primary }) => (primary ? "#44FF46" : "#ffffff")}; */
  }
`;

const ThirdButton = styled.a`
  margin: 10px 0px 10px 0px;
  width: auto;
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#ffffff" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#44FF46")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  /* font-style: italic; */
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#44FF46" : "#ffffff")};
  }
`;

const InfoContainer = styled.div`
  /* color: #ffffff; */
  /* #010606 */
  /* border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff; */
  /* background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")}; */
  /* background-color: transparent; */
/* background-color: transparent; */
/* background: linear-gradient(
  135deg,
  rgba(0, 0, 0, 1),
  rgba(0, 0, 0, 0)
);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(10px); */
  @media screen and (max-width: 768px) {
    padding: 100px 0;
    /* border-top: 1px solid #45fe47;
    border-bottom: 1px solid #45fe47; */
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  padding: 20px 0px 20px 0px;
  width: 80%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0px 20px 0px 20px;
    width: 100%;
  }
`;

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const TextWrapper = styled.div`
  max-width: 100%; /* Adjust the maximum width to your preference */
  padding-top: 0;
  padding-bottom: 60px;
  margin: 0 auto; /* Center the content within the parent */
`;

const TopLine = styled.p`
  color: #44ff46;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const BtnWrapLink = styled(Link)`
  text-decoration: none;
`;

const ImgWrap = styled.div`
  width: 100%; /* Adjust the width to your preference */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  height: auto; /* This ensures the image aspect ratio is maintained */
  max-width: 100%; /* This prevents the image from exceeding its natural size */
`;
