const data = [
  {
    slug: "start",
    messages: [
      "Hello, it's a pleasure to meet you. I'm the leader of this group, and I'm here to make sure everything runs smoothly.",
      // "Greetings! As a Ruler, I am pleased to meet you. I hope we can work together to achieve our goals and create a better future for all.",
      // "Responsibility and leadership are how i do things... ",
      // "Let's you and me create a prosperous community!",
    ],
    answers: [
      {
        label: "Tell me a little about yourself",
        link: "/ruler/characteristics",
      },
      {
        label: "Build a ruler character",
        link: "/ruler/build",
      },
      {
        label: "Home",
        link: "/mint",
      },
    ],
  },
  {
    slug: "characteristics",
    messages: [
      "Ruler archetypes describe the ability to lead. Leading is their most defining trait. They like to volunteer and take charge of situations. They are usually the fairest and just in a group of people. They have a constant need to wield power.",
      "Also known as the boss, the king, the queen, the politician, the sovereign, the monarch, the potentate, the crown prince, the emperor, the despot, the dictator, the tyrant, the autocrat, and the autarch.",
    ],
    answers: [
      // {
      //   label: "What are Jungian archetypes?",
      //   link: "/ruler/breakdown",
      // },
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a ruler character",
        link: "/ruler/build",
      },
    ],
  },
  {
    slug: "breakdown",
    messages: [
      "Jungian archetypes are defined as images and themes that derive from the collective unconscious, as proposed by Carl Jung. ",
      "Archetypes have universal meanings across cultures and may show up in dreams, literature, art or religion.",
      "Jung believed that we inherit these archetypes much in the way we inherit instinctive patterns of behavior.",
    ],
    answers: [
      { label: "Choose a different template", link: "/mint" },
      {
        label: "Build a ruler character ",
        link: "/ruler/build",
      },
      {
        label: "Contact ",
        link: "/ruler/contact",
      },
    ],
  },
  {
    slug: "contact",
    messages: ["Phone: ‪(971) 249-2717‬"],
    answers: [
      { label: "Home", link: "/" },
      { label: "Build a web app", link: "/webapps" },
      { label: "More archetypes", link: "/mint" },
    ],
  },
  {
    slug: "build",
    messages: ["What market are you servicing? "],
    answers: [
      { label: "Life Coaching", link: "me" },
      { label: "Personal Development", link: "me" },
      { label: "Character Creation", link: "me" },
      { label: "Education", link: "me" },
      { label: "Mental Health", link: "me" },
      { label: "Get help", link: "/ruler/contact" },
      { label: "More archetypes", link: "/mint" },
    ],
  },

  {
    slug: "companionship",
    messages: ["Ruler character companionship under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "fnb",
    messages: ["Ruler character food & beverage under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "me",
    messages: [
      "The foundation has been built, and the blueprints are in motion. The construction of this part of the project may have just begun, but the finished product will be a masterpiece. So, put on your hard hats and join us on this exciting journey as we continue to build and shape the future. Stay tuned, as the best is yet to come!",
    ],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "gaming",
    messages: ["Ruler gaming charcater under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
  {
    slug: "support",
    messages: ["Ruler character customer support under construction!"],
    answers: [{ label: "Back", link: "/mint" }],
  },

  {
    slug: "here-to-start",
    messages: ["Coming soon!"],
    answers: [{ label: "Back", link: "/mint" }],
  },
];

export default data;
