// Write your code here
import Navbar from "../Navbar";
import ThemeContext from "../../mint-context/ThemeContext";
import "./index.css";
import styled from "styled-components";
// import WalletConnectionButton from "../MintButton/WalletConnectionButton";

const Home = () => (
  <ThemeContext.Consumer>
    {(value) => {
      const { isDarkTheme } = value;
      return (
        <>
          {!isDarkTheme ? (
            <MainHomeContainer>
              <Navbar />
              <HomeContainerLight>
                <HomeHeading>Light</HomeHeading>
              </HomeContainerLight>
            </MainHomeContainer>
          ) : (
            <MainHomeContainer>
              <Navbar />
              <HomeContainerDark>
                <HomeHeadingDark>Dark</HomeHeadingDark>
              </HomeContainerDark>
            </MainHomeContainer>
          )}
        </>
      );
    }}
  </ThemeContext.Consumer>
);

export default Home;

const MainHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
`;

const HomeContainerLight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-grow: 1;
  width: 100%;
`;
const HomeHeading = styled.div`
  color: #000000;
`;

const HomeContainerDark = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  flex-grow: 1;
  width: 100%;
`;
const HomeHeadingDark = styled.div`
  color: white;
`;
