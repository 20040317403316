import React, { useState } from "react";
import styled from "styled-components";

import RulerImg from "../../images/RulerImg.png";
import CaregiverImg from "../../images/CaregiverImg.png";
import CreatorImg from "../../images/CreatorImg.png";
import LoverImg from "../../images/LoverImg.png";
import JokerImg from "../../images/JokerImg.png";
import EverymanImg from "../../images/EverymanImg.png";
import HeroImg from "../../images/HeroImg.png";
import MagicianImg from "../../images/MagicianImg.png";
import RebelImg from "../../images/RebelImg.png";
import ExplorerImg from "../../images/ExplorerImg.png";
import SageImg from "../../images/SageImg.png";
import InnocentImg from "../../images/InnocentImg.png";
// import QRJellyCards from "../../images/QRJellyCards.png";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  /* margin: 0rem 8rem 0rem 8rem; */
`;

const Square = styled.div`
  width: calc(33.33% - 20px);
  height: 0;
  padding-bottom: 40%; /* .4 is the aspect ratio of a basketball card */
  margin: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 960px; /* limit container to 960px width */
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37);
  animation: fade-in 1s ease-out forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(100%) scale(0.5);
    }
    to {
      opacity: 1;
      transform: translateY(0%) scale(1);
    }
  }

  &:hover img {
    opacity: 0.7;
  }

  & > img {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
    bottom: 0;
  }
`;

// const Square = styled.div`
//   width: calc(33.33% - 50px);
//   height: 0;
//   padding-bottom: 40%; /* .5 is the aspect ratio of a basketball card */
//   margin: 5px;
//   border-radius: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(
//     135deg,
//     rgba(255, 255, 255, 0.1),
//     rgba(255, 255, 255, 0)
//   );
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(10px);
//   border: 1px solid rgba(255, 255, 255, 0.18);
//   box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37);
//   max-width: 960px; /* limit container to 960px width */

//   animation: fade-in 1s ease-out forwards;

//   @keyframes fade-in {
//     from {
//       opacity: 0;
//       transform: translateY(100%) scale(0.5);
//     }
//     to {
//       opacity: 1;
//       transform: translateY(0%) scale(1);
//     }
//   }

//   &:hover img {
//     opacity: 0.7;
//   }

//   & > img {
//     /* width: 100%;
//     height: 100%;
//     object-fit: cover; */
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     position: absolute;
//     bottom: 0;
//     /* top: 20px; */
//     /* left: 20px;  */
//   }
// `;

const ClearCards = () => {
  const images = [
    CaregiverImg,
    RulerImg,
    CreatorImg,
    LoverImg,
    JokerImg,
    EverymanImg,
    HeroImg,
    MagicianImg,
    RebelImg,
    ExplorerImg,
    SageImg,
    InnocentImg,
    // QRJellyCards,
  ];

  return (
    <Container>
      {images.map((image, index) => (
        <Square key={index}>
          <img src={image} alt={`Image ${index + 1}`} />
        </Square>
      ))}
    </Container>
  );
};

export default ClearCards;

// const Square = styled.div`
//   /* width: calc(33.33% - 10px);
//   height: calc(25% - 10px); */
//   width: calc(33.33% - 10px);
//   height: calc((33.33% - 10px));
//   margin: 5px;
//   border-radius: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(
//     135deg,
//     rgba(255, 255, 255, 0.1),
//     rgba(255, 255, 255, 0)
//   );
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(10px);
//   border: 1px solid rgba(255, 255, 255, 0.18);
//   box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37);
//   max-width: 960px; /* limit container to 960px width */

//   animation: fade-in 1s ease-out forwards;

//   @keyframes fade-in {
//     from {
//       opacity: 0;
//       transform: translateY(100%) scale(0.5);
//     }
//     to {
//       opacity: 1;
//       transform: translateY(0%) scale(1);
//     }
//   }

//   &:hover img {
//     opacity: 0.7;
//   }

//   & > img {
//     max-width: 100%;
//     max-height: 100%;
//     object-fit: contain;
//     /* opacity: 0; */
//   }
// `;
