import React from "react";
import voice2 from "../../images/voice2.svg";
import builder from "../../images/builder.svg";
import undraw_new_ideas from "../../images/undraw_new_ideas.svg";
import undraw_programming from "../../images/undraw_programming.svg";
import { MdFingerprint } from "react-icons/md";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MdOutlineAttachMoney } from "react-icons/md";
import { GiDiamondHard } from "react-icons/gi";
import { FaBomb } from "react-icons/fa";
import { SlDiamond } from "react-icons/sl";
import { BsTools } from "react-icons/bs";
import { AiOutlineRobot } from "react-icons/ai";
import { FaChartLine } from "react-icons/fa";
import { MdOutlineLightbulb } from "react-icons/md";
import { BiBrain } from "react-icons/bi";
import { IoRocketOutline } from "react-icons/io5";
import { FaChessKing } from "react-icons/fa";
import { GiPsychicWaves } from "react-icons/gi";
import { FaCubes } from "react-icons/fa";

const Container = styled.div`
  /* margin: 20px 0px 20px 0px; */
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #ffffff; */
  /* background: #010606; */
  background: #41ff46;
  /* background-image: linear-gradient(
    to bottom,
    #41ff46,
    #2db231 50%,
    #010606
  ); */
  /* background-image: linear-gradient(to bottom, #41ff46, #29912c 50%, #010606); */

  height: auto;
  /* @media screen and (max-width: 1000px) {
    height: 900px;
  }

  @media screen and (max-width: 768px) {
    height: 1100px;
  } */
`;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  /* padding: 0 30px; */
  color: black;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

// const Wrapper = styled.div`
//   max-width: 1000px;
//   margin: 60px auto;
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   grid-gap: 44px;
//   padding: 0 30px;
//   color: black;

//   @media screen and (max-width: 1000px) {
//     grid-template-columns: 1fr 1fr;
//   }

//   @media screen and (max-width: 768px) {
//     grid-template-columns: 1fr;
//     padding: 0 20px;
//   }
// `;

const AppointmentCard1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #fff;
  background-color: #fff;
  height: auto;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  border: 0.3px solid #000;

  height: auto;
  /* height: 500px; */
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 1000px) {
    width: auto;
  }

  @media screen and (max-width: 700px) {
    width: auto;
  }
`;

const AppointmentCard2 = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #fff;
  height: auto;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  border: 0.3px solid #000;

  /* height: 600px; */
  height: auto;
  /* height: 550px; */
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 1000px) {
    width: auto;
    /* height: 500px; */
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

const AppointmentCard3 = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content to the bottom */
  align-items: center;
  border-radius: 10px;
  color: #fff;
  height: auto;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  border: 0.3px solid #000;
  position: relative; /* Add this */
  overflow: hidden; /* Add this */
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 1000px) {
    width: auto;
  }

  @media screen and (max-width: 700px) {
    height: auto;
  }
`;

const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

// #44FF46
const CardLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const Icon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
`;

export const H1 = styled.h1`
  font-family: "Orbitron", sans-serif;
  /* text-decoration: underline; */
  font-weight: 900;
  font-size: 2.5rem;
  color: #85ff87;
  /* color: #6fff72; */
  /* color: #fff; */
  margin-bottom: 64px;
  /* text-transform: uppercase; */
  @media screen and (max-width: 1000px) {
    /* margin-top: 64px; */
    font-size: 2rem;
  }

  @media screen and (max-width: 700px) {
    /* margin-bottom: 24px; */
  }
`;

const H2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const P = styled.p`
  font-size: 1rem;
  text-align: center;
`;

const TopWindow = styled.div`
  height: 100px;
  width: 100px;
  margin: 1rem;
  background-color: purple;
  border-radius: 10px;
`;
const CenterWindow = styled.div`
  /* font-family: "Orbitron", sans-serif; */
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  height: auto;
  width: 250px;
  padding: 20px 10px 10px 10px;
  margin-bottom: 10px;
  color: #000000;
  text-align: center;
  /* background-color: #ffffff; */
  border-radius: 10px;
  @media screen and (max-width: 1000px) {
    /* margin-top: 64px; */
  }

  @media screen and (max-width: 700px) {
    /* margin-bottom: 24px; */
  }
`;
// const BottomWindow = styled.div`
//   height: auto;
//   width: 200px;
//   padding: 10px;
//   background-color: pink;
//   border-radius: 10px;
//   display: flex;
//   justify-content: space-between;
// `;

// const Button = styled.div`
//   height: 40px;
//   width: 50px;
//   /* margin: 10px; */
//   background-color: green;
//   align-items: center;
// `;

// const AppointmentCard = styled.div`
//   background: #fff;
//   display: flex;
//   flex-direction: column;
//   border-radius: 10px;
//   height: 100%;
//   padding: 30px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
//   transition: all 0.2s ease-in-out;
//   justify-content: space-around;
//   align-items: center;

//   &:hover {
//     transform: scale(1.02);
//     transition: all 0.2s ease-in-out;
//     cursor: pointer;
//   }
// `;

const BottomWindow1 = styled(Link)`
  height: auto;
  width: 100%;
  padding: 10px 22px;
  /* max-width: 200px; */
  border-radius: 50px;
  color: #000000;
  background-color: #44fd47;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  border: 0.3px solid #000;

  & > * {
    /* margin-bottom: 10px; */
  }
  &:hover {
    color: #44fd47;
    background-color: #000000;
    cursor: pointer;
  }
`;
// const BottomWindow2 = styled(Link)`
const BottomWindow2 = styled.div`
  height: auto;
  width: 100%;
  /* max-width: 200px; */
  padding: 10px 22px;
  border-radius: 50px;
  color: #000000;
  background-color: #44fd47;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  border: 0.3px solid #000;

  &:hover {
    color: #44fd47;
    background-color: #000000;
    cursor: pointer;
  }
`;
const BottomWindow3 = styled.div`
  height: auto;
  width: 100%;
  /* max-width: 200px; */
  padding: 10px 22px;
  border-radius: 50px;
  background-color: #44fd47;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  border: 0.3px solid #000;

  & > * {
    /* margin-bottom: 10px; */
  }
  &:hover {
    color: #44fd47;
    background-color: #000000;
    cursor: pointer;
  }
`;

const Button = styled.div`
  height: 40px;
  width: 100%;
  /* margin: 10px; */
  background-color: green;
  align-items: center;
`;

const Square = styled.div`
  width: calc(33.33% - 10px);
  height: 0;
  padding-bottom: 40%; /* .5 is the aspect ratio of a basketball card */
  margin: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 0 rgba(0, 0, 0, 0.37);
  max-width: 960px; /* limit container to 960px width */

  animation: fade-in 1s ease-out forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(100%) scale(0.5);
    }
    to {
      opacity: 1;
      transform: translateY(0%) scale(1);
    }
  }

  &:hover img {
    opacity: 0.7;
  }

  & > img {
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    /* top: 20px; */
    /* left: 20px;  */
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-bottom: 20px;
  font-size: 0.9rem;
`;

const ListItem = styled.li`
  margin: 8px;
  color: #000000;
  /* font-family: "Orbitron", sans-serif; */
`;

const ContainerDolla = styled.div`
  padding: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
`;

const ContainerDolla2 = styled.div`
  padding: 60px 40px 20px 40px;
  display: flex;
  align-items: center;
`;
const ContainerDolla3 = styled.div`
  padding: 0px 40px 20px 40px;
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin-right: 8px;
  font-size: 4em;
  /* font-family: "Orbitron", sans-serif; */
`;

const Icon2 = styled(GiPsychicWaves)`
  color: #000000;
  font-size: 64px;
`;

const Icon1 = styled(AiOutlineRobot)`
  color: #000000;
  font-size: 64px;
`;

const Icon4 = styled(FaCubes)`
  font-size: 64px;
  color: #000000;
`;

const Icon3 = styled(GiDiamondHard)`
  font-size: 84px;
  color: #000000;
`;

const ServicesContainer = styled.div`
  padding: 40px 0px 0px 0px;
  /* height: 500px; */
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;
  /* background: #41ff46; */

  @media screen and (max-width: 1000px) {
    height: 900px;
  }

  @media screen and (max-width: 768px) {
    height: 1100px;
  }
`;

const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  padding: 0 30px;
  color: black;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const ServicesCard = styled.div`
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
  /* height: 300px; */
  padding: 20px 0px 30px 0px;

  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
const ServicesCard2 = styled.div`
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
  /* height: 300px; */
  padding: 0px 0px 30px 0px;

  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

const ServicesCard3 = styled.div`
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
  /* height: 300px; */
  padding: 60px 0px 30px 0px;

  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

// #44FF46
const ServicesCardLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

const ServicesSub = styled.div`
  font-size: 0.9rem;
  padding: 0.5em 0em 0.3em 0em;
  color: #000;
`;
const ServicesH2 = styled.h2`
  font-size: 0.9rem;
  /* margin-bottom: 10px; */
  color: #000;
  text-transform: uppercase;
  text-align: center;
`;

const ServicesP = styled.p`
  font-size: 0.9rem;
  text-align: center;
  color: #000;
`;

const Banner = styled.div`
  background-color: #ff4b4b;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  text-align: center;
  position: absolute;
  border-radius: 10px 10px 0px 0px;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #000000;
  border-right: 0.5px solid #000000;
  border-left: 0.5px solid #000000;
  border-bottom: 0.8px solid #000000;
`;

const BannerText = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
`;

const BannerSubtext = styled.p`
  font-size: 0.8rem;
`;

const DisclamerText = styled.div`
  /* font-family: "Orbitron", sans-serif; */
  /* font-weight: 100; */
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  color: #000;
  padding: 2rem 2rem 2rem 2rem;
  width: 700px;
  color: #000;
  border-radius: 8px;
  background-color: #fff;
  margin: 0rem 1.5rem 2rem 1.5rem;
  border: 0.3px solid #000;

  /* background-color: #42ff46; */
  @media screen and (max-width: 768px) {
    width: auto;
    margin: 0rem 1.5rem 2rem 1.5rem;
  }
`;

const AppointmentServices = () => {
  const data = [
    {
      name: "AI Family Storyteller",
      desc: "Preserving Heritage through Technology",
      link: "https://familiai.web.app/",
      link2: "https://github.com/jrq3rq/FamiliAI",
      hasBanner: true, // Add this property for the card with a banner
    },
    // Other data objects
  ];

  return (
    <>
      {/* <ServicesH1>Our Specialties</ServicesH1> */}

      <Container id="services">
        {/* <DisclamerText>
           Our website provides valuable information and showcases how our
          services can be of great assistance to small businesses eager to
          innovate, writers, game developers, and storytellers. For a
          personalized and detailed quote, we encourage you to get in touch with
          us directly. We look forward to discussing how we can best support you
          on your journey.
        </DisclamerText> */}
        {/* <H1>Our Specialties</H1> */}
        <Wrapper>
          <Fade bottom duration={1200}>
            <AppointmentCard1>
              <ServicesCard>
                <ContainerDolla>
                  <Icon1 />
                </ContainerDolla>
                {/* <ServicesH2>I. Free 30-minute </ServicesH2> */}
                <ServicesH2>I. STRATEGIC AI CONSULTING </ServicesH2>
                {/* <ServicesH2>I. Brainstorming Session</ServicesH2> */}
                {/* <ServicesSub>Brainstorming Session</ServicesSub> */}
                <ServicesP>
                  {/* Transform wild ideas into creative solutions. */}
                  {/* Marketing and Branding. */}
                  {/* Archetype-Driven Brand & Product Consulting */}
                  AI-Driven Solutions for Business Growth Introduction
                </ServicesP>
              </ServicesCard>
              <List>
                <ListItem>
                  {/* <b> Target audience: </b> Individuals or businesses,
                  particularly SMBs, struggling with marketing and branding
                  ideas or seeking to enhance product development using
                  archetype-driven AI and psychological strategies. */}
                  <b> Target audience: </b> Businesses looking for expert help
                  to use or grow AI solutions for better results and growth.
                </ListItem>
                <ListItem>
                  {/* <b>Discussion points: </b> Goals, target audience, and
                  potential strategies. Explore synergies between archetypes,
                  AI, and product development. */}
                  <b>Discussion points: </b> Use AI to make operations smoother,
                  speed up tasks, and make better, data-led choices. Speed up
                  product making and improve performance. Use AI to understand
                  your customers better, tailor marketing, and get more
                  engagement.
                </ListItem>
                <ListItem>
                  <b> Benefit: </b>Get expert help to create and use a
                  successful AI plan.
                </ListItem>
                {/* <ListItem>Free 30-minute Call</ListItem> */}
              </List>
              <BottomWindow1
                to="//calendly.com/studio-voice2fly/30min"
                target="_blank"
              >
                Book Now
              </BottomWindow1>
              {/* <CenterWindow>Archetype-Driven Consulting </CenterWindow> */}
              {/* <ServicesIcon src={undraw_new_ideas} /> */}
            </AppointmentCard1>
          </Fade>
          {/*  */}
          <Fade bottom duration={1400}>
            <AppointmentCard2>
              <Banner>
                <BannerText> No availability</BannerText>
                <BannerSubtext>Check back later</BannerSubtext>
              </Banner>
              <ServicesCard2>
                <ContainerDolla2>
                  <Icon2 />
                </ContainerDolla2>
                <ServicesH2>II. Archetypal Marketing Strategy </ServicesH2>
                {/* <ServicesH2>II. Boost Visibility </ServicesH2> */}
                {/* <ServicesSub>Step 2: </ServicesSub> */}
                <ServicesP>
                  Professional Guidance on Archetypal Marketing and Branding
                </ServicesP>
              </ServicesCard2>
              <List>
                <ListItem>
                  <b>Target audience:</b> Individuals and businesses seeking
                  solutions to marketing and brand-related challenges.
                </ListItem>
                <ListItem>
                  <b> Discussion points:</b> Specific challenges, goals, brand
                  strategy, target audience, and potential solutions. Leverage
                  AI tools for archetypal analysis and strategy implementation.
                </ListItem>
                <ListItem>
                  <b> Benefit:</b> Knowledge and guidance to solve brand-related
                  problems.
                </ListItem>
              </List>
              {/* <BottomWindow2>Book Now</BottomWindow2> */}
              {/* <CenterWindow>
                In-depth advice about brand or business
              </CenterWindow> */}
              {/* <ServicesIcon src={builder} /> */}
            </AppointmentCard2>
          </Fade>
          {/*  */}
          <Fade bottom duration={1600}>
            <AppointmentCard3>
              <Banner>
                <BannerText> No availability</BannerText>
                <BannerSubtext>Check back later</BannerSubtext>
              </Banner>
              <ServicesCard3>
                <ContainerDolla3>
                  <Icon4 />
                </ContainerDolla3>
                {/* <ContainerDolla>
                  <Icon4 />
                </ContainerDolla> */}
                <ServicesH2>
                  III. Archetype Fusion Product Development
                </ServicesH2>
                {/* <ServicesSub>Step 3: </ServicesSub> */}
                <ServicesP>
                  {/* Boost brand loyalty by crafting dynamic interactions with your
                products and services! */}
                  {/* Amplify brand loyalty with immersive and engaging interactions
                with your products and services. */}
                  {/* Elevate brand loyalty with compelling interactions across your
                  products and services. */}
                  Archetype-Infused Product Building
                </ServicesP>
              </ServicesCard3>
              <List>
                <ListItem>
                  <b>Target audience:</b> Businesses seeking to create
                  innovative products that blend archetypal wisdom with AI
                  capabilities
                </ListItem>
                <ListItem>
                  <b> Discussion points:</b> Comprehensive project planning and
                  execution. Integrating archetypal wisdom in product design.
                  Strategies for deep customer engagement and loyalty. Custom
                  solutions for unique brand challenges. AI functionalities
                  combined with archetypal design principles.
                </ListItem>
                <ListItem>
                  <b> Benefit:</b> Craft emotionally resonant, market-ready
                  products from concept to launch.
                </ListItem>
              </List>
              {/* <BottomWindow3>Book Now</BottomWindow3> */}
              {/* <CenterWindow>
                Assistance with product design and creation{" "}
              </CenterWindow> */}
              {/* <ServicesIcon src={voice2} /> */}
            </AppointmentCard3>
          </Fade>
        </Wrapper>
        {/* <DisclamerText>
          Our website provides valuable information and showcases how our
          services can be of great assistance to small businesses eager to
          innovate, writers, game developers, and storytellers. For a
          personalized and detailed quote, we encourage you to get in touch with
          us directly. We look forward to discussing how we can best support you
          on your journey.
        </DisclamerText> */}
      </Container>
    </>
  );
};

export default AppointmentServices;
