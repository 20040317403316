import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SocialIconLink,
} from "./SidebarElement";
import { FaBars, FaGift, FaBook, FaNewspaper } from "react-icons/fa";

import { PiEggCrackFill, PiEggFill } from "react-icons/pi";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {/* <SocialIconLink to="/about" onClick={toggle}>
            About
          </SocialIconLink> */}
          <SidebarLink to="services" onClick={toggle}>
            {/* Web + */}
            Services
          </SidebarLink>
          {/* <SidebarLink to="product" onClick={toggle}>
            <PiEggCrackFill />
          </SidebarLink> */}
          {/* <SidebarLink to="icons" onClick={toggle}>
            iOS Icons
          </SidebarLink> */}
          <SidebarLink to="react" onClick={toggle}>
            {/* Web + */}
            Templates
          </SidebarLink>
          <SidebarLink to="dapps" onClick={toggle}>
            AI
          </SidebarLink>
          <SidebarLink to="subscriptions" onClick={toggle}>
            Subscriptions
          </SidebarLink>
          <SidebarLink to="Xarticles" onClick={toggle}>
            <FaBook />
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            to="//calendly.com/studio-voice2fly/30min"
            target="_blank"
          >
            Let's Talk!
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
