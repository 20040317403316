import React, { useState } from "react";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ArtistSection from "../components/ArtSection";
import ArtistSectionMission from "../components/ArtSectionMission";
// import AboutMeArtistSection from "../components/AboutMeArtistSection";
import ArtPageNavbar from "../components/ArtSection/ArtNavbar";
import { ArtData } from "../components/ArtSection/Data";
import { ArtDataMission } from "../components/ArtSectionMission/Data";
import { Spacer } from "../components/ArtSectionSpacer/ArtSectionSpacerElements";
// import CardsCarousel from "../components/CardsCarousel";
// import CarouselSecond from "../components/CarouselSecond";
import styled from "styled-components";
import { BsEye } from "react-icons/bs";
import { GiNoseSide, GiSoundWaves } from "react-icons/gi";
// import { Link as LinkR } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import VoiceAppsNav from "../components/Webapps2Folder/VoiceAppsNav";
import { HomeButton } from "./MemoryPalAILearnMore";
import VoicePageBG from "../images/VoicePageBG.png";
import BackgroundImage from "../images/VoicePageBG.png";

// const BackgroundImage = styled.div`
//   background-image: url(${VoicePageBG});
//   background-position: absolute;
//   background-size: auto;
// `;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  transform: translateZ(0);
  will-change: transform;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const AboutPage = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      {/* <VoiceAppsNav /> */}
      {/* <Spacer /> */}
      {/* <ArtistSection {...ArtData} /> */}
      <BackgroundWrapper />
      <Container>
        <HomeButton to="/">Home</HomeButton>
        <ArtistSectionMission {...ArtDataMission} />
        <ArtistSection {...ArtData} />
      </Container>

      {/* <Footer /> */}
      {/* <SecondaryNav /> */}
    </>
  );
};
export default AboutPage;
