import React, { useState, useEffect, useMemo } from "react";
import styled, { keyframes } from "styled-components";

// Import your images
import image1 from "../images/theFarmerAndTheThylacineDuo.png";
import image2 from "../images/TheFarmer_and_TheThylacine.png";
import image3 from "../images/PRLosDiablos.png";
import image4 from "../images/IslandOfTheHarvesterAliens.png";
import image5 from "../images/IslandOfTheHarvesterAliens2.png";
import { HomeButton } from "./MemoryPalAILearnMore";

const ScriptsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 8rem 2rem 6rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  @media (max-width: 768px) {
    padding: 6rem 2rem 4rem 2rem;
    grid-template-columns: 1fr;
  }
`;

const ScriptCard = styled.article`
  background-color: #43fe47;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 300px; // Set a minimum height
  border: 0.8px solid #43fe47;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 300px; // Reduced from 300px
  overflow: hidden;
`;

const Content = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DescriptionContainer = styled.div`
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 1rem;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #43fe47;
    border: 0.8px solid #000000;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #000000;
    border: 0.8px solid #000000;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ScriptImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  ${ScriptCard}:hover & {
    transform: scale(1.1);
  }
`;

const GenreTag = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #43fe47;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
  font-weight: normal;
  font-style: italic;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Description = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  color: #444;
  margin: 0;
  padding-right: 10px;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: #43fe47;
`;

const Runtime = styled.span`
  background-color: #000000;

  padding: 0.3rem 0.6rem;
  border-radius: 4px;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;

  &:hover,
  &.active {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedScriptCard = styled(ScriptCard)`
  opacity: 0;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  animation: ${slideUp} 1s ease forwards;
  animation-delay: ${(props) => props.delay}s;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border: 0.8px solid #43fe47;
  border-radius: 10px;
`;

const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalImage
        src={imageSrc}
        alt="Enlarged view"
        onClick={(e) => e.stopPropagation()}
      />
    </ModalOverlay>
  );
};

const ScriptCardWithImages = ({ script }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <HomeButton to="/">Home</HomeButton>
      <AnimatedScriptCard
        delay={script.animationDelay}
        isVisible={script.isVisible}
      >
        <ImageContainer>
          <ScriptImage
            src={script.images[currentImageIndex]}
            alt={script.title}
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          />
          <GenreTag>{script.genre}</GenreTag>
        </ImageContainer>
        <Content>
          {script.title && <Title>{script.title}</Title>}
          {script.subtitle && <Subtitle>{script.subtitle}</Subtitle>}
          {script.description && (
            <DescriptionContainer>
              <Description>{script.description}</Description>
            </DescriptionContainer>
          )}
          <Meta>
            <Runtime>{script.runtime}</Runtime>
          </Meta>
          {script.images.length > 1 && (
            <ThumbnailContainer>
              {script.images.map((image, index) => (
                <Thumbnail
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setCurrentImageIndex(index)}
                  className={index === currentImageIndex ? "active" : ""}
                />
              ))}
            </ThumbnailContainer>
          )}
        </Content>
      </AnimatedScriptCard>
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        imageSrc={script.images[currentImageIndex]}
      />
    </>
  );
};

const FilmScripts = () => {
  const [animatedScripts, setAnimatedScripts] = useState([]);

  const scriptData = useMemo(
    () => [
      {
        id: 1,
        title: "The Farmer and The Thylacine",
        subtitle: "A Journey Through Time in Australia's Heartland",
        description:
          "In a secluded Tasmanian farmhouse, Elias Hart, a reclusive farmer, discovers his home is a portal that transcends time. With his enigmatic companion, Taro, a mysterious Thylacine, Elias embarks on extraordinary journeys through pivotal moments in Australian history—from the prehistoric era and ancient indigenous ceremonies to colonial struggles and modern-day milestones. As temporal landscapes shift around them, Elias uncovers hidden truths, prevents historical tampering, and grapples with the mind-bending wonders he's witnessed. 'The Farmer and The Thylacine' is a captivating fusion of sci-fi and history, highlighting the deep connections between past and present, the wonders of time travel, and the enduring quest for identity.",
        images: [image2, image1], // Add 4 images for each script
        genre: "Sci-Fi Tale",
        runtime: "Short Series",
      },
      {
        id: 2,
        title: "The Devil Vacations in PR",
        subtitle: "",
        description: "",
        images: [image3], // Add 4 images for each script
        genre: "Future Punk",
        runtime: "Animated Shorts",
      },
      {
        id: 3,
        title: "Island of the Harvester Aliens",
        subtitle: "Tales of the real Bloodsuckers",
        description:
          "In the late 1980s, a secluded village in rural Puerto Rico is gripped by terror as livestock are found mutilated, drained of blood. The legend of the Chupacabra spreads fear, but the truth is even more horrifying. An alien species has landed, misinterpreting human behavior and attempting to communicate through violence. As the villagers confront these shape-shifting beings that often appear as innocent children, they must uncover the aliens' intentions and survive the escalating horror.",
        images: [image4, image5], // Add 4 images for each script
        genre: "Horror Sci-Fi",
        runtime: "Short Film",
      },
    ],
    []
  ); // Empty dependency array means this will only be calculated once
  useEffect(() => {
    const animationInterval = 0.5; // 2 seconds between each card animation

    const animatedData = scriptData.map((script, index) => ({
      ...script,
      animationDelay: index * animationInterval,
      isVisible: false,
    }));

    setAnimatedScripts(animatedData);

    animatedData.forEach((script, index) => {
      setTimeout(() => {
        setAnimatedScripts((prev) =>
          prev.map((s, i) => (i === index ? { ...s, isVisible: true } : s))
        );
      }, index * animationInterval * 1000);
    });
  }, [scriptData]);

  return (
    <ScriptsContainer>
      {animatedScripts.map((script) => (
        <ScriptCardWithImages key={script.id} script={script} />
      ))}
    </ScriptsContainer>
  );
};

export default FilmScripts;
